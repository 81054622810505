import * as React from 'react'
import { trackingTokens } from '../config'

export const GA = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingTokens.google}`
    script.async = true

    document.body.appendChild(script)

    initGa()
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <></>
}

export function initGa() {
  const w = window as any
  w.dataLayer = w.dataLayer || []

  // tslint:disable-next-line: no-function-expression
  w.gtag = function() {
    w.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', trackingTokens.google)
  gtag('event', 'page_view')
}

export function setUser(x: string) {
  gtag('config', trackingTokens.google, {
    user_id: x
  })
}

export function send(name: string, action = 'click', label?: string, value?: number) {
  if (action === 'error') {
    gtag('event', 'exception', {
      description: label
    })
  } else if (action === 'open') {
    gtag('event', 'screen_view', {
      screen_name: name
    })
  } else {
    gtag('event', action, {
      event_category: name,
      event_label: label,
      value: value
    })
  }
}

export function login() {
  gtag('event', 'login', {
    method: 'email'
  })
}

export function signUp() {
  gtag('event', 'sign_up', {
    method: 'email'
  })
}
