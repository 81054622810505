import { classes } from '@grammarly/focal'
import * as React from 'react'

import { Part } from './parts'
import * as style from './iconList.styl'
import { Avatar } from './avatar'

export const IconList = ({
  value,
  phone,
  isExcept,
  isUser,
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  value: Part[]
  phone: string
  isExcept?: boolean
  isUser: boolean
}) => {
  const maxItems = 7
  const isCollapsed = value.length > maxItems
  return (
    <span
      {...props}
      {...classes(
        style.iconList,
        isUser ? style.users : style.chats,
        isExcept && (isUser ? style.exceptUsers : style.exceptChats),
        isCollapsed && style.collapsed,
        className
      )}
    >
      {value.map((x, i) => (
        <Avatar
          key={i}
          id={x.id!}
          name={x.text!}
          isUser={isUser}
          className={i > maxItems - 1 && style.hidden}
          phone={phone}
        />
      ))}{' '}
      {isCollapsed && <span className={style.more}>{`& ${value.length - maxItems} more `}</span>}
    </span>
  )
}
