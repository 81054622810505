import * as style from './welcome.styl'
import { Dialog, DialogButtons } from './dialog'
import { MainButton } from './buttons'
import { User } from '../types'
import React from 'react'
const kigorw = require('!file-loader!./kigorw.jpg')

export const Welcome = ({
  onClose,
  isShow,
  isWhitelisted,
  user
}: {
  onClose: Function
  isShow: boolean | undefined
  isWhitelisted?: boolean | undefined
  user: User
}) => {
  return (
    <Dialog
      key="welcome"
      dialogModeName="welcome"
      isShow={Boolean(isShow)}
      isModal={Boolean(isWhitelisted)}
      onClose={
        isWhitelisted
          ? onClose
          : () => {
              /**/
            }
      }
    >
      <div className={style.welcome}>
        <h2 className={style.title}>
          {isWhitelisted
            ? 'Thanks for signing up and welcome! 🎉'
            : 'Thanks for signing up, weʼre launching soon! ⏳'}
        </h2>
        {isWhitelisted ? (
          <>
            <p>
              To start with Filter, connect a Telegram account, add a filter, and choose how to
              forward messages (chat or webhook).
            </p>
            <p>
              It's the early days of Filter. We are testing and polishing service to make sure it
              works well and bugfree. Thanks for being with us at this stage.
            </p>
            <p style={{ fontWeight: 500 }}>
              You can try Filter 7 days for free before you decide if you want to use the service.
            </p>
            <p>
              In case of troubles with the service, please contact me directly at{' '}
              <a href="mailto:igor@sitg.app">igor@sitg.app</a> or on Twitter at{' '}
              <a className={style.author} href="https://twitter.com/kigorw">
                @kigorw
              </a>
            </p>
          </>
        ) : (
          <>
            <p>
              Itʼs the early days of Filter. We are testing and polishing service to make sure it
              works well and bugfree. <strong>Weʼll email you when we ready to launch.</strong>
            </p>
            <p>
              Meanwhile, if you’d like to play with the service and participate in pre-launch
              testing, please contact me directly at{' '}
              <a href="mailto:igor@sitg.app">igor@sitg.app</a> or on Twitter at{' '}
              <a className={style.author} href="https://twitter.com/kigorw">
                @kigorw
              </a>{' '}
              and tell about your use case.
            </p>
          </>
        )}

        <p className={style.signature}>
          Thanks again, <br />
          <img className={style.kigorw} src={kigorw} /> Igor Kononuchenko, Filter creator.
        </p>
      </div>
      {isWhitelisted && (
        <DialogButtons className={style.buttons}>
          <MainButton
            className={style.button}
            trackingName="welcome_start"
            onClick={() => onClose()}
          >
            Letʼs start
          </MainButton>
        </DialogButtons>
      )}
    </Dialog>
  )
}
