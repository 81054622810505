import React from 'react'

import { RootTooltip } from '../components/tooltip'
import { PlanType, PlanPeriod, PlanWithService } from '../types'
import { Membership } from '../components/membership'
import { classes, Atom } from '@grammarly/focal'
import * as style from './components.styl'
import * as layout from '../components/layout.styl'
import { PlanChooser } from '../components/forms/planChooser'
import { ServiceChooser } from '../components/services'
import { nonNullable } from '../utils/filter'
import cloneDeep from 'lodash/cloneDeep'
import { ErrorState } from '../components/forms/utils'

const plan0 = {
  id: 'plan0',
  type: PlanType.basic,
  start: Date.now() - 1000 * 60 * 60 * 24 * 60,
  nextBill: Date.now() + 1000 * 60 * 60 * 24 * 2,
  period: PlanPeriod.month,
  price: 30
}

const plan1 = {
  id: 'plan1',
  type: PlanType.basic,
  start: Date.now() - 1000 * 60 * 60 * 24 * 60,
  nextBill: Date.now() + 1000 * 60 * 60 * 24 * 2,
  period: PlanPeriod.month,
  price: 30,
  isCanceled: true
}

const plan2 = {
  id: 'plan2',
  type: PlanType.pro,
  start: Date.now() - 1000 * 60 * 60 * 24 * 60,
  nextBill: Date.now() + 1000 * 60 * 60 * 24 * 280,
  period: PlanPeriod.year,
  price: 600
}

const plan3 = {
  id: 'plan3',
  type: PlanType.pro,
  start: Date.now() - 1000 * 60 * 60 * 24 * 60,
  nextBill: Date.now() + 1000 * 60 * 60 * 24 * 2,
  period: PlanPeriod.month,
  price: 60,
  isTrial: true
}

export const dummyPlans: PlanWithService[] = [
  {
    id: 'plan0',
    plan: plan0
  },
  {
    id: 'plan1',
    service: {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+995599093978',
      isPendingPassword: false,
      id: '825588046',
      firstName: 'Small',
      lastName: 'Talk',
      name: 'smalltalkek',
      isPaused: true,
      plan: plan1
    } as any,
    plan: plan1
  },
  {
    id: 'plan2',
    service: {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+16282029306',
      isPendingPassword: false,
      firstName: 'Ask',
      lastName: 'Me',
      name: 'askmek',
      id: '147227732',
      plan: plan2
    } as any,
    plan: plan2
  },
  {
    id: 'plan3',
    service: {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+380638439334',
      isPendingPassword: false,
      id: '964757184',
      firstName: 'Good',
      lastName: 'Man',
      name: 'goodmanek',
      plan: plan3
    } as any,
    plan: plan3
  }
]

export const Components7 = () => {
  return (
    <div {...classes(style.components, layout.simpleLeft)}>
      <Membership
        onConnectService={x => console.log('plan', x)}
        canTrial={Atom.create(true)}
        value={Atom.create(dummyPlans)}
        isChooser
      ></Membership>
      <div style={{ height: '700px', paddingTop: '50px' }}>
        <ServiceChooser
          error={new ErrorState()}
          currentService={Atom.create(undefined)}
          currentPlan={Atom.create(undefined)}
          onNew={() => {}}
          services={dummyPlans
            .map(x => x.service)
            .filter(nonNullable)
            .map(x => ({ ...cloneDeep(x), plan: undefined }))
            .slice(0, 2)}
        ></ServiceChooser>
      </div>

      <div style={{ height: '700px', paddingTop: '50px' }}>
        <PlanChooser
          hasPlans={true}
          onClose={() => {}}
          canTrial={true}
          step={Atom.create('chooser')}
        ></PlanChooser>
      </div>
      <p></p>
      <Membership
        onConnectService={x => console.log('plan', x)}
        canTrial={Atom.create(true)}
        value={Atom.create(dummyPlans)}
      ></Membership>
      <RootTooltip />
    </div>
  )
}
