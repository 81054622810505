import { Atom, classes, F, ReadOnlyAtom } from '@grammarly/focal'
import React from 'react'

import { DeleteState } from '../types'
import { track } from '../utils/tracking'
import { Delete } from './delete'
import * as style from './listItem.styl'
import { ModalLoader } from './loader'
import { EditorMenu } from './menu'
import { Notification } from './notification'
import { Ripple } from './ripple'

export const ListItem = ({
  id,
  className,
  selectedClassName,
  readOnlyClassName,
  editButtonClassName,
  menuClassName,
  isSelected,
  isLoading,
  isError,
  isReadOnly,
  isNonSelectable,
  onDelete,
  onShowDelete,
  onHideDelete,
  onAction,
  getMenuItems,
  trackingName,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  id: string
  className?: string
  selectedClassName?: string
  readOnlyClassName?: string
  editButtonClassName?: string
  menuClassName?: string
  isSelected?: ReadOnlyAtom<boolean>
  isLoading?: Atom<boolean>
  isError?: Atom<{ message: string; isShow: boolean } | undefined>
  isReadOnly?: ReadOnlyAtom<boolean>
  isNonSelectable?: boolean
  onDelete?(id: string, state: DeleteState): Promise<boolean | undefined> | void
  onShowDelete?(): void
  onHideDelete?(): void
  onAction?(action: string): void
  getMenuItems(id: string, loadingAction?: string): React.ReactNode
  trackingName?: string
  children: React.ReactNode
}) => {
  const [isDelete, setDelete] = React.useState(false)
  const [loadingAction, setLoadingAction] = React.useState<string | undefined>(undefined)

  function showDelete() {
    setDelete(true)
    if (onShowDelete) {
      if (trackingName) track(`{delete}_${trackingName}`, 'show')
      onShowDelete()
    }
  }

  function hideDelete() {
    setDelete(false)
    if (onHideDelete) {
      if (trackingName) track(`{delete}_${trackingName}`, 'hide')
      onHideDelete()
    }
  }

  React.useEffect(() => {
    return () => {
      // console.log('unmount')
      if (onHideDelete) onHideDelete()
    }
  }, [])

  const editorMenu = !isDelete && (
    <EditorMenu
      key={id + '_menu'}
      menuClass={menuClassName}
      onAction={async action => {
        if (trackingName) track(`editorMenuAction_${trackingName}`, action)

        if (action === 'delete' && onDelete) showDelete()
        else if (onAction) {
          setLoadingAction(action)
          await onAction(action)
          setLoadingAction(undefined)
        }
      }}
      buttonClass={classes(style.editButton, editButtonClassName).className}
    >
      {getMenuItems(id, loadingAction)}
    </EditorMenu>
  )

  return (
    <F.div
      {...classes(
        style.listItem,
        className,
        isSelected && isSelected.view(x => x && style.selected),
        isSelected && isSelected.view(x => x && selectedClassName),
        isReadOnly && isReadOnly.view(x => x && style.readOnly),
        isReadOnly && isReadOnly.view(x => x && readOnlyClassName),
        isLoading && isLoading.view(x => x && style.loading)
      )}
      {...props}
    >
      {!isNonSelectable && (
        <F.div className={style.rippleContainer}>
          {isSelected && isSelected.view(x => <Ripple key={id} disabled={x} color="#d9e1f6" />)}
        </F.div>
      )}
      {children}
      {isLoading && <ModalLoader isVisible={isLoading} />}
      {isError &&
        isError.view(x => (
          <Notification
            key={'notification' + id}
            onClose={() => {
              if (isError) isError.modify(x => ({ ...(x || { message: '' }), isShow: false }))
            }}
            isShow={Boolean(x && x.isShow)}
          >
            {x ? x.message : ''}
          </Notification>
        ))}
      <Delete
        key={'delete' + id}
        trackingName={trackingName}
        isShow={isDelete}
        onUndo={() => hideDelete()}
        onClose={async () => {
          if (isLoading) isLoading.set(true)
          const result = onDelete ? await onDelete(id, 'deleted') : undefined
          if (!result) hideDelete()
          if (isLoading) isLoading.set(false)
        }}
      />

      {!isReadOnly ? editorMenu : isReadOnly.view(x => !x && editorMenu)}
    </F.div>
  )
}
