import { Atom, classes, F } from '@grammarly/focal'
import React from 'react'
import { Icons, SvgIcon } from '../icons'
import { TooltipAnchor, tooltipState } from '../tooltip'
import * as style from './expandLink.styl'
import { AnimatedExpand } from '../animated'

export const ExpandLink = ({
  children,
  isExpanded,
  tooltip,
  className,
  pseudoLinkClassName,
  expandIconClassName,
  expandIconLeft
}: {
  children: React.ReactNode
  isExpanded: Atom<boolean>
  tooltip: string
  className?: string
  pseudoLinkClassName?: string
  expandIconClassName?: string
  expandIconLeft?: boolean
}) => {
  const icon = (
    <SvgIcon
      {...classes(style.expandIcon, expandIconLeft && style.expandIconLeft, expandIconClassName)}
      icon={Icons.collapse}
    />
  )
  return (
    <F.div
      {...classes(
        style.expandLink,
        expandIconLeft && style.iconAtLeft,
        className,
        isExpanded.view(x => (x ? style.expanded : style.collapsed))
      )}
      onClick={() => isExpanded.modify(x => !x)}
    >
      {expandIconLeft && icon}
      <span {...classes(style.pseudoLink, pseudoLinkClassName)}>
        <TooltipAnchor tooltip={tooltipState} content={tooltip} align="top">
          {children}
        </TooltipAnchor>
      </span>
      {!expandIconLeft && icon}
    </F.div>
  )
}

export interface ExpandPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  expandLinkClassName?: string
  expandLinkWrapClassName?: string
  expandPseudoLinkClassName?: string
  panelClassName?: string
  isExpanded?: Atom<boolean>
  tooltip: string
  expandLinkChildren: React.ReactNode
  expandIconClassName?: string
  expandIconLeft?: boolean
}

export const ExpandPanel = ({
  expandLinkWrapClassName,
  expandLinkChildren,
  tooltip,
  className,
  expandLinkClassName,
  expandPseudoLinkClassName,
  panelClassName,
  expandIconClassName,
  expandIconLeft,
  isExpanded = Atom.create(false),
  children,
  ...props
}: ExpandPanelProps) => {
  return (
    <div {...classes(style.expandPanel, className)} {...props}>
      <div {...classes(expandLinkWrapClassName)}>
        <ExpandLink
          tooltip={tooltip}
          isExpanded={isExpanded}
          expandIconLeft={expandIconLeft}
          pseudoLinkClassName={expandPseudoLinkClassName}
          expandIconClassName={expandIconClassName}
          {...classes(style.expandLink, expandLinkClassName)}
        >
          {expandLinkChildren}
        </ExpandLink>
      </div>
      <AnimatedExpand isExpanded={isExpanded}>{children}</AnimatedExpand>
    </div>
  )
}
