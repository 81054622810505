import React, { useState } from 'react'
import * as style from './input.styl'
import { Field, FieldAttributes, FieldProps } from 'formik'
import { classes } from '@grammarly/focal'
import TextareaAutosize from 'react-autosize-textarea'
import Checkbox from '@material/react-checkbox'

export type InputProps = FieldAttributes<{
  label?: string
  prefixLabel?: string
  helper?: string | React.ReactNode
  containerClass?: string
  helperClass?: string
  isTextarea?: boolean
  button?: React.ReactNode
  onBeforeSetValue?(value: any): any
}>

export const Input = React.forwardRef(
  (
    {
      label,
      prefixLabel,
      helper,
      helperClass,
      containerClass,
      button,
      isTextarea,
      onBeforeSetValue,
      ...otherProps
    }: InputProps,
    ref
  ) => {
    const [isFocused, setFocused] = useState(false)

    const blurFocusProps = {
      onFocus: (e: any) => {
        setFocused(true)
        if (otherProps.onFocus) otherProps.onFocus(e)
      },
      onBlur: (e: any) => {
        setFocused(false)
        if (otherProps.onBlur) otherProps.onBlur(e)
      }
    }

    return (
      <Field
        {...otherProps}
        render={({ field, form }: FieldProps) => {
          const errors = form.errors || {}
          const error = errors[field.name]

          const isCheckbox = otherProps.type === 'checkbox'

          const value = isCheckbox ? form.values[field.name] : field.value
          const processedValue = onBeforeSetValue ? onBeforeSetValue(value) : value

          const inputProps = {
            ...field,
            ...(otherProps as any),
            ...blurFocusProps,
            value: processedValue || '',
            autoComplete: 'off',
            ...(isCheckbox ? { checked: Boolean(value) } : {}),
            ...classes(
              style.field,
              isTextarea && style.textareaField,
              (!isCheckbox || !otherProps.type) && style.inputField,
              isCheckbox && style.checkboxField,
              processedValue && style.notEmpty
            )
          }
          return (
            <div
              {...classes(
                style.input,
                containerClass,
                isCheckbox && style.checkbox,
                error && style.error,
                otherProps.disabled && style.disabled,
                prefixLabel && style.prefixed,
                isFocused && style.focused,
                processedValue && style.notEmptyParent
              )}
            >
              {isCheckbox ? (
                <label>
                  <Checkbox ref={ref} {...inputProps} />
                  {label}
                </label>
              ) : (
                <>
                  {prefixLabel && <span className={style.prefixLabel}>{prefixLabel}</span>}
                  {isTextarea ? (
                    <TextareaAutosize {...inputProps} ref={ref} />
                  ) : (
                    <input ref={ref} {...inputProps} />
                  )}
                  {label && <label>{label}</label>}
                </>
              )}

              {helper && <div {...classes(style.helper, helperClass)}>{helper}</div>}
              {error && <div className={style.errorMessage}>{error}</div>}
              {button}
            </div>
          )
        }}
      />
    )
  }
)
