import 'ts-material-icons-svg'

import { classes } from '@grammarly/focal'
import * as React from 'react'

import * as style from './icons.styl'

const edit = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/edit.svg`)
const pause = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/pause.svg`)
const people = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/people.svg`)
const play = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/play_arrow.svg`)
const remove = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/remove_circle.svg`)
const error = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/error.svg`)
const info = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/info.svg`)
const close = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/close.svg`)
const done = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/done.svg`)
const done2 = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/check_circle.svg`)
const add2 = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/add_circle.svg`)
const more = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/more_vert.svg`)

const collapse = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/expand_less.svg`)
const telegram = require('!svg-inline-loader?classPrefix!./telegram.svg')
const arrowRight = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/keyboard_arrow_right.svg`)
const arrowLeft = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/keyboard_arrow_left.svg`)
const undo = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/undo.svg`)
const filter = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/filter_list.svg`)
const forward = require(`!svg-inline-loader?classPrefix!./forward.svg`) // require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/forward.svg`)
const condition = require(`!svg-inline-loader?classPrefix!./condition.svg`)

const clippy = require(`!svg-inline-loader?classPrefix!./clippy.svg`)

const webhook = require(`!svg-inline-loader?classPrefix!./webhook.svg`)

const person = require(`!svg-inline-loader?classPrefix!./person.svg`)
const bot = require(`!svg-inline-loader?classPrefix!./bot.svg`)
const signout = require(`!svg-inline-loader?classPrefix!./signout.svg`)

const wholeWord = require(`!svg-inline-loader?classPrefix!./wholeWord.svg`)
const caseSensitive = require(`!svg-inline-loader?classPrefix!./caseSensitive.svg`)
const regexp = require(`!svg-inline-loader?classPrefix!./regexp.svg`)
const archive = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/archive.svg`)
const clock = require(`!svg-inline-loader?classPrefix!ts-material-icons-svg/svg/round/access_time.svg`)

export enum Icons {
  edit = 'edit',
  pause = 'pause',
  play = 'play',
  remove = 'remove',
  error = 'error',
  info = 'info',
  close = 'close',
  done = 'done',
  done2 = 'done2',
  add2 = 'add2',
  more = 'more',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  telegram = 'telegram',
  webhook = 'webhook',
  filter = 'filter',
  forward = 'forward',
  condition = 'condition',
  undo = 'undo',
  person = 'person',
  bot = 'bot',
  signout = 'signout',
  collapse = 'collapse',
  wholeWord = 'wholeWord',
  caseSensitive = 'caseSensitive',
  regexp = 'regexp',
  people = 'people',
  clippy = 'clippy',
  archive = 'archive',
  clock = 'clock'
}

const icons = {
  [Icons.edit]: edit,
  [Icons.pause]: pause,
  [Icons.people]: people,
  [Icons.play]: play,
  [Icons.remove]: remove,
  [Icons.error]: error,
  [Icons.info]: info,
  [Icons.close]: close,
  [Icons.done]: done,
  [Icons.done2]: done2,
  [Icons.add2]: add2,
  [Icons.more]: more,
  [Icons.arrowLeft]: arrowLeft,
  [Icons.arrowRight]: arrowRight,
  [Icons.telegram]: telegram,
  [Icons.webhook]: webhook,
  [Icons.filter]: filter,
  [Icons.forward]: forward,
  [Icons.condition]: condition,
  [Icons.undo]: undo,
  [Icons.person]: person,
  [Icons.bot]: bot,
  [Icons.signout]: signout,
  [Icons.collapse]: collapse,
  [Icons.wholeWord]: wholeWord,
  [Icons.caseSensitive]: caseSensitive,
  [Icons.regexp]: regexp,
  [Icons.clippy]: clippy,
  [Icons.archive]: archive,
  [Icons.clock]: clock
}

export interface SvgIconProps<T extends HTMLSpanElement> extends React.BaseHTMLAttributes<T> {
  icon: Icons
  test?: string
}

export const SvgIcon = <T extends HTMLSpanElement>({
  className,
  icon,
  test,
  ...otherProps
}: SvgIconProps<T>) => (
  <span
    {...{
      ...classes(style.icon, style[icon], className),
      ...otherProps
    }}
    dangerouslySetInnerHTML={{ __html: icons[icon] }}
  />
)

export const FilterIcon = <T extends HTMLSpanElement>({
  className,
  ...otherProps
}: Omit<SvgIconProps<T>, 'icon'>) => (
  <SvgIcon
    icon={Icons.filter}
    {...classes(style.dialogIcon, style.filterIcon, className)}
    {...otherProps}
  />
)

export const ConditionIcon = <T extends HTMLSpanElement>({
  className,
  ...otherProps
}: Omit<SvgIconProps<T>, 'icon'>) => (
  <SvgIcon
    icon={Icons.condition}
    {...classes(style.dialogIcon, style.actionIcon, className)}
    {...otherProps}
  />
)

export const ForwardIcon = <T extends HTMLSpanElement>({
  className,
  ...otherProps
}: Omit<SvgIconProps<T>, 'icon'>) => (
  <SvgIcon
    icon={Icons.forward}
    {...classes(style.dialogIcon, style.actionIcon, className)}
    {...otherProps}
  />
)

export const DialogIcon = <T extends HTMLSpanElement>({
  icon,
  className,
  ...otherProps
}: SvgIconProps<T>) => (
  <SvgIcon
    icon={icon}
    {...classes(style.dialogIcon, style.actionIcon, className)}
    {...otherProps}
  />
)
