import * as React from 'react'

import * as style from './header.styl'
import { Logo } from './logo'
import { classes } from '@grammarly/focal'
import { Button } from './buttons'
import { Icons } from './icons'
import { PopupMenu, PopupMenuItem } from './menu'
import { track } from '../utils/tracking'

export const Header = ({
  email,
  onSignOut,
  onProfile,
  onExport,
  className
}: {
  email: string | undefined
  className?: string
  onSignOut: Function
  onProfile: Function
  onExport: Function
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null)

  return (
    <header {...classes(style.header, className, email && style.authorized)}>
      <Logo className={style.mainLogo} />

      {email && (
        <>
          <div className={style.mainMenu}>
            {/* <Button
              tooltip={'Export chats history in JSON format for given filters'}
              tooltipAlign="bottom"
              onClick={() => onExport()}
            >
              Export tool
            </Button> */}
          </div>
          <div className={style.profile}>
            <Button
              ref={setMenuAnchor}
              trackingName="profileBtn"
              className={style.profileBtn}
              icon={Icons.person}
            >
              {email}
            </Button>
            <PopupMenu
              className={style.menu}
              parentElement={document.body}
              isShowOnHover
              isCloseOnClick
              anchor={menuAnchor}
              anchorActiveClass={style.activeEditButton}
              onAction={action => {
                track('profileMenu', action)
                if (action === 'signout') {
                  onSignOut()
                }
                if (action === 'profile') {
                  onProfile()
                }
              }}
            >
              <PopupMenuItem action="profile" icon={Icons.person}>
                Profile
              </PopupMenuItem>
              <PopupMenuItem action="signout" icon={Icons.signout}>
                Sign out
              </PopupMenuItem>
            </PopupMenu>
          </div>
        </>
      )}
    </header>
  )
}
