import React from 'react'

import { classes, Atom } from '@grammarly/focal'
import * as layout from '../components/layout.styl'
import { Animated } from '../components/animated'
import { MainButton } from '../components/buttons'
import * as style from './components.styl'
import { random } from '../utils/math'
import { AppLoader } from '../components/loader'

export const Components4 = () => {
  const x = Atom.create(<div>Hi guys</div>)
  const someLabels = ['Hi friends', 'Submit', 'Hello', 'Good play', 'Am del']
  return (
    <div className={layout.simple}>
      <div style={{ marginTop: '40px' }} {...classes(style.components, layout.simpleLeft)}>
        <AppLoader />
        <MainButton
          onClick={() => {
            x.set(<div>{someLabels[random(0, someLabels.length - 1)]}</div>)
          }}
        >
          <Animated children={x} />
        </MainButton>{' '}
        <p />
        <div>
          <MainButton
            onClick={() => {
              x.set(<div>{someLabels[random(0, someLabels.length - 1)]}</div>)
            }}
          >
            <Animated children={x} transitionType={'appear'} />
          </MainButton>
        </div>
      </div>
    </div>
  )
}
