import { Formik } from 'formik'
import React from 'react'
import { DialogContent, DialogButtons, DialogForm } from '../dialog'
import * as style from './export.styl'
import { F, classes, Atom } from '@grammarly/focal'
import { Button, MainButton, MainLightButton } from '../buttons'
import { Calendar } from '../calendar'
import { PopupMenu } from '../menu'
import { dateToString, plural, timeRangeToString, ellipsisInTheMiddle } from '../../utils/string'
import { isSameDay, isSameMonth } from '../../utils/date'
import { Animated } from '../animated'
import { BucketForm } from './bucket'
import { ErrorState, useSubmit } from './utils'
import { MediaForm, ShortMedia } from './mediaForm'
import { MediaDownloadSelector, ExportJob, Filter } from '../../types'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import { OvalLoader } from '../loader'
import { TooltipAnchor, tooltipState } from '../tooltip'
import { SvgIcon, Icons } from '../icons'
import { ExportJobValidation } from './targets'
import { track } from '../../utils/tracking'
import { interval } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { getExportFile } from '../../config'
import { Avatar } from '../avatar'

// export const Export = ({
//   onClose,
//   isShow,
//   error
// }: {
//   onClose: Function
//   isShow: ReadOnlyAtom<boolean>
//   error: ErrorState
// }) => {
//   const state = Atom.create<ExportState>({
//     filter: 'BTC mentions',
//     start: undefined,
//     end: new Date(),
//     downloadType: 'file',
//     mediaSelectors: [MediaDownloadSelector.photo]
//   })
//   return (
//     <F.Fragment>
//       {isShow.view(isShow => (
//         <Dialog dialogModeName="export" key="profile" isShow={isShow} isModal onClose={onClose}>
//           <ExportContent state={state} error={error} />
//         </Dialog>
//       ))}
//     </F.Fragment>
//   )
// }

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

function getFileName(id: string, _label?: string) {
  const file = `/export/${id}.json.gz`
  return <span title={file}>{ellipsisInTheMiddle(file, 140)}</span>

  //return `/${(label || '').replace(/\W/gi, '_').toLowerCase()}-${id.substr(0, 4)}`
}

function getChatStat({ searchExpressions }: Filter) {
  // TODO maybe make precise list of chats (api method?)
  const chats = uniq(flatMap(searchExpressions, x => x.chats?.map(c => c.id)).filter(notEmpty))

  return {
    chatsLabel: searchExpressions.find(x => !x.chats || x.chats.length === 0)
      ? 'all chats'
      : `${chats.length} ${plural(chats.length, ['chat', 'chats'])}`,
    chatsCount: chats.length
  }
}

export const ExportContent = ({
  state,
  filter,
  onSubmit,
  onFinish,
  error
}: {
  state: Atom<ExportJob>
  filter: Filter
  onSubmit: (data: ExportJob) => Promise<ExportJobValidation>
  onFinish?: Function
  error: ErrorState
}) => {
  const menuAnchor = Atom.create<HTMLButtonElement | null>(null)
  const menuAnchor2 = Atom.create<HTMLButtonElement | null>(null)

  const setMenuAnchor = (x: HTMLButtonElement) => menuAnchor.set(x)
  const setMenuAnchor2 = (x: HTMLButtonElement) => menuAnchor2.set(x)

  const isOpenCalendar1 = Atom.create(false)
  const isOpenCalendar2 = Atom.create(false)

  const setOpenCalendar1 = (x: boolean) => isOpenCalendar1.set(x)
  const setOpenCalendar2 = (x: boolean) => isOpenCalendar2.set(x)

  const isExpandedMediaForm = Atom.create(false)

  const { chatsLabel, chatsCount } = getChatStat(filter)

  const bigRange = 30 * 24 * 60 * 60 * 1000 // 30 days

  const isShowLongWaitWarning = Atom.combine(
    state.lens('start'),
    state.lens('end'),
    (start, end) => {
      return chatsCount > 10 || !start || start - (end || Date.now()) > bigRange
    }
  ).view(x => {
    return x ? (
      <div className={style.longRangeWarning}>
        Export takes a while when a filter has many chats or timeframe is big.
      </div>
    ) : (
      <div className={style.px}></div>
    )
  })

  let submit: Function
  useSubmit(() => submit && submit())

  return (
    <Formik
      initialValues={state.get()}
      validate={() => {
        error.hide()
      }}
      onSubmit={async (data, { setSubmitting, setErrors }) => {
        // console.log('onSubmit', data)
        const result = await onSubmit(data)
        if (result.isValid && onFinish) {
          onFinish()
          track('exportForm', 'success')
        } else {
          track('exportForm', 'error', result.message)
          setErrors(result)
          error.show(result.message)
        }

        setSubmitting(false)
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        submit = handleSubmit
        return (
          <>
            <DialogContent className={style.exportDialog}>
              <DialogForm>
                <div className={style.description}>
                  <span className={style.filter}>
                    <span className={style.filterName}>{filter.label}</span> filter
                  </span>{' '}
                  will scan history in <span className={style.chats}>{chatsLabel}</span>
                  <div className={style.range}>
                    from{' '}
                    <Button
                      ref={setMenuAnchor}
                      onClick={() => {
                        setOpenCalendar2(false)
                        setOpenCalendar1(!isOpenCalendar1.get())
                      }}
                      className={isOpenCalendar1.view(x => x && style.selectedButton)}
                    >
                      <Animated
                        children={state.lens('start').view(x => {
                          values.start = x
                          return x ? dateToString(new Date(x)) : 'the oldest message'
                        })}
                      />
                    </Button>{' '}
                    to{' '}
                    <Button
                      ref={setMenuAnchor2}
                      onClick={() => {
                        setOpenCalendar1(false)
                        setOpenCalendar2(!isOpenCalendar2.get())
                      }}
                      className={isOpenCalendar2.view(x => x && style.selectedButton)}
                    >
                      <Animated
                        children={state.lens('end').view(x => {
                          values.end = x
                          return x && !isSameDay(new Date(x), new Date())
                            ? dateToString(new Date(x))
                            : 'present'
                        })}
                      />
                    </Button>
                    <F.Fragment>
                      {Atom.combine(
                        isOpenCalendar1,
                        menuAnchor,
                        (_isOpenCalendar1, _menuAnchor) => {
                          return (
                            <PopupMenu
                              parentElement={document.body}
                              isOpen={_isOpenCalendar1}
                              anchor={_menuAnchor}
                              isAlignCenter
                              onClose={() => setOpenCalendar1(false)}
                              {...classes(style.calendar)}
                            >
                              <Calendar
                                maxDate={
                                  state.lens('end').get()
                                    ? new Date(state.lens('end').get()!)
                                    : new Date()
                                }
                                minDate={new Date(2000, 1, 1)}
                                onValue={x => {
                                  state.lens('start').set(+x)
                                  setOpenCalendar1(false)
                                }}
                                value={state
                                  .lens('start')
                                  .view(x => (x === undefined ? x : new Date(x)))}
                              />
                              <div className={style.calendarButtons}>
                                <Button
                                  tooltip={
                                    'You can export all chat messages. In this case, export can take a long time.'
                                  }
                                  onClick={() => {
                                    state.lens('start').set(undefined)
                                    setOpenCalendar1(false)
                                  }}
                                >
                                  From oldest
                                </Button>
                                <MainLightButton onClick={() => setOpenCalendar1(false)}>
                                  Close
                                </MainLightButton>
                              </div>
                            </PopupMenu>
                          )
                        }
                      )}
                    </F.Fragment>
                    <F.Fragment>
                      {Atom.combine(
                        isOpenCalendar2,
                        menuAnchor2,
                        (_isOpenCalendar2, _menuAnchor2) => {
                          return (
                            <PopupMenu
                              parentElement={document.body}
                              isOpen={_isOpenCalendar2}
                              anchor={_menuAnchor2}
                              onClose={() => setOpenCalendar2(false)}
                              isAlignCenter
                              {...classes(style.calendar)}
                            >
                              <Calendar
                                maxDate={new Date()}
                                minDate={
                                  new Date(state.lens('start').get() || +new Date(2000, 1, 1))
                                }
                                onValue={x => {
                                  state.lens('end').set(+x)
                                  setOpenCalendar2(false)
                                }}
                                value={state.lens('end').view(x => (x ? new Date(x) : new Date()))}
                              />
                              <div className={style.calendarButtons}>
                                <Button
                                  onClick={() => {
                                    state.lens('end').set(undefined)
                                    values.end = undefined
                                    setOpenCalendar2(false)
                                  }}
                                >
                                  Present
                                </Button>
                                <MainLightButton onClick={() => setOpenCalendar2(false)}>
                                  Close
                                </MainLightButton>
                              </div>
                            </PopupMenu>
                          )
                        }
                      )}
                    </F.Fragment>
                  </div>
                  <div>
                    <Animated
                      transitionType={'slideDown'}
                      prevClassName={style.px}
                      nextClassName={style.px}
                      children={isShowLongWaitWarning}
                    ></Animated>
                  </div>
                  <div>
                    <MediaForm
                      className={style.mediaForm}
                      expandLinkWrapClassName={style.mediaLabel}
                      buttonsClassName={style.mediaButtons}
                      isShowSelectedItems
                      expandLink={'Include media'}
                      possibleValues={MediaDownloadSelector}
                      tooltip={'Decide what media resources you want to export with messages'}
                      isExpanded={isExpandedMediaForm}
                    />
                  </div>
                  <div className={style.blockFormat}>
                    Messages will be saved in{' '}
                    <a
                      href="https://github.com/sitg/filter/blob/master/types/webhook-message.d.ts#L927"
                      target="_blank"
                    >
                      JSON-format
                    </a>
                  </div>
                  <F.div className={style.selector}>
                    {state.lens('downloadType').view(x => {
                      return (
                        <>
                          <span className={style.lbl}>to </span>{' '}
                          <Button
                            isOn={x === 'file'}
                            onClick={() => {
                              state.lens('downloadType').set('file')
                              values.downloadType = 'file'
                            }}
                          >
                            downloadable file
                          </Button>{' '}
                          <span className={style.lbl}>or your</span>{' '}
                          <Button
                            isOn={x === 's3'}
                            onClick={() => {
                              state.lens('downloadType').set('s3')
                              values.downloadType = 's3'
                            }}
                          >
                            AWS s3 bucket
                          </Button>
                        </>
                      )
                    })}
                  </F.div>
                  <div {...classes(style.block, style.downloadTypeBlock)}>
                    <Animated
                      prevClassName={style.downloadTypeWrap}
                      nextClassName={style.downloadTypeWrap}
                      transitionType={'appear'}
                      children={state.lens('downloadType').view(x => {
                        return x === 'file' ? (
                          <div className={style.downloadTypeWrap}>
                            Upon export completion, you'll receive an email with a download link.
                          </div>
                        ) : (
                          <div className={style.downloadTypeWrap}>
                            <BucketForm>
                              <div className={style.smallText}>
                                Filter will store media in a root folder and messages in the file{' '}
                                <b className={style.dir}>
                                  {getFileName(state.get().id, filter.label)}
                                </b>
                                <div className={style.completion}>
                                  Upon export completion, you'll receive an email.
                                </div>
                              </div>
                            </BucketForm>
                          </div>
                        )
                      })}
                    ></Animated>
                  </div>
                </div>
              </DialogForm>
            </DialogContent>
            <DialogButtons>
              <Button
                trackingName="telegramExportCancel"
                onClick={() => {
                  // if (props.onFinish) props.onFinish()
                }}
              >
                Cancel
              </Button>
              <MainButton
                trackingName="telegramExportStart"
                isLoading={isSubmitting}
                onClick={() => {
                  handleSubmit()
                }}
              >
                Start export
              </MainButton>
            </DialogButtons>
          </>
        )
      }}
    </Formik>
  )
}

export const TimeElapsed = ({ value, children }: { value: number; children?: React.ReactNode }) => {
  const { m, h } = timeRangeToString(value)
  return (
    <span className={style.listItemTimeTook}>
      <SvgIcon className={style.clockIcon} icon={Icons.clock} />
      {h > 0 && (
        <span className={style.listItemTimeHour}>
          <span className={style.listItemTimeValue}>{h}</span>
          <span className={style.listItemTimeLbl}>h</span>
        </span>
      )}

      <span className={style.listItemTimeMinute}>
        <span className={style.listItemTimeValue}>{m}</span>
        <span className={style.listItemTimeLbl}>m</span>
      </span>
      {children}
    </span>
  )
}

export const ProgressBar = ({ value }: { value: number }) => {
  return (
    <div title={`Chat export progress: ${value + '%'}`} className={style.progressBar}>
      <div style={{ width: value + '%' }} className={style.progressBarValue}></div>
    </div>
  )
}

export const ExportListItem = ({
  value,
  filter,
  phone,
  onResume
}: {
  value: ExportJob
  filter: Filter
  phone: string
  onResume: Function
}) => {
  const end = value.end || Date.now()
  const sameDay = value.start && isSameDay(new Date(value.start), new Date(end))
  const sameMonth = Boolean(value.start && isSameMonth(new Date(value.start), new Date(end)))
  const isToday = sameDay && value.start && isSameDay(new Date(value.start), new Date())

  const isResuming = Atom.create(false)

  const downloadTypeTooltip =
    value.downloadType === 's3'
      ? `Export to AWS S3 bucket '${value.bucket}'`
      : 'Export to JSON file'

  const start = value.start ? dateToString(new Date(value.start), true) : 'oldest message'
  const range = sameDay ? (
    isToday ? (
      <span className={style.listItemDate}>today</span>
    ) : (
      <span className={style.listItemDate}>{start}</span>
    )
  ) : (
    <>
      <span className={style.listItemDate}>{start}</span>
      {'..'}
      <span className={style.listItemDate}>{dateToString(new Date(end), true, sameMonth)}</span>
    </>
  )

  const progressTotal = (value.end || Date.now()) - (value.currentChatStartDate || 0)
  let progressValue =
    value.currentChatStartDate && value.currentDate
      ? ((value.currentDate - value.currentChatStartDate) / progressTotal) * 100
      : ((value.processedChats || 0) / value.chatIds.length) * 100

  progressValue = Math.min(Math.max(progressValue, 4), 98)

  const clock = interval(1000 * 60).pipe(startWith(0))
  // const now = Atom.create(Date.now())

  return (
    <div className={style.listItem}>
      <div>
        <span className={style.listItemTitle}>
          {value.isRunning ? (
            <span className={style.listItemLoaderWrap}>
              <OvalLoader className={style.exportingLoader} isDarkLoader />
            </span>
          ) : (
            value.isOver && (
              <SvgIcon
                className={value.errorMessage ? style.errorIcon : style.doneIcon}
                icon={value.errorMessage ? Icons.error : Icons.done2}
              />
            )
          )}
          Export <span className={style.listItemDateWrap}>{range}</span>
        </span>
        <div className={style.listItemMedia}>
          {value.mediaSelectors && value.mediaSelectors.length > 0 && 'Media: '}
          <ShortMedia values={value.mediaSelectors} />
        </div>
      </div>
      <div className={style.listItemProps}>
        {value.downloadType == 's3' && (
          <span className={style.listItemDownloadType}>
            <TooltipAnchor tooltip={tooltipState} content={downloadTypeTooltip} align="top">
              {value.downloadType}
            </TooltipAnchor>
          </span>
        )}
        <TooltipAnchor
          tooltip={tooltipState}
          content={value.isRunning ? 'Time since start' : 'Took time'}
          align="top"
          wrapperClassName={style.timeWrap}
        >
          <F.Fragment>
            {clock.pipe(
              map(() => {
                // console.log(value.date, value.timeTook, 'hehe')
                return (
                  <TimeElapsed
                    value={value.timeTook || +new Date() - (value.date || +new Date())}
                  ></TimeElapsed>
                )
              })
            )}
          </F.Fragment>
        </TooltipAnchor>

        {value.isOver && value.size && (
          <TooltipAnchor tooltip={tooltipState} content={'JSON file size'} align="top">
            <span className={style.size}>
              <span className={style.sizeValue}>
                {parseFloat((value.size / 1000000).toFixed(2)).toLocaleString()}
              </span>
              <span className={style.sizeUnit}>MB</span>
            </span>
          </TooltipAnchor>
        )}
      </div>
      {value.isOver && !value.errorMessage && value.downloadType === 'file' && (
        <div className={style.listItemDownload}>
          <Button
            onClick={() => {
              window.location.href = getExportFile(value.id)
            }}
            isSmall
            icon={Icons.archive}
            trackingName="downloadExport"
          >
            Download
          </Button>

          {Boolean(value.date && value.timeTook) && (
            <div className={style.buttonMessage}>
              Download media resources and JSON-file before expiration date on{' '}
              {dateToString(
                new Date(value.date! + value.timeTook! + 3 * 24 * 60 * 60 * 1000),
                true
              )}
              .
            </div>
          )}
        </div>
      )}
      {value.isOver && !value.errorMessage && value.downloadType === 's3' && (
        <div className={style.listItemS3}>
          Exported to AWS S3 bucket <span className={style.listItemBucket}>{value.bucket}</span>{' '}
          into the file{' '}
          <span className={style.listItemFolder}>{getFileName(value.id, filter.label)}</span>
        </div>
      )}

      {value.isOver && value.errorMessage && (
        <F.div className={style.listItemError}>
          <div className={style.listItemErrorMessage}>{value.errorMessage}</div>
          {isResuming.view(isLoading => {
            return (
              <MainLightButton
                key={'resume _' + isLoading}
                isLoading={isLoading}
                onClick={async () => {
                  isResuming.set(true)
                  await onResume()
                  isResuming.set(false)
                }}
                trackingName="resumeExport"
                isSmall
              >
                Resume
              </MainLightButton>
            )
          })}

          <div className={style.buttonMessage}>Try to resume from the last known position.</div>
        </F.div>
      )}

      {value.isRunning && (
        <div>
          <div className={style.progressLabelWrap}>
            <div className={style.progressLabel}>
              {Boolean(value.currentChatId) && (
                <Avatar
                  phone={phone}
                  isUser={false}
                  id={value.currentChatId?.toString() || ''}
                  name={value.currentChatTitle || ''}
                />
              )}{' '}
              {Boolean(
                value.currentDate &&
                  value.currentDate > 0 &&
                  value.currentChatStartDate &&
                  value.currentChatStartDate > 0 &&
                  dateToString(new Date(value.currentDate), true) !== '0'
              ) && (
                <span title="Current exported date" className={style.progressDateValue}>
                  {dateToString(new Date(value.currentDate!), true)}
                </span>
              )}
            </div>
          </div>
          <ProgressBar value={progressValue} />
        </div>
      )}
    </div>
  )
}
