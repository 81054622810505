import { Atom, classes, F, Lens, ReadOnlyAtom } from '@grammarly/focal'
import * as React from 'react'
import { pairwise } from 'rxjs/operators'

import { limitMessage, limits } from '../api'
import { getWriteApi } from '../model'
import { Filter, isDisabledService, SearchExpression, TelegramService } from '../types'
import { ellipsisInTheMiddle, plural } from '../utils/string'
import { track } from '../utils/tracking'
import { FoldableList } from './animatedList'
import { AddButton } from './buttons'
import { Dialog } from './dialog'
import { EmptyList } from './emptyList'
import * as style from './filters.styl'
import { ConditionForm } from './forms/condition'
import { AddFilter } from './forms/filter'
import {
  Chooser,
  Step as ChooserStep,
  TargetResult,
  TelegramTargetValidation,
  WebhookTargetValidation
} from './forms/targets'
import { Icons } from './icons'
import { PausedBadge, ExportBadge } from './itemBadges'
import { ListItem } from './listItem'
import { PopupMenuItem } from './menu'

type Step = 'filter' | 'condition' | 'target'

export const Filters = ({
  data,
  selectedItem,
  openFilterDialog,
  onCloseFilter,
  onNew,
  onEdit,
  onNewCondition,
  onTargetSubmit,
  onDelete,
  onShowDelete,
  onHideDelete,
  onOpenDialog,
  onCloseDialog
}: {
  data: ReadOnlyAtom<{
    filters: Filter[]
    phone: string
    service?: TelegramService
    serviceHasExportJob?: boolean
  }>
  selectedItem: Atom<number>
  openFilterDialog: Atom<{ isOpen: boolean; item?: Filter }>
  onCloseFilter?: Function
  onNew(x: Filter): void
  onEdit(x: Filter): void
  onNewCondition?(x: SearchExpression): void
  onTargetSubmit(x: TargetResult): Promise<WebhookTargetValidation | TelegramTargetValidation>
  onDelete?(x: Filter): void
  onOpenDialog: Function
  onCloseDialog: Function
  onShowDelete(): void
  onHideDelete(): void
}) => {
  const currentStep = Atom.create<Step>('filter')

  const [chooserStep] = React.useState(Atom.create<ChooserStep>('chooser'))

  function setStep(step: Step) {
    currentStep.set(step)
  }

  function closeDialog() {
    openFilterDialog.set({ isOpen: false, item: openFilterDialog.get().item })
    if (onCloseFilter) onCloseFilter()
    onCloseDialog()
  }

  function openEdit(item: Filter) {
    setStep('filter')
    openFilterDialog.set({ isOpen: true, item })
    onOpenDialog()
  }

  React.useEffect(() => {
    const s = openFilterDialog.pipe(pairwise()).subscribe(([prev, next]) => {
      if (next && !prev) setStep('filter')
    })
    return () => {
      s.unsubscribe()
    }
  })

  const isLoadingItem = Atom.create<Record<string, boolean>>({})
  const isErrorItem = Atom.create<Record<string, { message: string; isShow: boolean }>>({})
  const setError = (id: string, message: string) => {
    isErrorItem.lens(Lens.key(id)).set({ message, isShow: true })
  }
  const getPhone = () => {
    return data.get().service!.phone
  }

  const dialogChildren = currentStep.view(step => {
    // console.log('dialog children has item', openFilterDialog.get().item)
    return {
      filter: () => (
        <AddFilter
          key="filter"
          editItem={openFilterDialog.get().item}
          onFinish={closeDialog}
          currentFilter={Atom.create(data.get().service!.filters[selectedItem.get()])}
          onSubmit={async x => {
            const item: Filter = openFilterDialog.get().item
              ? ({
                  ...openFilterDialog.get().item,
                  ...x
                } as Filter)
              : {
                  id: '',
                  label: x.label,
                  searchExpressions: [],
                  telegramTargets: []
                }

            const result = await getWriteApi().editTelegramFilter(
              data.get().phone,
              item.id,
              item.label
            )

            if (result.validation.isValid) {
              if (openFilterDialog.get().item) {
                onEdit(item)
                closeDialog()
              } else {
                onNew(result.item!)
                setStep('condition')
              }
            }

            return result.validation
          }}
        />
      ),
      condition: () => (
        <ConditionForm
          key="condition"
          phone={data.get().phone}
          onFinish={closeDialog}
          onSubmit={async x => {
            const result = await getWriteApi().editTelegramCondition(
              data.get().phone,
              data.get().filters[selectedItem.get()].id,
              x
            )

            if (result.validation.isValid) {
              if (onNewCondition) {
                onNewCondition(x)
              }
              setTimeout(() => setStep('target'), 10)
            }

            return result.validation
          }}
        />
      ),
      target: () => (
        <Chooser
          step={chooserStep}
          key="chooser"
          planType={data.get().service!.plan?.type}
          filter={data.get().service!.filters[selectedItem.get()]}
          serviceHasExportJob={data.get().serviceHasExportJob}
          phone={data.get().phone}
          onFinish={closeDialog}
          onSubmit={onTargetSubmit}
          onNextStep={() => {
            /**
             */
          }}
        />
      )
    }[step]()
  })

  return (
    <F.Fragment>
      <F.div
        {...classes(
          style.filterList,
          data.view(({ filters }) => (filters || []).length === 0 && style.empty)
        )}
      >
        <FoldableList
          items={data.view(x => x.filters || [])}
          category={data.view(x => (x.service ? x.service.phone : ''))}
          empty={
            <EmptyList
              style={{ '--margin-top': '60px' } as any}
              icon={Icons.filter}
              label="No filters added"
            />
          }
          onItem={(item, i) => (
            <ListItem
              trackingName="filter"
              key={item.id}
              id={item.id}
              isLoading={isLoadingItem.lens(Lens.key(item.id))}
              isError={isErrorItem.lens(Lens.key(item.id))}
              {...classes(style.filterItem, item.isPaused && style.paused)}
              selectedClassName={style.selected}
              isReadOnly={data.view(isDisabledService)}
              editButtonClassName={style.editButton}
              isSelected={selectedItem.view(x => i === x)}
              onClick={() => {
                selectedItem.set(i)
              }}
              onShowDelete={onShowDelete}
              onHideDelete={onHideDelete}
              onDelete={async () => {
                track('deleteFilter', 'submit')
                const result = await getWriteApi().deleteTelegramFilter(getPhone(), item.id)
                if (!result.validation.isValid) {
                  setError(item.id, result.validation.message || '')
                  track('deleteFilter', 'error', result.validation.message)
                  return false
                }
                if (onDelete) onDelete(item)
                return true
              }}
              onDoubleClick={() => {
                if (isDisabledService(data.get())) return
                openEdit(item)
              }}
              onAction={async action => {
                if (action === 'edit') {
                  openEdit(item)
                }
                if (action === 'pause') {
                  const isLoading = isLoadingItem.lens(Lens.key(item.id))
                  isLoading.set(true)
                  track('pauseFilter', 'submit')
                  const result = await getWriteApi().pauseTelegramFilter(
                    getPhone(),
                    item.id,
                    !item.isPaused
                  )
                  if (result.validation.isValid) {
                    onEdit({ ...item, isPaused: !item.isPaused })
                  } else {
                    track('pauseFilter', 'error', result.validation.message)
                    setError(item.id, result.validation.message || '')
                  }

                  isLoading.set(false)
                }
              }}
              getMenuItems={() => (
                <>
                  <PopupMenuItem action="edit" icon={Icons.edit}>
                    Edit filter name
                  </PopupMenuItem>
                  <PopupMenuItem action="pause" icon={item.isPaused ? Icons.play : Icons.pause}>
                    {item.isPaused ? 'Resume' : 'Pause'} processing updates
                  </PopupMenuItem>
                  <PopupMenuItem action="delete" icon={Icons.remove}>
                    Delete filter with conditions
                  </PopupMenuItem>
                </>
              )}
            >
              <div className={style.label}>
                {ellipsisInTheMiddle(
                  item.label
                    ? item.label
                    : `Filter (${item.searchExpressions.length} ${plural(
                        item.searchExpressions.length,
                        ['condition', 'conditions']
                      )})`,
                  22
                )}
              </div>
              {item.isPaused && <PausedBadge className={style.pausedBadge} />}
              {item.exportJobs?.find(x => x.isRunning) && <ExportBadge />}
            </ListItem>
          )}
        >
          <F.Fragment>
            {data.view(x => {
              let isDisabled = x.filters.length >= limits.filter
              let disabledText = limitMessage('filters', limits.filter)
              if (isDisabledService(x)) {
                isDisabled = true
                disabledText = `Read-only for a non-active service`
              }
              // console.log('filters', x.service)

              return (
                <AddButton
                  trackingName="addFilterBtn"
                  className={style.btnAdd}
                  onClick={() => {
                    setStep('filter')
                    openFilterDialog.set({ isOpen: true })
                    onOpenDialog()
                  }}
                  disabled={isDisabled}
                  disabledText={disabledText}
                  tooltip={'Add a filter, then you can add filter rules to it.'}
                >
                  Filter
                </AddButton>
              )
            })}
          </F.Fragment>
        </FoldableList>
      </F.div>

      {openFilterDialog.view(openDialog => {
        return (
          <Dialog
            key="dialog"
            isShow={openDialog.isOpen}
            onClose={closeDialog}
            isModal={currentStep.view(step => step === 'condition')}
            top={currentStep.view(step => (step === 'condition' ? 1 : 5))}
            dialogModeName={currentStep.view()}
            children={dialogChildren}
          />
        )
      })}
    </F.Fragment>
  )
}
