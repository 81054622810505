import React from 'react'

import { Dialog, DialogContent, DialogHeader, DialogError, DialogButtons } from './dialog'
import * as style from './profile.styl'
import { ErrorState } from './forms/utils'
import { IconButton, Button, MainButton } from './buttons'
import { Icons, SvgIcon } from './icons'
import { classes, Atom, F, ReadOnlyAtom } from '@grammarly/focal'
import { Input } from './input'
import { Animated } from './animated'
import { Formik } from 'formik'
import { changePassword, getTOTPCode, setupTOTP, getEmail, disableTOTP, hasTOTP } from '../authApi'
import QRCode from 'qrcode.react'
import { CognitoUser } from '@aws-amplify/auth'
import { Delete } from './delete'
import { getWriteApi } from '../model'
import { track } from '../utils/tracking'
import { PlanWithService, Plan, TelegramService } from 'types'
import { Membership } from './membership'

export const Profile = ({
  onClose,
  isShow,
  onDeleteUser,
  plans,
  canTrial,
  isWhitelisted,
  user,
  onConnectService
}: {
  onClose: Function
  isShow: ReadOnlyAtom<boolean>
  onDeleteUser: Function
  plans: Atom<PlanWithService[]>
  canTrial: Atom<boolean>
  isWhitelisted: Atom<boolean | undefined>
  user?: CognitoUser
  onConnectService: (plan: Plan, selectedService?: TelegramService) => void
}) => {
  const is2FAEnabled = Atom.create<boolean | undefined>(undefined)
  hasTOTP().then(x => is2FAEnabled.set(x))

  return (
    <F.Fragment>
      {isShow.view(isShow => (
        <Dialog
          dialogModeName="profile"
          key="profile"
          isShow={isShow}
          isModal
          isFullScreen
          onClose={() => {
            if (
              ['ServiceChooserDialog', 'PlanChooserDialog'].find(
                x => x === document.documentElement.dataset.dialog
              )
            ) {
              return
            }
            onClose()
          }}
        >
          {user && (
            <ProfileContent
              onDeleteUser={onDeleteUser}
              plans={plans}
              email={getEmail(user)!}
              canTrial={canTrial}
              isWhitelisted={isWhitelisted}
              onConnectService={onConnectService}
              is2FAEnabled={is2FAEnabled}
            />
          )}
        </Dialog>
      ))}
    </F.Fragment>
  )
}

export const ProfileContent = ({
  email,
  plans,
  canTrial,
  isWhitelisted,
  is2FAEnabled,
  onDeleteUser,
  onConnectService
}: {
  email: string
  is2FAEnabled: Atom<boolean | undefined>
  plans: Atom<PlanWithService[]>
  onDeleteUser: Function
  onConnectService: (plan: Plan, selectedService?: TelegramService) => void
  canTrial: Atom<boolean>
  isWhitelisted: Atom<boolean | undefined>
}) => {
  const error = new ErrorState()

  return (
    <div className={style.profile}>
      <DialogError isError={error.state} />
      <DialogHeader className={style.header}>Profile</DialogHeader>
      <DialogContent className={style.profileContent}>
        <div {...classes(style.card)}>
          <ProfileItem label="Email" value={email} />
        </div>
        <F.Fragment>
          {isWhitelisted.view(
            whitelisted =>
              !whitelisted &&
              plans && (
                <div {...classes(style.card)}>
                  <div className={style.profileSection}>Your plans</div>
                  <div className={style.billingPortalWrap}>
                    <span
                      onClick={async () => {
                        const response = await getWriteApi().stripePortal(
                          document.location.pathname
                        )
                        if (response.validation.isValid && response.item) {
                          document.location.href = response.item
                        }
                      }}
                      className={style.billingPortalLink}
                    >
                      See payment history, change payment method or switch plans →
                    </span>
                  </div>
                  <Membership
                    onConnectService={onConnectService}
                    canTrial={canTrial}
                    value={plans}
                  ></Membership>
                </div>
              )
          )}
        </F.Fragment>

        <div {...classes(style.card)}>
          <div className={style.profileSection}>Security</div>
          <Password error={error} />
          <SecondFactor email={email} error={error} is2FAEnabled={is2FAEnabled} />
          <DeleteAccount onDeleteUser={onDeleteUser} error={error} />
        </div>
      </DialogContent>
    </div>
  )
}

const SecondFactor = ({
  email,
  error,
  is2FAEnabled
}: {
  email: string
  error: ErrorState
  is2FAEnabled: Atom<boolean | undefined>
}) => {
  const isGeneratingCode = Atom.create(false)

  const isDisablingOtp = Atom.create(false)

  async function addOTP() {
    try {
      isGeneratingCode.set(true)
      const code = await getTOTPCode()
      children.set(set2fa(code))
    } catch (e) {
      console.log(e)
      error.show(`We got an error during 2FA code generation. Please retry`)
    } finally {
      isGeneratingCode.set(false)
    }
  }

  const initial = () => (
    <div className={style.wrap2fa}>
      <F.span className={style.label2fa}>
        {is2FAEnabled.view(x =>
          x ? (
            <>
              <SvgIcon {...classes(style.icon2fa, style.doneIcon)} icon={Icons.done} />
              Protected
            </>
          ) : (
            <>
              <SvgIcon {...classes(style.icon2fa, style.errorIcon)} icon={Icons.error} />
              Not protected
            </>
          )
        )}
      </F.span>
      <F.Fragment>
        {is2FAEnabled.view(x =>
          !x ? (
            <F.Fragment>
              {isGeneratingCode.view(isLoading => (
                <Button
                  key={'btnTurnOn'}
                  trackingName="turnOn2FABtn"
                  isDarkLoader
                  isLoading={isLoading}
                  className={style.turnOn2faBtn}
                  onClick={() => addOTP()}
                >
                  Turn on
                </Button>
              ))}
            </F.Fragment>
          ) : (
            <div className={style.buttons2fa}>
              <F.Fragment>
                {isGeneratingCode.view(
                  isLoading =>
                    1 * 1 > 2 && (
                      <Button
                        isDarkLoader
                        key={'btnChange2fa'}
                        isLoading={isLoading}
                        onClick={() => addOTP()}
                      >
                        Change 2FA device
                      </Button>
                    )
                )}

                {isDisablingOtp.view(isLoading => (
                  <Button
                    key={'isDisablingOtp'}
                    trackingName="turnOf2FABtn"
                    isLoading={isLoading}
                    isDarkLoader
                    onClick={async () => {
                      try {
                        isDisablingOtp.set(true)
                        await disableTOTP()
                        is2FAEnabled.set(false)
                        children.set(initial())
                      } catch (e) {
                        error.show(`We got an error during turning off. Please retry`)
                      } finally {
                        isDisablingOtp.set(false)
                      }
                    }}
                  >
                    Turn off
                  </Button>
                ))}
              </F.Fragment>
            </div>
          )
        )}
      </F.Fragment>
    </div>
  )

  // todo add input and cancel save button
  const set2fa = (code: string) => (
    <Formik
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const result = await setupTOTP(values.code)
        // console.log('onsubmit', result)

        if (result.isValid) {
          is2FAEnabled.set(true)
          setErrors({})
          children.set(initial())
        } else {
          track('2faForm', 'error', result.message)
          setErrors(result)
          error.show(result.message)
        }

        setSubmitting(false)
        return values
      }}
      initialValues={{ code: '' }}
      validate={() => {
        error.hide()
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className={style.qrCodeBlock}>
          <div className={style.qrBlockWrap}>
            <QRCode
              className={style.qrCode}
              value={`otpauth://totp/AWSCognito:${email}?secret=${code}&issuer=filter.sitg.app`}
            />
            <div>
              <div className={style.qrDescription}>
                Scan QR Code with a two-step verification application (Google Authenticator, Authy,
                1Password, etc).
              </div>
              <div className={style.qrInput}>
                <Input
                  name="code"
                  label="Insert code"
                  helper="Code from two-step verification app"
                />
              </div>
            </div>
          </div>
          <DialogButtons className={style.dialogButtons2fa}>
            <Button
              trackingName="cancel2FABtn"
              onClick={e => {
                e.preventDefault()
                is2FAEnabled.set(false)
                children.set(initial())
              }}
            >
              Cancel
            </Button>
            <MainButton
              isLoading={isSubmitting}
              trackingName="save2FABtn"
              type="submit"
              onClick={e => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              Save
            </MainButton>
          </DialogButtons>
        </div>
      )}
    </Formik>
  )

  const children = Atom.create(initial())

  return (
    <ProfileItem
      {...classes(style.profile2fa)}
      label="Two-Step Verification"
      description="Secure your account with two-step verification code. Each time you sign in to your account, weʼll ask a verification code."
    >
      <Animated transitionType={'appear'} children={children} />
    </ProfileItem>
  )
}

const Password = ({ error }: { error: ErrorState }) => {
  let isSuccessPasswordChange = false
  const passwordRead = () => (
    <ProfileItem
      label="Password"
      className={isSuccessPasswordChange && style.successPasswordChange}
      onChange={() => {
        // console.log('change password')
        isSuccessPasswordChange = false
        passwordChildren.set(passwordWrite)
      }}
      valueClassName={style.passwordReadOnly}
      value="•••••••••••"
    />
  )

  const passwordWrite = (
    <Formik
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const result = await changePassword(values.oldPassword, values.newPassword)
        // console.log('onsubmit', result)

        if (result.isValid) {
          isSuccessPasswordChange = true
          setErrors({})
          passwordChildren.set(passwordRead())
        } else {
          track('changePasswordForm', 'error', result.message)
          setErrors(result)
          error.show(result.message)
        }

        setSubmitting(false)
        return values
      }}
      initialValues={{ oldPassword: '', newPassword: '' }}
      validate={() => {
        error.hide()
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form className={style.passwordForm}>
            <div className={style.changePasswordTitle}>Change password</div>
            <div className={style.inputs}>
              <Input name="oldPassword" label="Old Password" type="password" />
              <Input
                name="newPassword"
                label="New Password"
                helper="Minimal password length is 8"
                helperClass={style.helper}
                type="password"
              />
            </div>
            <DialogButtons className={style.buttons}>
              <Button
                trackingName="cancelChangePasswordBtn"
                onClick={e => {
                  e.preventDefault()
                  isSuccessPasswordChange = false
                  passwordChildren.set(passwordRead())
                }}
              >
                Cancel
              </Button>
              <MainButton
                trackingName="changePasswordSubmitBtn"
                isLoading={isSubmitting}
                type="submit"
                onClick={e => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                Save
              </MainButton>
            </DialogButtons>
          </form>
        )
      }}
    </Formik>
  )

  const passwordChildren = Atom.create(passwordRead())

  return <Animated transitionType={'appear'} children={passwordChildren} />
}

const DeleteAccount = ({ error, onDeleteUser }: { error: ErrorState; onDeleteUser: Function }) => {
  const isDeleting = Atom.create(false)
  return (
    <F.div className={style.deleteAccount}>
      {isDeleting.view(x => (
        <Delete
          trackingName="deleteAccount"
          key={'deleteAccount'}
          label={'Deleting...'}
          onClose={async () => {
            track('deleteAccount', 'submit')
            const x = await getWriteApi().deleteUser()
            if (!x.validation.isValid && x.validation.message) {
              track('deleteAccountForm', 'error', x.validation.message)

              error.show(x.validation.message)
              isDeleting.set(false)
            } else {
              // console.log('Deleted, logout')
              onDeleteUser()
            }
          }}
          isShow={x}
          onUndo={() => isDeleting.set(false)}
        />
      ))}

      <>
        <Button
          trackingName="deleteAccountBtn"
          className={style.deleteButton}
          onClick={() => isDeleting.set(true)}
        >
          Delete account
        </Button>
        <div className={style.deleteDescription}>
          This account will no longer be available, and all your saved filters and authorizations
          will be permanently deleted.
        </div>
      </>
    </F.div>
  )
}

const ProfileItem = ({
  label,
  value,
  description,
  valueClassName,
  className,
  onChange,
  children
}: {
  label: string
  value?: string
  description?: string
  className?: string
  valueClassName?: string
  onChange?: Function
  children?: React.ReactNode
}) => {
  return (
    <div {...classes(style.profileItem, className)}>
      <div className={style.profileLabel}>{label}</div>
      {description && <div className={style.description}>{description}</div>}
      {value && (
        <div {...classes(style.profileValue, valueClassName)}>
          <span className={style.valueWrap}>{value}</span>
          {onChange && (
            <IconButton
              trackingName="profileEditBtn"
              icon={Icons.edit}
              {...classes(style.editButton)}
              onClick={() => onChange()}
            />
          )}
        </div>
      )}
      {children}
    </div>
  )
}
