import * as React from 'react'
import { classes } from '@grammarly/focal'

import { intitials } from '../utils/string'
import { PlaceholderedImage } from './placeholderedImage'
import random from 'random-seed'
import { imageUrl } from '../config'
import { Contact } from '../types'
import * as style from './avatar.styl'

export interface AvatarProps {
  id: string
  name: string
  isUser: boolean
  isBig?: boolean
  className?: string | undefined
  phone: string
}

export const Avatar = ({ id, name, isUser, isBig, className, phone }: AvatarProps) => {
  const r = random.create(id)
  return (
    <PlaceholderedImage
      {...classes(
        style.avatar,
        isBig && style.avatarBig,
        isUser ? style.user : style.chat,
        className
      )}
      src={imageUrl(phone, id)}
      title={name!}
      placeholder={
        <span
          title={name!}
          style={{
            backgroundColor: `rgb(${r.intBetween(0, 180)} , ${r.intBetween(
              0,
              180
            )},  ${r.intBetween(0, 180)})`
          }}
          {...classes(style.placeholder, isBig && style.placeholderBig)}
        >
          {intitials(name!)}
        </span>
      }
    />
  )
}

// Omit<React.HTMLAttributes<HTMLDivElement>, 'id' | 'className'>
export interface AvatarWithLabelProps extends Omit<AvatarProps, 'id' | 'name'> {
  containerClass?: string
  nameClass?: string
  member: Contact
}

export const AvatarWithTitle = ({
  containerClass,
  nameClass,
  member,
  ...props
}: AvatarWithLabelProps) => {
  const providedName = ((member.firstName || '') + ' ' + (member.lastName || '')).trim()
  const calculatedName = providedName || member.username || member.id
  const nameSpan = <span {...classes(style.name, nameClass)}>{calculatedName}</span>

  return (
    <div {...classes(style.avatarWithTitle, containerClass)} data-id={member.id}>
      <Avatar id={member.id} name={calculatedName} {...props} />
      {member.username && providedName ? (
        <span className={style.nameBox}>
          {nameSpan}
          <span className={style.username}>@{member.username}</span>
        </span>
      ) : (
        nameSpan
      )}
    </div>
  )
}
