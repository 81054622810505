import React, { useEffect } from 'react'
import { css, getNormalizedEventCoords, inContainer } from '../utils/dom'
import * as style from '../components/ripple.styl'
import { Observable } from 'rxjs'

export const Ripple = ({
  disabled,
  color
}: {
  disabled?: boolean | Observable<boolean | undefined>
  color?: string
}) => {
  let el: HTMLElement | null
  let timer: NodeJS.Timeout
  let isAnimating = false
  let isPendingRemove = false
  let isMouseDown = false

  function clearElement() {
    if (el) {
      el.removeAttribute('class')
      el.removeAttribute('style')
    }
  }

  function onMouseMove(e: MouseEvent) {
    if (!el || !e.target) return
    if (!inContainer(el.parentNode, e.target as Node)) {
      mouseUp()
    }
  }

  function rippleEffect(e: MouseEvent) {
    // console.log(el && el.parentNode, disabled)
    if (!el || !el.parentNode || disabled) return
    if (e.which === 3 || e.button === 2) return // rightclick

    isAnimating = true
    isPendingRemove = false
    isMouseDown = true
    clearTimeout(timer)
    clearElement()
    const parent = el.parentNode as HTMLElement
    const rect = parent.getBoundingClientRect()
    const { x, y } = getNormalizedEventCoords(
      e,
      { x: window.pageXOffset, y: window.pageYOffset },
      rect
    )

    css(el, { top: y, left: x })

    const parentStyles = css(parent, ['overflow', 'position', 'display'])
    const putStyles: Record<string, string> = {}
    if (parentStyles) {
      if (parentStyles.overflow !== 'hidden') putStyles.overflow = 'hidden'
      if (parentStyles.position === 'static') putStyles.position = 'relative'
      if (parentStyles.display === 'inline') putStyles.display = 'inline-block'
      css(parent, putStyles)
    }

    const maxX = Math.max(x, rect.width - x)
    const maxY = Math.max(y, rect.height - y)
    const dotSize = 2
    const diameter = Math.hypot(maxX, maxY) * 2
    // console.log({ x, y }, { maxX, maxY }, diameter / dotSize)
    css(el, {
      '--ripple-scale': diameter / dotSize
    })
    if (color) {
      css(el, {
        '--ripple-color': color
      })
    }

    requestAnimationFrame(() => {
      document.addEventListener('mousemove', onMouseMove, true)
      window.addEventListener('blur', mouseUp, true)
      if (el) el.classList.add(style.ripple)
    })
  }

  function animationEnd(e: AnimationEvent) {
    if (e.target !== el) return
    isAnimating = false
    if (isPendingRemove || !isMouseDown) {
      isPendingRemove = false
      removeEffect()
    }
  }

  function mouseUp() {
    isMouseDown = false
    removeEffect()
  }
  function removeEffect() {
    if (isAnimating) {
      isPendingRemove = true
      return
    }
    if (!el || !el.classList.contains(style.ripple)) return

    document.removeEventListener('mousemove', onMouseMove, true)
    window.removeEventListener('blur', mouseUp, true)

    requestAnimationFrame(() => {
      if (el) el.classList.add(style.rippleFade)
      clearTimeout(timer)
      timer = setTimeout(() => {
        clearElement()
      }, 200)
    })
  }

  useEffect(() => {
    if (el && el.parentNode) {
      el.parentNode.addEventListener('mousedown', rippleEffect, true)
      el.parentNode.addEventListener('mouseup', mouseUp, true)
      document.addEventListener('animationend', animationEnd, true)
    }
    return () => {
      if (el && el.parentNode) {
        el.parentNode.removeEventListener('mousedown', rippleEffect, true)
        el.parentNode.removeEventListener('mouseup', mouseUp, true)
        document.removeEventListener('animationend', animationEnd, true)
      }
    }
  })
  return (
    <span
      ref={_el => {
        if (_el) el = _el
      }}
    />
  )
}
