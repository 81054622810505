import React from 'react'
import * as style from './membership.styl'
import { PlanWithService, PlanPeriod, Plan, TelegramService } from '../types'
import { TelegramItem } from './services'

import * as servicesStyle from './services.styl'
import { classes, Atom, F } from '@grammarly/focal'
import { dateToString } from '../utils/string'
import { Button, MainLightButton, AddButton, MainButton } from './buttons'
import { PlanBadge } from './planBadge'
import { Features } from './features'
import { PlanChooserDialog } from './forms/planChooser'
import { getWriteApi, planById } from '../model'
import { DialogError } from './dialog'
import { ErrorState } from './forms/utils'
import { FoldableList } from './animatedList'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Ripple } from './ripple'

export const Membership = ({
  value,
  canTrial,
  onConnectService,
  isChooser,
  onOpenChooser,
  onSelect
}: {
  value: Atom<PlanWithService[]>
  onSelect?: (x: Plan) => void
  canTrial: Atom<boolean>
  onConnectService?: (plan: Plan, service?: TelegramService) => void
  isChooser?: boolean
  onOpenChooser?: Function
}) => {
  const isOpenPlanChooser = Atom.create(false)
  const openDialog = () => {
    onOpenChooser && onOpenChooser()
    isOpenPlanChooser.set(true)
  }

  function updatePlan(plan?: Plan) {
    if (!plan) return

    if (!value.get()?.find(x => x.plan.id === plan?.id)) return
    planById(value, plan.id!)
      .lens('plan')
      .set(plan)
  }

  return (
    <div {...classes(style.membership, isChooser && style.chooserMode)}>
      <div className={style.cards}>
        <F.Fragment>
          <FoldableList
            items={
              isChooser ? value.view().pipe(switchMap(xs => of(xs.filter(x => !x.service)))) : value
            }
            onItem={(x, i) => {
              // console.log('render again', x, i)
              const isSavingPlan = Atom.create(false)
              const isCreatingPortalSession = Atom.create(false)

              const error = new ErrorState()
              return (
                <div
                  key={`${x.plan.id}_${x.plan.isCanceled}`}
                  {...classes(style.plan, isChooser && style.planChooser)}
                  onClick={() => {
                    isChooser && onSelect && onSelect(x.plan)
                  }}
                >
                  {isChooser && <Ripple color="#d9e1f6"></Ripple>}
                  <DialogError
                    isAppear
                    className={style.errorCanceling}
                    isError={error.state}
                  ></DialogError>
                  <div className={style.planWrap}>
                    <div>
                      {!isChooser && (
                        <div
                          {...classes(
                            style.item,
                            servicesStyle.serviceItem,
                            servicesStyle.telegram
                          )}
                        >
                          {x.service ? (
                            <div
                              onClick={() =>
                                onConnectService && onConnectService(x.plan, x.service)
                              }
                              className={style.telegramItemWrap}
                            >
                              <Ripple color="#d9e1f6" />
                              <TelegramItem key={x.service.id} data={x.service} />
                            </div>
                          ) : (
                            <div className={style.connectTelegram}>
                              <PlanBadge
                                className={style.addButtonBadge}
                                planType={x.plan.type}
                              ></PlanBadge>
                              <AddButton
                                trackingName="AddServiceBtn"
                                className={style.addButton}
                                onClick={() => {
                                  onConnectService && onConnectService(x.plan)
                                }}
                                tooltip={
                                  'Connect your Telegram account, so you can setup filters for it.'
                                }
                              >
                                Connect Telegram
                              </AddButton>
                            </div>
                          )}
                        </div>
                      )}
                      <div className={style.description}>
                        <div className={style.price}>
                          <b>${x.plan.price}</b>/
                          {x.plan.period === PlanPeriod.month ? 'month' : 'year'}
                          {isChooser && (
                            <PlanBadge
                              className={style.chooserBadge}
                              planType={x.plan.type}
                            ></PlanBadge>
                          )}
                          {x.plan.isTrial && (
                            <PlanBadge className={style.trialBadge}>trial</PlanBadge>
                          )}
                        </div>
                        <div className={style.nextDate}>
                          {x.plan.isCanceled ? (
                            <>
                              Future charges are disabled. <br />
                              Plan will stop working at{' '}
                            </>
                          ) : (
                            ' Next bill date is: '
                          )}
                          <span className={style.nextBill}>
                            {dateToString(new Date(x.plan.nextBill))}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Features plan={x.plan.type}></Features>
                  </div>
                  {!isChooser && (
                    <div className={style.buttons}>
                      {x.plan.isCanceled ? (
                        <F.Fragment>
                          {isSavingPlan.view(isLoading => (
                            <MainButton
                              isLoading={isLoading}
                              trackingName="restorePlanBtn"
                              onClick={async () => {
                                isSavingPlan.set(true)
                                const result = await getWriteApi().plan(x.plan.id, 'renew')
                                if (!result.validation.isValid) {
                                  error.show('Error renewing plan.')
                                } else {
                                  updatePlan(result.item)
                                }
                                isSavingPlan.set(false)
                              }}
                              tooltip={'Plan will be restored.'}
                            >
                              Restore plan
                            </MainButton>
                          ))}
                        </F.Fragment>
                      ) : (
                        <>
                          <F.Fragment>
                            {isCreatingPortalSession.view(isLoading => (
                              <MainLightButton
                                tooltip={'Upgrade/downgrade plan or change billing details.'}
                                isLoading={isLoading}
                                trackingName="switchPlanBtn"
                                isDarkLoader
                                onClick={async () => {
                                  isCreatingPortalSession.set(true)
                                  const response = await getWriteApi().stripePortal(
                                    document.location.pathname
                                  )
                                  if (!response.validation.isValid) {
                                    error.show(response.validation.message)
                                  } else {
                                    console.log(response)
                                    const url = response.item!
                                    document.location.href = url
                                  }
                                  isCreatingPortalSession.set(false)
                                }}
                              >
                                Switch plan
                              </MainLightButton>
                            ))}
                          </F.Fragment>
                          <F.Fragment>
                            {isSavingPlan.view(isLoading => (
                              <Button
                                tooltip={
                                  'Plan will be cancelled but you can still use it till the end of period. You will not be billed unless you resubscribe. '
                                }
                                isLoading={isLoading}
                                isDarkLoader
                                trackingName="cancelPlanBtn"
                                className={style.deleteButton}
                                onClick={async () => {
                                  isSavingPlan.set(true)
                                  const result = await getWriteApi().plan(x.plan.id, 'cancel')
                                  if (!result.validation.isValid) {
                                    error.show('Error canceling plan.')
                                  } else {
                                    updatePlan(result.item)
                                  }
                                  isSavingPlan.set(false)
                                }}
                              >
                                Cancel plan
                              </Button>
                            ))}
                          </F.Fragment>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )
            }}
          />
        </F.Fragment>
        <div className={style.addPlanSection}>
          <MainButton
            onClick={() => {
              openDialog()
            }}
            trackingName="addPlanBtn"
            tooltip={'Subscribe to one of plans and connect Telegram account'}
          >
            Add Plan
          </MainButton>
          {!isChooser && (
            <PlanChooserDialog
              plans={value}
              onClose={() => {
                isOpenPlanChooser.set(false)
              }}
              canTrial={canTrial.get()}
              isOpen={isOpenPlanChooser}
            ></PlanChooserDialog>
          )}
        </div>
      </div>
    </div>
  )
}
