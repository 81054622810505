import memoize from 'lodash/memoize'

import { deleteCognitoUser } from './authApi'
import { apiUrls, isMock } from './config'
import {
  Contact,
  Filter,
  SearchExpression,
  TelegramAuthValidation,
  TelegramAuthValues,
  TelegramRegistrationStep,
  TelegramService,
  TelegramTarget,
  User,
  WebhookTarget,
  ExportJob,
  Plan
} from './types'
import { random, randomBoolean } from './utils/math'

export function authHeader(): RequestInit {
  return {
    headers: {},
    mode: 'cors',
    credentials: 'include'
  }
}

interface UserResponse {
  user?: User
  isChanged: boolean
  ts?: number
}

export async function getUser(ts?: number): Promise<UserResponse> {
  const response = await fetch(apiUrls.getUser(ts), authHeader())

  // if (response.status !== 200) {
  //   console.error(response.status, response.statusText)
  //   throw Error('Api is not available')
  // }

  return response.json()
}

export interface ApiResponse<T> {
  item?: T
  validation: {
    isValid: boolean
    message?: string
    messages?: Record<keyof T, string>
  }
  ts?: number
}

export const limits = {
  telegramService: 10,
  webhookTarget: 5,
  telegramTarget: 5,
  condition: 50,
  filter: 100
}

export function limitMessage(label: string, limit: number) {
  return `Canʼt add more ${label} as limit of ${limit} has been reached. Please, let us know if you need more.`
}

export const errorMessages = {
  PHONE_NUMBER_INVALID: 'Not valid phone number',
  PHONE_ALREADY_ADDED: 'Phone number already added',
  PHONE_CODE_INVALID: 'Not valid code',
  PASSWORD_HASH_INVALID: 'Not valid password',
  NOT_FOUND: 'Item not found. Refresh page',
  SEARCH_EXPRESSION_CHARS_LIMIT: 'Search expression should be < 1000 chars',
  CHATS_FILTER_LIMIT: 'Should be < 500 chats',
  IGNORE_CHATS_FILTER_LIMIT: 'Should be < 500 chats',
  USERS_FILTER_LIMIT: 'Should be < 500 users',
  IGNORE_USERS_FILTER_LIMIT: 'Should be < 500 users',
  TELEGRAM_SERVICES_LIMIT_REACHED: `Can't add more than 10 services.`,
  TELEGRAM_TARGET_LIMIT_REACHED: `Can't add more than 20 Telegram actions.`,
  WEBHOOK_LIMIT_REACHED: `Can't add more than 5 Webhook actions.`,
  SEARCH_EXPRESSIONS_LIMIT_REACHED: `Can't add more than 50 conditions`,
  FILTERS_LIMIT_REACHED: `Can't add more than 100 filters`,
  DUPLICATE_CONDITION: 'Such condition is already added',
  ERROR: 'Oops, we got an error. Please retry.',
  DUPLICATE_TARGET: 'Target with such URL is already added',
  PRO_PLAN_FEATURE: 'Upgrade to Pro to run feature',
  IS_NOT_ELIGIBLE_FOR_TRIAL: 'Number is not eligible for trial. Select non trial plan'
}

export async function writeGetRequest<T>(url: string): Promise<ApiResponse<T>> {
  return writeRequest(url)
}

export async function writePostRequest<T>(url: string, data: Object): Promise<ApiResponse<T>> {
  return writeRequest(url, 'post', data)
}

export async function writeRequest<T>(
  url: string,
  method: 'get' | 'post' = 'get',
  data?: Object
): Promise<ApiResponse<T>> {
  const headers = authHeader()
  if (data && headers.headers) headers.headers['Content-Type'] = 'application/json'

  const response = await fetch(url, {
    ...headers,
    method,
    ...(method === 'post' && data ? { body: JSON.stringify(data) } : undefined)
  })

  if (response.status !== 200) {
    console.error(response.status, response.statusText)
    return {
      validation: {
        isValid: false,
        message: 'Request error. Please retry.'
      }
    }
  }

  const obj = await response.json()

  const messages: Record<keyof T, string> = (obj.validation || {}).messages || {}

  Object.keys(messages).forEach(key => {
    messages[key] = errorMessages[messages[key]] || messages[key]
  })

  obj.validation.message = errorMessages[obj.validation.message] || obj.validation.message

  obj.validation = { ...obj.validation, ...(obj.validation || {}).messages }

  return obj
}

export const searchUsersCached = memoize(searchUsers, (phone, query) => `${phone}_${query}`)

export async function searchUsers(phone: string, query: string): Promise<Contact[]> {
  if (!query) return []
  const response = await fetch(apiUrls.contacts(phone, query), authHeader())
  if (!response.ok) {
    searchUsersCached.cache.delete(`${phone}_${query}`)
    throw Error('searchUsers error')
  }
  const contacts = (await response.json()) as Contact[]
  // fix type mismatch
  contacts.forEach(x => (x.id = x.id.toString()))
  return contacts
}

interface Chat {
  id: number
  title: string
}
export async function getChats(
  phone: string,
  isWritable?: boolean,
  isIncludePrivateChats?: boolean,
  isIncludeBots?: boolean
): Promise<Contact[]> {
  const response = await fetch(
    apiUrls.chats(phone, isWritable, isIncludePrivateChats, isIncludeBots),
    authHeader()
  )
  if (!response.ok) {
    throw Error('getChats error')
  }

  const xs = (await response.json()) as Chat[]

  return xs.map(
    x =>
      ({
        id: x.id.toString(),
        username: x.title
      } as Contact)
  )
}

let lastChatsValue: Contact[] = []
let lastTime = 0
let lastPrivateSetting = false
let lastBotSetting = false
export async function getChatsCached(
  phone: string,
  query: string,
  isIncludePrivateChats: boolean,
  isIncludeBots: boolean,
  ttl = 2 * 1000
) {
  if (
    Date.now() - lastTime < ttl &&
    lastChatsValue.length > 0 &&
    isIncludePrivateChats === lastPrivateSetting &&
    isIncludeBots === lastBotSetting
  )
    return search(lastChatsValue, query)

  lastChatsValue = await getChats(phone, undefined, isIncludePrivateChats, isIncludeBots)
  lastTime = Date.now()
  lastPrivateSetting = isIncludePrivateChats
  lastBotSetting = isIncludeBots
  return search(lastChatsValue, query)
}

let lastWritableChatsValue: Contact[] = []
let lastWritableTime = 0
export async function getWritableChatsCached(
  phone: string,
  query: string,
  ttl = 2 * 1000,
  isIncludePrivateChats = false,
  isIncludeBots = false
) {
  if (Date.now() - lastWritableTime < ttl && lastWritableChatsValue.length > 0)
    return search(lastWritableChatsValue, query)

  lastWritableChatsValue = await getChats(phone, true, isIncludePrivateChats, isIncludeBots)
  lastWritableTime = Date.now()
  return search(lastWritableChatsValue, query)
}

export function search(xs: Contact[], query: string) {
  if (!query.trim()) return xs
  return xs.filter(x => {
    const tokens = x.username!.toLowerCase().split(/[.,:;?_+\[\]\|()<>\s]/gi)
    return tokens.find(t => t.indexOf(query.toLowerCase()) === 0)
  })
}

export interface TelegramRegistrationResult {
  item?: TelegramService
  validation: TelegramAuthValidation
}
export async function registerTelegramService(
  x: TelegramAuthValues,
  _step?: any
): Promise<TelegramRegistrationResult> {
  const response = await writePostRequest<TelegramService>(
    apiUrls.registerTelegramService(x.phone),
    {
      code: x.code,
      password: x.password,
      planId: x.planId
    }
  )

  return {
    ...response,
    validation: {
      ...response.validation,
      isAuthorized: Boolean(response.item && response.ts)
    }
  }
}

export async function deleteTelegramService(phone: string) {
  return writeGetRequest<TelegramService>(apiUrls.deleteTelegramService(phone))
}

export async function pauseTelegramService(phone: string, _isPause: boolean) {
  return writeGetRequest<TelegramService>(apiUrls.toggleTelegramService(phone))
}

export async function pauseTelegramFilter(phone: string, filterId: string, _isPause: boolean) {
  return writeGetRequest<Filter>(apiUrls.toggleTelegramFilter(phone, filterId))
}

export async function editTelegramFilter(phone: string, filterId: string, label?: string) {
  return writePostRequest<Filter>(apiUrls.editTelegramFilter(phone, filterId), { label })
}

export async function deleteTelegramFilter(phone: string, filterId: string) {
  return writeGetRequest<TelegramService>(apiUrls.deleteTelegramFilter(phone, filterId))
}

export async function editTelegramCondition(
  phone: string,
  filterId: string,
  item: SearchExpression,
  conditionId?: string
) {
  return writePostRequest<SearchExpression>(
    apiUrls.editTelegramCondition(phone, filterId, conditionId),
    item
  )
}

export async function deleteTelegramCondition(
  phone: string,
  filterId: string,
  conditionId: string
) {
  return writeGetRequest<Filter>(apiUrls.deleteTelegramCondition(phone, filterId, conditionId))
}

export async function editWebhookTarget(
  phone: string,
  filterId: string,
  item: WebhookTarget,
  targetId?: string
) {
  return writePostRequest<WebhookTarget>(apiUrls.editWebhookTarget(phone, filterId, targetId), item)
}
export async function editTelegramTarget(
  phone: string,
  filterId: string,
  item: TelegramTarget,
  targetId?: string
) {
  return writePostRequest<TelegramTarget>(
    apiUrls.editTelegramTarget(phone, filterId, targetId),
    item
  )
}

export async function deleteTelegramTarget(phone: string, filterId: string, targetId: string) {
  return writeGetRequest<Filter>(apiUrls.deleteTelegramTarget(phone, filterId, targetId))
}

export async function deleteWebhookTarget(phone: string, filterId: string, targetId: string) {
  return writeGetRequest<Filter>(apiUrls.deleteWebhookTarget(phone, filterId, targetId))
}

export async function editExportJob(phone: string, filterId: string, item: ExportJob, id?: string) {
  return writePostRequest<ExportJob>(apiUrls.editExportJob(phone, filterId, id), item)
}

export async function deleteExportJob(
  phone: string,
  filterId: string,
  id: string
): Promise<ApiResponse<Filter>> {
  return writeGetRequest<Filter>(apiUrls.deleteExportJob(phone, filterId, id))
}

export async function deleteUser(): Promise<ApiResponse<string>> {
  const x = await writeGetRequest<string>(apiUrls.deleteUser())
  if (!x.validation.isValid) return x

  const congnitoResult = await deleteCognitoUser()
  return {
    validation: congnitoResult
  }
}

export interface Settings {
  seenWelcome?: boolean
}
export async function settings(item: Settings): Promise<ApiResponse<User>> {
  return writePostRequest<User>(apiUrls.settings(), item)
}

export async function mockRegisterTelegramService(
  values: TelegramAuthValues,
  step?: TelegramRegistrationStep
): Promise<TelegramRegistrationResult> {
  await new Promise(r => setTimeout(r, 100))
  const item: TelegramService = {
    id: Math.random().toString(),
    username: 'Some tg',
    firstName: 'hhe',
    lastName: 'goner',
    phone: values.phone || '2132',
    isPendingCode: false,
    isPendingPassword: false,
    isAuthorized: false,
    planId: 'sub_HOj8wVJnGVrVz0',
    filters: []
  }
  if (step === TelegramRegistrationStep.Phone) {
    if (String(values.phone) === '1') {
      return {
        item,
        validation: {
          phone: 'Not valid phone number',
          isValid: false,
          isAuthorized: false
        }
      }
    }
    console.log(values)
    if (String(values.phone) === '2') {
      return {
        item,
        validation: {
          isValid: false,
          message: 'Something bad happened',
          isAuthorized: false
        }
      }
    }
    if (String(values.phone) === '3') {
      return {
        item,
        validation: {
          isValid: true,
          isAuthorized: true
        }
      }
    }

    return {
      validation: {
        isValid: false,
        isAuthorized: false,
        code: 'Not valid code'
      }
    }
  }
  if (step === TelegramRegistrationStep.Code) {
    if (values.code === '1') {
      return {
        item,
        validation: {
          code: 'Not valid code',
          isValid: false,
          isAuthorized: false
        }
      }
    }
    if (values.code === '2') {
      return {
        item,
        validation: {
          isValid: true,
          isAuthorized: false
        }
      }
    }

    return {
      validation: {
        isValid: false,
        isAuthorized: false,
        password: 'Not valid password'
      }
    }
  }
  if (step === TelegramRegistrationStep.Password) {
    if (values.password === '1') {
      return {
        item,
        validation: {
          password: 'Not valid password',
          isValid: false,
          isAuthorized: false
        }
      }
    }
  }
  return {
    item: { ...item, isAuthorized: step === TelegramRegistrationStep.Password },
    validation: {
      isValid: true,
      isAuthorized: true
    }
  }
}

async function randomFailResponse() {
  await new Promise(r => setTimeout(r, random(400, 1000)))
  const isValid = randomBoolean()
  return {
    validation: {
      message: 'Error sending request. Please retry',
      isValid
    }
  }
}

export async function mockDeleteUser(): Promise<ApiResponse<string>> {
  return randomFailResponse()
}

export async function mockPauseTelegramService(
  _phone: string,
  _isPause: boolean
): Promise<ApiResponse<TelegramService>> {
  return randomFailResponse()
}

export async function mockPauseTelegramFilter(
  _phone: string,
  _filterId: string,
  _isPause: boolean
): Promise<ApiResponse<Filter>> {
  return randomFailResponse()
}

export async function mockDeleteTelegramService(
  _phone: string
): Promise<ApiResponse<TelegramService>> {
  return randomFailResponse()
}

export async function mockDeleteTelegramFilter(
  _phone: string,
  _filterId: string
): Promise<ApiResponse<TelegramService>> {
  return randomFailResponse()
}

export async function mockDeleteTelegramCondition(
  _phone: string,
  _conditionId: string
): Promise<ApiResponse<Filter>> {
  return randomFailResponse()
}

export async function mockEditTelegramTarget(
  _phone: string,
  _filterId: string,
  _item: TelegramTarget
): Promise<ApiResponse<TelegramTarget>> {
  return randomFailResponse()
}

export async function mockDeleteTelegramTarget(
  _phone: string,
  _targetId: string
): Promise<ApiResponse<Filter>> {
  return randomFailResponse()
}

export async function mockEditTelegramFilter(
  _phone: string,
  id?: string,
  label?: string
): Promise<ApiResponse<Filter>> {
  return randomFailResponse()
}

export async function mockEditTelegramCondition(
  _phone: string,
  _filterId: string,
  _item: SearchExpression,
  _conditionId?: string
): Promise<ApiResponse<SearchExpression>> {
  return randomFailResponse()
}

export async function mockEditTelegramWebhook(
  _phone: string,
  _filterId: string,
  _item: WebhookTarget
): Promise<ApiResponse<WebhookTarget>> {
  return randomFailResponse()
}

export async function mockEditExportJob(
  _phone: string,
  _filterId: string,
  _item: ExportJob
): Promise<ApiResponse<ExportJob>> {
  await new Promise(r => setTimeout(r, random(400, 1000)))
  const isValid = randomBoolean()
  const item = { ..._item }
  if (isValid) {
    item.isRunning = true
    item.isOver = false
    item.timeTook = undefined
    item.errorMessage = undefined
    item.date = Date.now()
  }
  return {
    item: isValid ? item : undefined,
    validation: {
      message: 'Error sending request. Please retry',
      isValid
    }
  }
}

export async function mockDeleteExportJob(
  _phone: string,
  _id: string
): Promise<ApiResponse<Filter>> {
  return randomFailResponse()
}

export async function mockSettings(_item: Settings): Promise<ApiResponse<User>> {
  return randomFailResponse()
}

export async function mockStripeSession(
  _plan: string,
  _page: string
): Promise<ApiResponse<string>> {
  return randomFailResponse()
}

export async function stripeSession(plan: string, page: string): Promise<ApiResponse<string>> {
  console.log('calling stripe session', plan, page)
  return writePostRequest<string>(apiUrls.stripeSession(), { page, plan })
}

export async function stripePortal(page: string): Promise<ApiResponse<string>> {
  console.log('calling stripe portal session', page)
  return writePostRequest<string>(apiUrls.stripePortal(), { page })
}

export async function mockStripePortal(_page: string): Promise<ApiResponse<string>> {
  console.log('calling stripe portal session', page)
  return randomFailResponse()
}

export async function mockPlan(
  _planId: string,
  _command: 'cancel' | 'renew'
): Promise<ApiResponse<Plan>> {
  return randomFailResponse()
}
export async function plan(planId: string, command: 'cancel' | 'renew') {
  return writeGetRequest<Plan>(apiUrls.plan(planId, command))
}

export async function assignPlan(phone: string, planId: string) {
  return writeGetRequest<Plan>(apiUrls.assignPlan(phone, planId))
}
export async function mockAssignPlan(_phone: string, _planId: string): Promise<ApiResponse<Plan>> {
  return randomFailResponse()
}

const writeApi = isMock
  ? {
      registerTelegramService: mockRegisterTelegramService,
      deleteTelegramService: mockDeleteTelegramService,
      pauseTelegramService: mockPauseTelegramService,
      editTelegramFilter: mockEditTelegramFilter,
      editTelegramCondition: mockEditTelegramCondition,
      editWebhookTarget: mockEditTelegramWebhook,
      editTelegramTarget: mockEditTelegramTarget,
      editExportJob: mockEditExportJob,
      pauseTelegramFilter: mockPauseTelegramFilter,
      deleteTelegramFilter: mockDeleteTelegramFilter,
      deleteTelegramCondition: mockDeleteTelegramCondition,
      deleteTelegramTarget: mockDeleteTelegramTarget,
      deleteWebhookTarget: mockDeleteTelegramTarget,
      deleteExportJob: mockDeleteExportJob,
      deleteUser: mockDeleteUser,
      settings: mockSettings,
      stripeSession: mockStripeSession,
      plan: mockPlan,
      assignPlan: mockAssignPlan,
      stripePortal: mockStripePortal
    }
  : {
      registerTelegramService,
      deleteTelegramService,
      pauseTelegramService,
      pauseTelegramFilter,
      editTelegramFilter,
      editTelegramCondition,
      editWebhookTarget,
      editTelegramTarget,
      editExportJob,
      deleteExportJob,
      deleteTelegramFilter,
      deleteTelegramCondition,
      deleteTelegramTarget,
      deleteWebhookTarget,
      deleteUser,
      settings,
      stripeSession,
      plan,
      assignPlan,
      stripePortal
    }

type ApiMethods = typeof writeApi

export class WriteApi {
  constructor(private onNewDate: (method: keyof ApiMethods, ts?: number) => void) {}
  methods = this.transform(writeApi)

  transform(xs: ApiMethods) {
    const keys = Object.keys(xs)

    keys.forEach(async (x: keyof ApiMethods) => {
      const method = xs[x]
      xs[x] = async (...args: any[]) => {
        const result = await method.call(null, ...args)
        if (!isNaN(result.ts)) {
          this.onNewDate(x, result.ts)
        }
        return result
      }
    })

    return xs
  }
}
