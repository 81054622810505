import ipRegex from 'ip-regex'

export function plural(value: number, arr: [string, string] | [string, string, string]) {
  if (arr.length === 2) {
    arr.push(arr[1])
  }
  let index = 2
  if (value % 10 === 1 && value % 100 !== 11) index = 0

  if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) index = 1

  return arr[index]
}

export function intitials(x: string) {
  const parts = x
    .replace(/[~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|+=]/g, '')
    .split(/[\s-_]/)
    .map(x => x[0])
  if (parts.length < 2) return x.substr(0, 2)

  return parts[0] + parts[parts.length - 1]
}

export function ellipsisInTheMiddle(s: string, max = 35) {
  return s.length > max
    ? s.substring(0, max / 2 - 1) + '...' + s.substring(s.length - max / 2 + 2, s.length)
    : s
}

export function createUrlRegex() {
  const protocol = `(?:(?:[a-z]+:)//)`
  const auth = '(?:\\S+(?::\\S*)?@)?'
  const ip = ipRegex.v4().source
  const host = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)'
  const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*'
  const tld = `(?:\\.${'(?:[a-z\\u00a1-\\uffff]{2,})'})\\.?`
  const port = '(?::\\d{2,5})?'
  const path = '(?:[/?#][^\\s"]*)?'
  const regex = `(?:${protocol})${auth}(?:localhost|${ip}|${host}${domain}${tld})${port}${path}`

  return new RegExp(`(?:^${regex}$)`, 'i')
}

export const urlRegex = createUrlRegex()

export function isValidUrl(url: string) {
  return urlRegex.test(url)
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function isValidEmail(email: string) {
  return emailRegex.test(email)
}

export function getId(): string {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    // tslint:disable-next-line: no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

export function setIds<T>(xs: T[]): (T & { id: string })[] {
  return xs.map(x => ({ ...x, id: (x as any).id || getId() }))
}

export function dateToString(x: Date, isShort = false, sameMonth = false) {
  let month: string | undefined = isShort ? 'short' : 'long'
  if (sameMonth) month = undefined
  return x.getFullYear() === new Date().getFullYear()
    ? x.toLocaleString('en-us', { month, day: 'numeric' })
    : x.toLocaleString('en-us', {
        year: 'numeric',
        month,
        day: 'numeric'
      })
}

export function timeRangeToString(ms: number) {
  const xs = new Date(ms)
    .toISOString()
    .substr(11, 5)
    .split(':')
    .map(x => parseInt(x, 10))
    .filter(x => x > 0)

  let label = ''
  let m = 0
  let h = 0
  if (xs.length === 0) {
    label = '1m'
    m = 1
  } else if (xs.length === 2) {
    m = xs[1]
    h = xs[0]
    label = `${h}h ${m}m`
  } else {
    m = xs[0] === 0 ? xs[0] + 1 : xs[0]
    label = `${m}m`
  }
  // console.log(xs, label, h, m)

  return {
    label,
    m,
    h
  }
}
