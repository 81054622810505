import { classes } from '@grammarly/focal'
import * as React from 'react'

import { track } from '../utils/tracking'
import * as style from './buttons.styl'
import { Icons, SvgIcon } from './icons'
import { OvalLoader } from './loader'
import { Ripple } from './ripple'
import { Observable } from 'rxjs'
import { TooltipAnchor, tooltipState } from './tooltip'
import { ObservableLike, F } from '@grammarly/focal/dist/src/react'
import { ObservableReactHTMLAttributes } from '@grammarly/focal/dist/src/react/observablePropTypes'

type ButtonTypes = HTMLAnchorElement | HTMLButtonElement

export interface ButtonProps<T extends ButtonTypes> extends ObservableReactHTMLAttributes<T> {
  className?: ObservableLike<string | undefined>
  isSmall?: boolean
  isToggleMode?: boolean
  isToggleStyleLight?: boolean
  isOn?: boolean
  icon?: Icons
  iconRight?: Icons
  iconClassName?: string
  tooltip?: string | Observable<string>
  tooltipAlign?: 'top' | 'bottom'
  onToggle?(value: boolean): void
  isLoading?: boolean
  isDarkLoader?: boolean
  trackingName?: string
}

export const AddIcon = ({ radius, strokeWidth }: { radius: number; strokeWidth: number }) => {
  const start = (strokeWidth + radius) / 2
  const end = radius * 2 - start
  return (
    <svg style={{ width: radius * 2, height: radius * 2 }} className={style.addIcon}>
      <circle cx={radius} cy={radius} r={radius} />
      <line x1={start} y1={radius} x2={end} y2={radius} strokeWidth={strokeWidth} />
      <line x1={radius} y1={start} x2={radius} y2={end} strokeWidth={strokeWidth} />
    </svg>
  )
}

export const MainButton = <T extends ButtonTypes>({ className, ...props }: ButtonProps<T>) => {
  return <Button {...classes(style.mainButton, className)} {...props} />
}

export const MainLightButton = <T extends ButtonTypes>({ className, ...props }: ButtonProps<T>) => {
  return <Button isDarkLoader {...classes(style.mainLightButton, className)} {...props} />
}

export const AddButton = <T extends ButtonTypes>({
  children,
  className,
  disabledText,
  ...otherProps
}: ButtonProps<T> & {
  disabledText?: string | React.ReactNode
}) => {
  return (
    <F.div {...classes(style.buttonWrap, className)}>
      <Button className={style.button} {...otherProps}>
        <AddIcon radius={12} strokeWidth={2} />
        {children}
      </Button>
      {otherProps.disabled && (
        <div className={style.disabledText}>
          <span>{disabledText}</span>
        </div>
      )}
    </F.div>
  )
}
export type ButtonRef = React.Ref<HTMLButtonElement>

export const IconButton = React.forwardRef(
  <T extends ButtonTypes>(
    { icon, className, children, ...otherProps }: ButtonProps<T> & { icon: Icons },
    ref: ButtonRef
  ) => {
    const props = {
      className: classes(style.iconButton, className).className,
      ...otherProps
    }
    return (
      <Button ref={ref} {...props}>
        <SvgIcon icon={icon} />
        {children}
      </Button>
    )
  }
)

export const Button = React.forwardRef(
  <T extends ButtonTypes>(
    {
      className = '',
      onClick,
      isToggleMode,
      isToggleStyleLight,
      isOn,
      onToggle,
      isLoading,
      isDarkLoader,
      isSmall,
      disabled = false,
      icon,
      iconRight,
      iconClassName,
      children,
      tooltip,
      tooltipAlign,
      trackingName,
      ...otherProps
    }: ButtonProps<T>,
    ref: ButtonRef
  ) => {
    disabled = disabled || Boolean(isLoading)
    const [isOnValue, setIsOn] = React.useState(Boolean(isOn))
    React.useEffect(() => {
      setIsOn(Boolean(isOn))
    }, [Boolean(isOn)])

    const props = {
      ...classes(
        style.button,
        isSmall && style.small,
        (isToggleMode || isOn !== undefined) &&
          (isToggleStyleLight ? style.toogleButtonLight : style.toggleButton),
        isOnValue && style.isOn,
        isOnValue && !isToggleMode && style.disableInteractions,
        isLoading && style.loading,
        icon && style.buttonWithIcon,
        className
      ),
      disabled,
      ...otherProps
    }

    const i = iconRight || icon
    const iconEl = i && (
      <SvgIcon
        {...classes(style.buttonIcon, iconRight && style.buttonIconRight, iconClassName)}
        icon={i}
      />
    )

    let content = (
      <>
        {icon && !iconRight && iconEl}
        {children}
        {iconRight && iconEl}
      </>
    )

    if (isOn !== undefined || isToggleMode) {
      content = (
        <>
          <span className={style.contentWrap}>{content}</span>
          <span aria-hidden className={style.sizer}>
            {content}
          </span>
        </>
      )
    }

    const tooltipedContent = tooltip ? (
      <TooltipAnchor
        wrapperClassName={style.tooltip}
        tooltip={tooltipState}
        content={tooltip}
        align={tooltipAlign || 'top'}
      >
        {content}
      </TooltipAnchor>
    ) : (
      content
    )

    return (
      <F.button
        mount={ref}
        onClick={e => {
          if (trackingName) {
            track(trackingName)
          }
          if (isToggleMode) {
            setIsOn(!isOnValue)
            if (onToggle) onToggle(!isOnValue)
          }
          if (onClick && !(onClick instanceof Observable)) onClick(e as any)
        }}
        {...(props as React.HTMLProps<HTMLButtonElement>)}
      >
        <Ripple disabled={disabled} />
        {isLoading && <OvalLoader isDarkLoader={isDarkLoader} />}
        {tooltipedContent}
      </F.button>
    )
  }
)

// export interface ToggleButtonProps extends React.InputHTMLAttributes<any> {
//   className?: string
//   isLoading?: boolean
// }

// export const ToggleButton = React.forwardRef(
//   (
//     { className = '', isLoading, disabled = false, children, ...otherProps }: ToggleButtonProps,
//     ref: React.Ref<HTMLInputElement>
//   ) => {
//     disabled = disabled || Boolean(isLoading)
//     const props = {
//       className: classes(style.button, style.toggleButton, className).className,
//       disabled,
//       ...otherProps
//     }

//     return (
//       <label>
//         <input type="checkbox" ref={ref} {...props as React.HTMLProps<HTMLInputElement>} />
//         <Ripple disabled={disabled} />
//         {isLoading && <OvalLoader />}
//         {children}
//       </label>
//     )
//   }
// )
