import { Atom, F, classes } from '@grammarly/focal'
import { connect, FieldArray, Formik } from 'formik'
import React from 'react'
import { ExpandPanel } from './expandLink'
import { BucketForm } from './bucket'
import { IconButton, AddButton } from '../buttons'
import { getWritableChatsCached, limitMessage, limits } from '../../api'
import {
  Contact,
  TelegramTarget,
  WebhookTarget,
  MediaDownloadSelector,
  Filter,
  ExportJob,
  PlanType
} from '../../types'
import { track } from '../../utils/tracking'
import { Animated } from '../animated'
import { Button } from '../buttons'
import * as buttonsStyle from '../buttons.styl'
import { DialogButtons, DialogContent, DialogForm, DialogHeader } from '../dialog'
import { Icons, SvgIcon } from '../icons'
import { Input } from '../input'
import * as inputStyle from '../input.styl'
import { MemberSelect } from '../memberSelect'
import * as style from './targets.styl'
import { ErrorState, useSubmit } from './utils'
import { ExportContent } from './export'
import { getId } from '../../utils/string'
import { MediaForm } from './mediaForm'
import { BillingPortalLink } from '../billingPortalLink'
import { TooltipAnchor, tooltipState } from '../tooltip'

export interface TargetResult {
  phone: string
  webhook?: WebhookTarget
  telegram?: TelegramTarget
  export?: ExportJob
}

export interface ChooserProps {
  phone: string
  filter: Filter
  onFinish?: Function
  onNextStep: Function
  editItem?: TargetResult
  planType?: PlanType
  isDisabledTelegram?: boolean
  isDisabledWebhook?: boolean
  serviceHasExportJob?: boolean
  step: Atom<Step>
  onSubmit(
    x: TargetResult
  ): Promise<WebhookTargetValidation | TelegramTargetValidation | ExportJobValidation>
}

export type Step = 'chooser' | 'webhook' | 'telegram' | 'export'

export const Chooser = ({
  phone,
  filter,
  editItem,
  step,
  serviceHasExportJob,
  isDisabledWebhook,
  isDisabledTelegram,
  planType,
  onFinish,
  onSubmit,
  onNextStep
}: ChooserProps) => {
  const currentStep = step
  function setStep(step: Step) {
    currentStep.set(step)
  }

  const error = new ErrorState()

  const view = currentStep.view(step =>
    ({
      ['chooser']: () => (
        <DialogContent>
          <DialogForm>
            <div className={style.chooserSubheader}>
              Forward <b>new messages</b> to
            </div>
            <div className={style.chooserButtons}>
              <div className={style.buttonWrap}>
                <Button
                  trackingName="chooserWebhookBtn"
                  autoFocus
                  disabled={isDisabledWebhook || planType === PlanType.basic}
                  onClick={() => {
                    onNextStep()
                    setStep('webhook')
                  }}
                >
                  <SvgIcon icon={Icons.webhook} className={style.icon} />
                  Webhook
                </Button>
                {isDisabledWebhook && (
                  <div className={style.disabledText}>
                    <span>{limitMessage('webhook targets', limits.webhookTarget)}</span>
                  </div>
                )}
                {planType === PlanType.basic && (
                  <div className={style.disabledText}>
                    <span>
                      To use Webhooks feature{' '}
                      <BillingPortalLink showError={err => error.show(err)} />.
                    </span>
                  </div>
                )}
              </div>
              <div className={style.buttonWrap}>
                <Button
                  trackingName="chooserTelegramBtn"
                  autoFocus
                  disabled={isDisabledTelegram}
                  onClick={() => {
                    onNextStep()
                    setStep('telegram')
                  }}
                >
                  <SvgIcon icon={Icons.telegram} className={style.icon} />
                  Telegram
                </Button>
                {isDisabledTelegram && (
                  <div className={style.disabledText}>
                    <span>{limitMessage('telegram targets', limits.webhookTarget)}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={style.chooserSubheader}>
              Run filter through <b>old messages</b> to export them in JSON
            </div>
            <div className={style.chooserExport}>
              <Button
                onClick={() => {
                  onNextStep()
                  setStep('export')
                }}
                className={style.exportBtn}
                disabled={Boolean(
                  serviceHasExportJob ||
                    filter.searchExpressions.length === 0 ||
                    filter.isPaused ||
                    planType === PlanType.basic
                )}
              >
                Export
              </Button>
              {planType === PlanType.basic && (
                <div className={style.disabledText}>
                  <span>
                    To use export feature <BillingPortalLink showError={err => error.show(err)} />.
                  </span>
                </div>
              )}
              {Boolean(serviceHasExportJob) ? (
                <div className={style.disabledText}>
                  <span>
                    Can only run one export per service. Wait till the end or cancel a current
                    active job.
                  </span>
                </div>
              ) : filter.searchExpressions.length === 0 ? (
                <div className={style.disabledText}>
                  <span>Add filter rules to run export.</span>
                </div>
              ) : (
                filter.isPaused && (
                  <div className={style.disabledText}>
                    <span>Resume filter to run export.</span>
                  </div>
                )
              )}
            </div>
          </DialogForm>
        </DialogContent>
      ),

      ['webhook']: () => (
        <WebhookTargetForm
          editItem={editItem && editItem.webhook}
          onFinish={onFinish}
          errorState={error}
          onSubmit={x =>
            onSubmit({
              phone,
              webhook: x
            })
          }
        />
      ),

      ['export']: () => (
        <ExportContent
          error={error}
          filter={filter}
          onSubmit={x => onSubmit({ phone, export: x }) as Promise<ExportJobValidation>}
          onFinish={onFinish}
          state={Atom.create<ExportJob>({
            id: getId(),
            start: Date.now() - 10 * 24 * 60 * 60 * 1000,
            end: Date.now(),
            downloadType: 'file',
            mediaSelectors: [MediaDownloadSelector.photo],
            chatIds: []
          })}
        />
      ),

      ['telegram']: () => (
        <TelegramTargetForm
          editItem={editItem && editItem.telegram}
          phone={phone}
          onFinish={onFinish}
          errorState={error}
          onSubmit={x =>
            onSubmit({
              phone,
              telegram: x
            }) as Promise<TelegramTargetValidation>
          }
        />
      )
    }[step]())
  )

  const backButton = (
    <Button
      iconClassName={style.backIcon}
      className={style.buttonBack}
      icon={Icons.arrowLeft}
      onClick={() => {
        setStep('chooser')
      }}
    >
      Back
    </Button>
  )

  const getTitle = (x: Step) =>
    x === 'telegram' || x === 'webhook'
      ? 'Forward to'
      : x === 'chooser'
      ? 'Choose action'
      : 'Export'

  return (
    <>
      <DialogContent isError={error.state}>
        <Animated
          children={currentStep.view(x => x !== 'chooser' && !editItem && backButton)}
          transitionType="appear"
        />
        <F.Fragment>
          {currentStep.view(x => (
            <DialogHeader className={style.targetsHeader}>{getTitle(x)}</DialogHeader>
          ))}
        </F.Fragment>
      </DialogContent>
      <Animated children={view} transitionType="appear" />
    </>
  )
}

export interface ExportJobValidation {
  bucket?: string
  message?: string
  isValid: boolean
}

export interface TelegramTargetValidation {
  name?: string
  chat?: string
  notify: string
  message?: string
  isValid: boolean
}

export const emptyReplacement = () => ({
  search: '',
  replacement: ''
})

export const initialValues: TelegramTarget & {
  isIncludePrivateChats: boolean
  isIncludeBots: boolean
} = {
  id: '',
  chat: -1,
  name: '',
  isCopy: false,
  isDebounceLink: false,
  isSkipLink: false,
  notify: false,
  isIncludePrivateChats: false,
  isIncludeBots: false,
  replacements: [emptyReplacement()]
}

export interface TelegramTargetProps {
  phone: string
  editItem?: TelegramTarget
  errorState: ErrorState
  onFinish?: Function
  onSubmit?(x: TelegramTarget): Promise<TelegramTargetValidation>
}

export const ReplacementForm = connect<{ index: number }>(props => {
  const f = (x: string) => `replacements.${props.index}.${x}`
  const v = (x: string) => props.formik.values['replacements'][props.index][x]

  const isRegExp = Boolean(v('isRegExp'))
  const isCaseSensitive = Boolean(v('isCaseSensitive'))
  const isWholeWord = Boolean(v('isWholeWord'))

  return (
    <>
      <Input
        maxLength={500}
        autoFocus
        containerClass={inputStyle.inputMiddle + ' ' + style.textarea}
        name={f('search')}
        label={isRegExp ? 'Text to replace (RegExp)' : 'Text to replace'}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          // console.log(f('search'), e.target.value)
          props.formik.setFieldValue(f('search'), e.target.value)
        }}
        isTextarea
        button={
          <div className={style.inputFlags}>
            <TooltipAnchor tooltip={tooltipState} content={'Match Case'} align="top">
              <IconButton
                isToggleMode
                isToggleStyleLight
                isOn={isCaseSensitive}
                icon={Icons.caseSensitive}
                onToggle={value => {
                  props.formik.setFieldValue(f('isCaseSensitive'), value)
                }}
              />
            </TooltipAnchor>
            <TooltipAnchor tooltip={tooltipState} content={'Match Whole Word'} align="top">
              <IconButton
                isToggleMode
                isToggleStyleLight
                isOn={isWholeWord}
                icon={Icons.wholeWord}
                onToggle={value => {
                  props.formik.setFieldValue(f('isWholeWord'), value)
                }}
              />
            </TooltipAnchor>
            <TooltipAnchor tooltip={tooltipState} content={'Use Regular Expression'} align="top">
              <IconButton
                isToggleMode
                isToggleStyleLight
                isOn={isRegExp}
                onToggle={value => {
                  props.formik.setFieldValue(f('isRegExp'), value)
                }}
                icon={Icons.regexp}
              />
            </TooltipAnchor>
          </div>
        }
      />
      <Input
        maxLength={500}
        containerClass={inputStyle.inputMiddle}
        name={f('replacement')}
        label={'Replacement value'}
        // onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        //   props.formik.setFieldValue(f('replacement'), e.target.value)
        // }}
        helper={'Leave blank if you want to delete matched text'}
      />
    </>
  )
})

export const Replacements = connect(props => {
  const values = props.formik.values as TelegramTarget
  return (
    <FieldArray name="replacements">
      {({ remove, push }) => (
        <div>
          {values.replacements.length > 0 &&
            values.replacements.map((_, index) => (
              <div className="row" key={index}>
                <ReplacementForm index={index} />

                <div className={style.removeBtnWrap}>
                  <Button className={style.removeTransformBtn} onClick={() => remove(index)}>
                    Remove
                  </Button>
                </div>
              </div>
            ))}

          <AddButton
            tooltip={'Transforms allow to replace keywords in a message text'}
            className={style.addTransformBtn}
            onClick={() => push(emptyReplacement())}
          >
            Add transform
          </AddButton>
        </div>
      )}
    </FieldArray>
  )
})

export const TelegramTargetForm = (props: TelegramTargetProps) => {
  let submit: Function
  useSubmit(() => submit && submit())
  const error = props.errorState
  // console.log({
  //   ...initialValues,
  //   ...props.editItem
  // })
  const isExpandedTransform = Atom.create(!!props.editItem?.replacements.find(x => x.search))

  return (
    <DialogContent>
      <Formik
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!props.onSubmit) return

          const result = await props.onSubmit(values)

          if (result.isValid && props.onFinish) {
            props.onFinish()
            track('telegramTargetForm', 'success')
          } else {
            track('telegramTargetForm', 'error', result.message)
            setErrors(result)
            error.show(result.message)
          }

          setSubmitting(false)
        }}
        validate={() => {
          error.hide()
        }}
        initialValues={
          props.editItem
            ? {
                ...initialValues,
                ...props.editItem
              }
            : initialValues
        }
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          submit = handleSubmit
          const chat: Contact | undefined =
            values.chat !== -1 ? { id: values.chat.toString(), username: values.name } : undefined

          const isIncludePrivateChats = Boolean(values.isIncludePrivateChats)
          const isIncludeBots = Boolean(values.isIncludeBots)
          return (
            <>
              <DialogForm>
                <MemberSelect
                  isSingle
                  phone={props.phone}
                  type="chat"
                  label="Telegram chat"
                  getData={(phone, query) =>
                    getWritableChatsCached(
                      phone,
                      query,
                      undefined,
                      isIncludePrivateChats,
                      isIncludeBots
                    )
                  }
                  openOnFocus
                  value={chat && [chat]}
                  onChange={x => {
                    if (x && x.length === 1) {
                      setFieldValue('chat', x[0].id)
                      setFieldValue('name', x[0].username)
                    } else {
                      setFieldValue('chat', -1)
                      setFieldValue('name', undefined)
                    }
                  }}
                  helper="Matched messages will be forwarded to this chat"
                  button={
                    <div className={style.memberInputFlags}>
                      <TooltipAnchor
                        tooltip={tooltipState}
                        content={'Include private 1:1 chats'}
                        align="top"
                      >
                        <IconButton
                          isToggleMode
                          isToggleStyleLight
                          isOn={isIncludePrivateChats}
                          icon={Icons.people}
                          onToggle={value => {
                            setFieldValue('isIncludePrivateChats', value)
                          }}
                        />
                      </TooltipAnchor>
                      <TooltipAnchor
                        tooltip={tooltipState}
                        content={'Include chats with bots'}
                        align="top"
                      >
                        <IconButton
                          isToggleMode
                          isToggleStyleLight
                          isOn={isIncludeBots}
                          icon={Icons.bot}
                          onToggle={value => {
                            setFieldValue('isIncludeBots', value)
                          }}
                        />
                      </TooltipAnchor>
                    </div>
                  }
                />

                <Input
                  type="checkbox"
                  name="isCopy"
                  label="Copy messages"
                  containerClass={style.chatsPadding}
                  helper={`Post as it’s your message without ’forwarded’ header`}
                />
                <div {...classes(style.debounceWrap, values.isCopy && style.showDebounce)}>
                  <ExpandPanel
                    tooltip="Replace text in forwarded message"
                    expandLinkChildren={'Transform message'}
                    expandIconLeft
                    expandLinkWrapClassName={style.transformLink}
                    isExpanded={isExpandedTransform}
                  >
                    <div className={style.excludeWrap}>
                      <Replacements />
                    </div>
                  </ExpandPanel>

                  <Input
                    type="checkbox"
                    name="isDebounceLink"
                    label="Merge messages"
                    containerClass={style.chatsPadding}
                    helper={`Try to edit message instead of posting new. Reduces visual noise.`}
                  />
                </div>

                <Input
                  type="checkbox"
                  name="isSkipLink"
                  label={'Skip links'}
                  containerClass={style.chatsPadding}
                  helper={`Don’t post a link to the original message`}
                />

                {/* <Input
                  type="checkbox"
                  name="isNotify"
                  label="Notify with @sitg_filter_bot"
                  containerClass={style.chatsPadding}
                  helper={
                    'You should manually add @sitg_filter_bot to the chat to see messages from it.'
                  }
                /> */}
              </DialogForm>
              <DialogButtons>
                <Button
                  trackingName="telegramTargetCancel"
                  onClick={() => {
                    if (props.onFinish) props.onFinish()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  trackingName="telegramTargetSubmit"
                  isLoading={isSubmitting}
                  className={buttonsStyle.mainButton}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Save
                </Button>
              </DialogButtons>
            </>
          )
        }}
      </Formik>
    </DialogContent>
  )
}

export interface WebhookTargetValidation {
  url?: string
  secret?: string
  message?: string
  isValid: boolean
}

export const initialWebhookValues: WebhookTarget = {
  id: '',
  url: '',
  secret: '',
  mediaSelectors: [MediaDownloadSelector.photo]
}

export interface WebhookTargetProps {
  onFinish?: Function
  editItem?: WebhookTarget
  errorState: ErrorState
  onSubmit?(x: WebhookTarget): Promise<WebhookTargetValidation>
}

export const WebhookTargetForm = (props: WebhookTargetProps) => {
  let submit: Function
  useSubmit(() => submit && submit())
  const error = props.errorState
  const isExpandedMediaForm = Atom.create(false)
  const isExpandedBucket = Atom.create(Boolean(props.editItem?.bucket))

  return (
    <DialogContent>
      <Formik
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!props.onSubmit) return

          const result = await props.onSubmit(values)

          if (result.isValid && props.onFinish) {
            props.onFinish()
            track('webhookTargetForm', 'success')
          } else {
            setErrors(result)
            error.show(result.message)
            track('webhookTargetForm', 'error', result.message)
          }

          setSubmitting(false)
        }}
        validate={() => {
          error.hide()
        }}
        initialValues={props.editItem || initialWebhookValues}
      >
        {({ handleSubmit, isSubmitting }) => {
          submit = handleSubmit
          return (
            <>
              <DialogForm>
                <Input
                  autoFocus
                  containerClass={inputStyle.inputMiddle}
                  name="url"
                  label="Webhook URL"
                  maxLength={300}
                  helper={
                    <>
                      URL to HTTP POST-handler. Example: https://site.io/handler.{' '}
                      <a
                        href="https://github.com/sitg/filter#how-to-configure-webhook"
                        target="_blank"
                      >
                        See documentation
                      </a>
                      .
                    </>
                  }
                />
                <div style={{ marginTop: '18px' }} />
                <Input
                  containerClass={inputStyle.inputMiddle}
                  name="secret"
                  type="password"
                  label="Secret"
                  maxLength={200}
                  helper={'Use secret to verify validity of request.'}
                />
                <div>
                  <MediaForm
                    className={style.mediaForm}
                    expandLinkWrapClassName={style.mediaLabel}
                    buttonsClassName={style.mediaButtons}
                    isShowSelectedItems
                    expandLink={'Include media'}
                    possibleValues={MediaDownloadSelector}
                    tooltip={'Decide what media resources you want to forward with messages'}
                    isExpanded={isExpandedMediaForm}
                  />
                </div>
                <div>
                  <ExpandPanel
                    tooltip="Setup custom bucket"
                    expandLinkChildren={'Use your AWS S3 bucket to store media'}
                    expandIconLeft
                    expandLinkWrapClassName={style.webhookBucketLink}
                    isExpanded={isExpandedBucket}
                  >
                    <BucketForm instructionOnBottom />
                  </ExpandPanel>
                </div>
              </DialogForm>
              <DialogButtons>
                <Button
                  trackingName="webhookTargetCancelBtn"
                  onClick={() => {
                    if (props.onFinish) props.onFinish()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  trackingName="webhookTargetSubmitBtn"
                  isLoading={isSubmitting}
                  className={buttonsStyle.mainButton}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Save
                </Button>
              </DialogButtons>
            </>
          )
        }}
      </Formik>
    </DialogContent>
  )
}
