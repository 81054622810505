import { classes, F } from '@grammarly/focal'
import * as React from 'react'

import * as style from './index.styl'
import { ITooltipModel, TooltipAlignment } from './model'

export const ESC_CODE = 27
export const Tooltip = ({ model }: { model: ITooltipModel }) => {
  let el: HTMLElement | undefined = undefined

  const scrollHandler = () => {
    if (model.isVisible) model.hide()
  }

  const keyDownHandler = (e: KeyboardEvent) => {
    if (model.isVisible && e.keyCode === ESC_CODE) model.hide()
  }

  React.useEffect(() => {
    document.addEventListener('scroll', scrollHandler, true)
    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('mousedown', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler, true)
      document.removeEventListener('keydown', keyDownHandler)
      document.addEventListener('mousedown', scrollHandler)
      model.hide()
    }
  })

  return (
    <F.div mount={_el => (el = _el!)} className={style.wrap}>
      {model.tooltip.view(x => {
        const offsetParentCorrection = el
          ? el.offsetParent!.getBoundingClientRect()
          : { top: 0, left: 0 }
        const styleObj = {
          top: x.visible ? x.pos.top - offsetParentCorrection.top : -10000,
          left: x.pos.left - offsetParentCorrection.left,
          opacity: x.visible ? 1 : 0,
          visibility: x.visible ? 'visible' : ('none' as any),
          transition: x.visible ? '' : 'none'
        }

        const alignClass = x.alignment === TooltipAlignment.top ? 'top' : 'bottom'
        return (
          <div {...classes(style.tooltip, style[alignClass], x.className)} style={styleObj}>
            {x.content}
          </div>
        )
      })}
    </F.div>
  )
}
