// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:633ce789-58b5-4432-9d7f-db3583b67d11',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_SGWxlvdp1',
  aws_user_pools_web_client_id: '585uj9nu3nqclhp2819tdpgq97',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS'
}

export default awsmobile
