import React from 'react'
import { Button } from '../buttons'

import * as buttonsStyle from '../buttons.styl'

import { Formik } from 'formik'
import { classes, Atom } from '@grammarly/focal'

import { Input } from '../input'
import { DialogHeader, DialogForm, DialogButtons, DialogContent } from '../dialog'
import { Filter } from '../../types'
import * as style from './telegram.styl'
import { useSubmit, ErrorState } from './utils'
import { track } from '../../utils/tracking'

export interface FilterValues {
  label?: string
}

const initialValues: FilterValues = { label: '' }

export interface FilterValidation extends FilterValues {
  message?: string
  isValid: boolean
}

export interface FilterProps {
  currentFilter: Atom<Filter>
  editItem?: Filter
  onFinish: Function
  onSubmit(x: FilterValues): Promise<FilterValidation>
}

export const AddFilter = ({ currentFilter, editItem, onFinish, onSubmit }: FilterProps) => {
  let submit: Function
  useSubmit(() => submit && submit())

  const error = new ErrorState()
  return (
    <DialogContent isError={error.state}>
      <DialogHeader>{editItem ? 'Edit' : 'Add'} Filter</DialogHeader>
      <Formik
        initialValues={editItem || initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const result = await onSubmit(values)
          if (!result.isValid) {
            setErrors(result)
            error.show(result.message)
            track('filterForm', 'error', result.message)
          } else {
            track('filterForm', 'success')
          }

          setSubmitting(false)
        }}
        validate={values => {
          error.hide()
          currentFilter.lens('label').modify(() => values.label || 'Filter')
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          submit = handleSubmit
          return (
            <>
              <DialogForm {...classes(style.filter)}>
                <Input
                  autoFocus
                  maxLength={40}
                  name="label"
                  label="Filter name"
                  title=""
                  helper="example: “brand” mentions"
                />
              </DialogForm>
              <DialogButtons>
                <Button trackingName="filterCancelBtn" onClick={() => onFinish()}>
                  Cancel
                </Button>
                <Button
                  trackingName="filterSubmitBtn"
                  className={buttonsStyle.mainButton}
                  isLoading={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  {editItem ? 'Save' : 'Continue'}
                </Button>
              </DialogButtons>
            </>
          )
        }}
      </Formik>
    </DialogContent>
  )
}
