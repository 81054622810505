import { F, Atom, classes } from '@grammarly/focal'
import React from 'react'

import { Animated } from '../animated'
import * as config from '../../config'
import { Button, MainLightButton } from '../buttons'
import { DialogContent, DialogForm, DialogHeader } from '../dialog'
import { Icons } from '../icons'
import * as style from './planChooser.styl'
import { ErrorState } from './utils'
import { FeaturesChooser } from '../features'
import { Ripple } from '../ripple'
import { PlanPeriod, PlanType, PlanWithService } from '../../types'
import { getWriteApi } from '../../model'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { Dialog, DialogButtons } from '../dialog'

export type PlanChooserStep = 'chooser' | 'basic' | 'pro'

export interface PlanChooserProps {
  step: Atom<PlanChooserStep>
  hasPlans: boolean
  canTrial: boolean
  onClose: Function
  onRootBack?: Function
}

export const PeriodItem = ({
  isYear,
  price,
  canTrial,
  onClick
}: {
  onClick: Function
  isYear?: boolean
  canTrial?: boolean
  price: number
}) => {
  const isLoading = Atom.create(false)
  return (
    <div
      onClick={() => {
        isLoading.set(true)
        onClick(isLoading)
      }}
      className={style.periodItem}
    >
      <Ripple />
      <div className={style.periodItemWrap}>
        <div className={style.periodNameWrap}>
          <div className={style.periodName}>{isYear ? 'Annual' : 'Monthly'}</div>
          {isYear && (
            <div className={style.periodDescription}>
              {'Billed as one payment of $' + price * 12}
            </div>
          )}
        </div>
        <div className={style.periodPriceWrap}>
          <span className={style.periodPrice}>${price}</span>
          <span className={style.periodMonth}>/month</span>
        </div>
        <F.div className={style.periodButtonWrap}>
          {isLoading.view(loading => (
            <MainLightButton trackingName="planChooserSelectBtn" isLoading={loading}>
              {canTrial ? 'Start trial' : 'Select'}
            </MainLightButton>
          ))}
        </F.div>
      </div>
    </div>
  )
}

export const PeriodChooser = ({
  monthPrice,
  monthPriceYearly,
  canTrial,
  onSelect
}: {
  monthPrice: number
  monthPriceYearly: number
  canTrial: boolean
  onSelect: (x: PlanPeriod) => void
}) => {
  return (
    <DialogContent {...classes(style.periods, style.chooserContent)}>
      <div className={style.periodsWrap}>
        <PeriodItem
          onClick={() => onSelect(PlanPeriod.month)}
          canTrial={canTrial}
          price={monthPrice}
        ></PeriodItem>
        <PeriodItem
          canTrial={canTrial}
          onClick={() => onSelect(PlanPeriod.year)}
          price={monthPriceYearly}
          isYear
        ></PeriodItem>
        <div className={style.disclaimer}>
          When you subscribe to a plan you are agreeing to our{' '}
          <a target="_blank" href="https://filter.sitg.app/policies/terms">
            Terms of Service.
          </a>
          <br />
          {canTrial && ' Trial length is 7 days.'}
        </div>
      </div>
    </DialogContent>
  )
}

let stripe: Stripe | undefined | null = undefined

export async function getStripe() {
  if (stripe) return stripe
  stripe = await loadStripe(config.stripe.key)

  return stripe
}

export const PlanChooser = ({
  step,
  canTrial,
  hasPlans,
  onClose,
  onRootBack
}: PlanChooserProps) => {
  const error = new ErrorState()
  const currentStep = step
  function setStep(step: PlanChooserStep) {
    currentStep.set(step)
  }

  const chooser = (
    <DialogContent className={style.chooserContent}>
      <DialogForm>
        <FeaturesChooser
          onSelectPlan={type => {
            setStep(type)
          }}
        ></FeaturesChooser>
      </DialogForm>
    </DialogContent>
  )

  const backButton = (
    <Button
      iconClassName={style.backIcon}
      className={style.buttonBack}
      icon={Icons.arrowLeft}
      trackingName="planChooserBackBtn"
      onClick={() => {
        if (currentStep.get() === 'chooser' && onRootBack) onRootBack()
        setStep('chooser')
      }}
    >
      Back
    </Button>
  )

  const getTitle = (x: PlanChooserStep) => {
    switch (x) {
      case 'chooser':
        return 'Choose your plan'
      case 'basic':
        return 'Basic plan'
      case 'pro':
        return 'Pro plan'
      default:
        throw Error(`non-existing plan ${x}`)
    }
  }

  const onSelect = async (type: PlanType, period: PlanPeriod) => {
    // console.log(type, period)

    const response = await getWriteApi().stripeSession(
      period + '_' + type,
      document.location.pathname
    )
    // console.log(response)
    if (!response.validation.isValid) {
      error.show(response.validation.message)
    } else {
      // console.log(response)
      const sessionId = response.item!
      const stripe = await getStripe()
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({
          sessionId
        })
        if (error) console.error('error redirecting to checkout', error)
      }
    }
  }

  const pro = (
    <PeriodChooser
      onSelect={period => onSelect(PlanType.pro, period)}
      monthPrice={60}
      monthPriceYearly={50}
      canTrial={canTrial}
    ></PeriodChooser>
  )
  const basic = (
    <PeriodChooser
      onSelect={period => onSelect(PlanType.basic, period)}
      monthPrice={30}
      monthPriceYearly={25}
      canTrial={canTrial}
    ></PeriodChooser>
  )

  const view = currentStep.view(step =>
    ({
      ['chooser']: () => chooser,
      ['basic']: () => basic,
      ['pro']: () => pro
    }[step]())
  )

  return (
    <>
      <DialogContent className={style.chooserContent} isError={error.state}>
        <Animated
          children={currentStep.view(
            x => (x !== 'chooser' || onRootBack) && !(!hasPlans && x === 'chooser') && backButton
          )}
          transitionType="appear"
        />
        <F.Fragment>
          {currentStep.view(x => (
            <DialogHeader className={style.chooserHeader}>{getTitle(x)}</DialogHeader>
          ))}
        </F.Fragment>
      </DialogContent>
      <Animated children={view} transitionType="appear" />
      <DialogButtons>
        <Button
          trackingName="choosePlanCancel"
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
      </DialogButtons>
    </>
  )
}

export const PlanChooserDialog = ({
  isOpen,
  step = Atom.create('chooser'),
  canTrial,
  plans,
  onClose
}: {
  isOpen: Atom<boolean>
  step?: Atom<PlanChooserStep>
  canTrial: boolean
  plans: Atom<PlanWithService[]>
  onClose: Function
}) => {
  return (
    <F.Fragment>
      {isOpen.view(_isOpen => {
        return (
          <Dialog
            dialogModeName="PlanChooserDialog"
            isShow={_isOpen}
            showCloseButton
            onClose={() => onClose()}
          >
            <PlanChooser
              hasPlans={plans.get().filter(x => !x.service).length > 0}
              onClose={onClose}
              canTrial={canTrial}
              step={step}
            ></PlanChooser>
          </Dialog>
        )
      })}
    </F.Fragment>
  )
}
