import React from 'react'
import * as style from './loader.styl'
import { classes, F, ReadOnlyAtom } from '@grammarly/focal'

export const Loader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...classes(style.loader, className)} {...props} />
)

export const TailSpinLoader = (props: React.SVGProps<SVGSVGElement>) => (
  <svg className={style.tailSpinLoader} {...props}>
    <defs>
      <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="prefix__a">
        <stop stopColor="#fff" stopOpacity={0} offset="0%" />
        <stop stopColor="#fff" stopOpacity={0.631} offset="63.146%" />
        <stop stopColor="#fff" offset="100%" />
      </linearGradient>
    </defs>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path d="M36 18c0-9.94-8.06-18-18-18" stroke="url(#prefix__a)" strokeWidth={2}>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 18 18"
          to="360 18 18"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </path>
      <circle fill="#fff" cx={36} cy={18} r={1}>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 18 18"
          to="360 18 18"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
)

export const OvalLoader = ({
  className,
  isDarkLoader,
  ...props
}: React.SVGProps<SVGSVGElement> & { isDarkLoader?: boolean }) => {
  const radius = 10
  const strokeWidth = 2
  return (
    <svg {...classes(style.ovalLoader, className, isDarkLoader && style.darkLoader)} {...props}>
      <g
        transform={`translate(${strokeWidth} ${strokeWidth})`}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity={0.5} cx={radius} cy={radius} r={radius} />
        <path d={`M${radius * 2} ${radius}c0-9.94-8.06-${radius}-${radius}-${radius}`}>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from={`0 ${radius} ${radius}`}
            to={`360 ${radius} ${radius}`}
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  )
}

export const AppLoader = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
  return <OvalLoader {...props} {...classes(style.appLoader, className)} />
}

export const ModalLoader = ({
  isVisible,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { isVisible: ReadOnlyAtom<boolean> }) => {
  return (
    <F.Fragment>
      {isVisible.view(
        x =>
          x && (
            <div {...props} className={style.modalLoader}>
              <OvalLoader />
            </div>
          )
      )}
    </F.Fragment>
  )
}
