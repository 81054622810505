import React, { useState, useEffect, useRef } from 'react'
import * as style from './menu.styl'
import { classes } from '@grammarly/focal'
import { fromEvent, of } from 'rxjs'
import { map, distinctUntilChanged, switchMap, delay } from 'rxjs/operators'
import { inContainer, getParentBySelector } from '../utils/dom'
import { Ripple } from './ripple'
import { Icons, SvgIcon } from './icons'
import { createPortal } from 'react-dom'
import { IconButton } from './buttons'
import { useTransition, animated } from 'react-spring'
import { OvalLoader } from './loader'
import { scrollElement } from '../utils/dom'

export interface PopupMenuProps<T extends HTMLDivElement> extends React.BaseHTMLAttributes<T> {
  anchor: HTMLElement | null
  anchorActiveClass?: string
  parentElement?: HTMLElement
  isOpen?: boolean
  isShowOnHover?: boolean
  isCloseOnClick?: boolean
  isAlignCenter?: boolean
  onAction?(action: string): Promise<void> | void
  onClose?(): void
}

export const PopupMenu = <T extends HTMLDivElement>({
  anchor,
  anchorActiveClass,
  parentElement,
  isOpen = false,
  onClose,
  className = '',
  children,
  isShowOnHover,
  onAction,
  isAlignCenter,
  isCloseOnClick = true,
  ...otherProps
}: PopupMenuProps<T>) => {
  const [visible, setVisible] = useState(isOpen)
  if (isOpen !== visible && !isShowOnHover) {
    setVisible(isOpen)
  }
  const el = useRef<HTMLDivElement | null>(null)
  const transitions = useTransition(visible, null, {
    config: {
      tension: 80,
      friction: 10,
      duration: 150
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const showHide = (x: boolean) => {
    if (anchor && anchorActiveClass) {
      anchor.classList[x ? 'add' : 'remove'](anchorActiveClass)
    }
    if (onClose) onClose()
    setVisible(x)
  }

  const onKey = (e: KeyboardEvent) => {
    if (!visible) return
    if (e.keyCode === 27) showHide(false)
  }

  useEffect(() => {
    const mouseSubscription =
      isShowOnHover &&
      fromEvent(document, 'mousemove')
        .pipe(
          map(e => {
            const target = e.target as HTMLElement
            return inContainer(anchor, target) || inContainer(el.current, target)
          }),
          distinctUntilChanged(),
          switchMap(x => (x ? of(x).pipe(delay(200)) : of(x).pipe(delay(400))))
        )
        .subscribe(showHide)

    const scrollSubscription = fromEvent(document, 'scroll', { capture: true })
      .pipe(map(() => false))
      .subscribe(showHide)

    document.addEventListener('keydown', onKey)

    return () => {
      if (mouseSubscription) mouseSubscription.unsubscribe()
      if (scrollSubscription) scrollSubscription.unsubscribe()
      document.removeEventListener('keydown', onKey)
    }
  })

  if (parentElement && anchor) {
    const rect = anchor.getBoundingClientRect()
    const scrollX = scrollElement().scrollLeft
    const scrollY = scrollElement().scrollTop
    otherProps.style = otherProps.style || {}
    otherProps.style.top = rect.bottom + scrollY + 'px'
    otherProps.style.left = rect.left + scrollX + 'px'
  }

  if (anchor && isAlignCenter) {
    otherProps.style = otherProps.style || {}
    const { width } = anchor.getBoundingClientRect()
    otherProps.style.marginLeft = `${width / 2}px`
    otherProps.style.transform = 'translateX(-50%)'
  }

  const menu = (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              ref={el}
              role="menu"
              {...{
                ...classes(style.popupMenu, className),
                ...otherProps
              }}
              key={key}
              style={{ ...props, ...(otherProps.style as any) }}
              onClick={e => {
                const _target = e.target as HTMLElement
                const target = _target.dataset.action
                  ? _target
                  : (getParentBySelector(_target, '[data-action]') as HTMLElement | undefined)
                if (!target || !target.dataset.action) return

                if (onAction) onAction(target.dataset.action)

                e.stopPropagation()
                if (isCloseOnClick) {
                  showHide(false)
                }
              }}
            >
              {children}
            </animated.div>
          )
      )}
    </>
  )

  return parentElement ? createPortal(menu, parentElement) : menu
}

export interface PopupMenuItemProps<T extends HTMLDivElement> extends React.BaseHTMLAttributes<T> {
  icon?: Icons
  action?: string
  loader?: boolean
}

export const PopupMenuItem = <T extends HTMLDivElement>({
  className = '',
  loader,
  children,
  icon,
  action,
  ...otherProps
}: PopupMenuItemProps<T>) => {
  return (
    <div
      role="menu-item"
      data-action={action}
      {...{
        ...classes(style.item, className),
        ...otherProps
      }}
    >
      <Ripple />
      {loader && (
        <div className={style.loader}>
          <OvalLoader />
        </div>
      )}
      {icon && <SvgIcon icon={icon} className={style.icon} />}
      {children}
    </div>
  )
}

export const EditorMenu = (props: {
  children: React.ReactNode
  menuClass?: string
  buttonClass?: string
  onAction?(action: string): Promise<void>
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null)

  return (
    <>
      <IconButton
        ref={setMenuAnchor}
        icon={Icons.more}
        {...classes(style.editButton, props.buttonClass)}
      />
      <PopupMenu
        className={props.menuClass}
        parentElement={document.body}
        isShowOnHover={true}
        anchor={menuAnchor}
        anchorActiveClass={style.activeEditButton}
        onAction={props.onAction}
      >
        {props.children}
      </PopupMenu>
    </>
  )
}
