import { classes } from '@grammarly/focal'
import React, { useRef, useEffect, useState } from 'react'
import * as deleteStyle from './delete.styl'
import { IconButton, Button } from './buttons'
import { Icons } from './icons'
import { useTransition, animated } from 'react-spring'

export const Delete = ({
  isShow,
  onClose,
  onUndo,
  timeout = 7000,
  children,
  label,
  style,
  trackingName,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  isShow: boolean
  trackingName?: string
  timeout?: number
  label?: string
  onUndo: Function
  onClose: Function
}) => {
  const el = useRef<HTMLDivElement | null>(null)
  const [progress, setProgress] = useState('0%')
  const [finished, setFinished] = useState(false)

  let timer: NodeJS.Timeout | undefined = undefined

  const progressEl = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // console.log('useEffect', isShow, finished, timer)
    if (isShow && !finished && !timer) {
      timer = setTimeout(() => {
        // console.log('Delete filter', isShow, finished)
        if (!finished) onClose()
      }, timeout)
      setTimeout(() => setProgress('100%'), 10)
    }

    // else {
    //   setProgress('0%')
    //   setFinished(false)
    // }
    if (finished && timer) {
      // console.log('clear timerout')
      clearTimeout(timer)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isShow, finished])

  const transitions = useTransition(isShow, null, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' }
  })

  const animatedDiv = transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          ref={el}
          style={{ ...props, ...(style as any) }}
          {...classes(deleteStyle.delete, finished && deleteStyle.finished, className)}
          {...other}
        >
          <span className={deleteStyle.label}>{label ? label : 'Deleted'}</span>
          <div className={deleteStyle.buttons}>
            <Button
              trackingName={`${trackingName}_deleteUndoBtn`}
              className={deleteStyle.btnUndo}
              onClick={e => {
                e.stopPropagation()
                setProgress('0%')
                setFinished(false)
                onUndo()
              }}
              icon={Icons.undo}
            >
              Undo
            </Button>
            <IconButton
              className={deleteStyle.btnClose}
              icon={Icons.close}
              trackingName={`${trackingName}_deleteConfirmBtn`}
              onClick={e => {
                e.stopPropagation()
                setFinished(true)
                onClose()
              }}
            />
          </div>
          {children}

          <div
            ref={progressEl}
            className={deleteStyle.progressBar}
            style={{ transitionDuration: `${timeout - 100}ms`, width: progress }}
          />
        </animated.div>
      )
  )

  return <>{animatedDiv}</>
}
