import mixpanel from 'mixpanel-browser'
import * as ga from '../components/ga'
import { trackingTokens } from '../config'

export function init() {
  mixpanel.init(trackingTokens.mixpanel)
}

export function setUser(userId: string) {
  // console.log('setUser', userId)
  mixpanel.identify(userId)
  ga.setUser(userId)
}

export function track(name: string, action = 'click', label?: string, value?: number) {
  // console.log('track', { name, action, label })
  try {
    mixpanel.track(name, { action, label, value })
    ga.send(name, action, label, value)
  } catch (e) {
    console.warn(e)
  }
}

export function login() {
  mixpanel.track('login')
  ga.login()
}

export function signUp(userId: string) {
  mixpanel.track('signup')
  mixpanel.alias(userId)
  ga.setUser(userId)
  ga.signUp()
}

export function signout() {
  track('signout')
}
