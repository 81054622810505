import { connect } from 'formik';
import uniq from 'lodash/uniq';
import React from 'react';
import { IconButton } from '../buttons';
import { Icons } from '../icons';
import { Input } from '../input';
import * as inputStyle from '../input.styl';
import { TooltipAnchor, tooltipState } from '../tooltip';
import * as style from './condition.styl';


export const KeywordsInput = connect<{ isExclude: boolean; }>(props => {
  const f = (x: string) => (props.isExclude ? x + 'Exclude' : x);
  const v = (x: string) => props.formik.values[f(x)];

  const isRegExp = Boolean(v('isRegExp'));
  const isCaseSensitive = !Boolean(v('isIgnoreCase'));
  const isWholeWord = Boolean(v('isWholeWord'));

  const transformSetValue = (_value: string, _isRegExp: boolean) => {
    const value = _isRegExp
      ? [_value]
      : uniq(
        _value
          .split(',')
          .map(x => x.trim())
          .filter(Boolean)
      );
    props.formik.setFieldValue(f('values'), value.length > 0 ? value : []);
  };

  return (
    <Input
      maxLength={500}
      autoFocus
      containerClass={inputStyle.inputMiddle + ' ' + style.textarea}
      name={f('textValue')}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.formik.setFieldValue(f('textValue'), e.target.value);
        transformSetValue(e.target.value, isRegExp);
      }}
      label={isRegExp
        ? props.isExclude
          ? 'Skip messages that match RegExp'
          : 'Message matches regular expression'
        : props.isExclude
          ? 'Skip messages with keywords (comma separated)'
          : 'Message has one of keywords (comma separated)'}
      helper={isRegExp ? (
        'regular expression, example matches email: [w-]+@([w-]+.)+[w-]+s*'
      ) : (
          <span>
            {props.isExclude ? null : <b>Leave empty to match any message.</b>} Example: brand, bad
            service, issue.{' '}
          </span>
        )}
      isTextarea
      button={<div className={style.inputFlags}>
        <TooltipAnchor tooltip={tooltipState} content={'Match Case'} align="top">
          <IconButton
            isToggleMode
            isToggleStyleLight
            isOn={isCaseSensitive}
            icon={Icons.caseSensitive}
            onToggle={value => {
              props.formik.setFieldValue(f('isIgnoreCase'), !value);
            }} />
        </TooltipAnchor>
        <TooltipAnchor tooltip={tooltipState} content={'Match Whole Word'} align="top">
          <IconButton
            isToggleMode
            isToggleStyleLight
            isOn={isWholeWord}
            icon={Icons.wholeWord}
            onToggle={value => {
              props.formik.setFieldValue(f('isWholeWord'), value);
            }} />
        </TooltipAnchor>
        <TooltipAnchor tooltip={tooltipState} content={'Use Regular Expression'} align="top">
          <IconButton
            isToggleMode
            isToggleStyleLight
            isOn={isRegExp}
            onToggle={value => {
              const textValue = v('textValue');
              props.formik.setFieldValue(f('isRegExp'), value);
              transformSetValue(textValue, value);
            }}
            icon={Icons.regexp} />
        </TooltipAnchor>
      </div>} />
  );
});
