import { classes, F } from '@grammarly/focal'
import React from 'react'
import { from, Observable, of } from 'rxjs'
import { concatMap, delay, map, scan, switchMap } from 'rxjs/operators'

import * as style from './animated.styl'

interface AnimatedProps extends React.HTMLAttributes<HTMLDivElement> {
  children: Observable<React.ReactNode>
  prevClassName?: string
  nextClassName?: string
  isNotAdjustWidth?: boolean
  keepWidthBetweenStates?: boolean
  keepHeightBetweenStates?: boolean
  transitionType?: 'slideDown' | 'slideUp' | 'appear'
}

const states = ['init', 'start', 'end', 'cleanup']
const delays = {
  init: 50,
  start: 50,
  end: 50,
  cleanup: 300
}

export const Animated = ({
  className,
  transitionType = 'slideDown',
  isNotAdjustWidth,
  prevClassName,
  nextClassName,
  keepWidthBetweenStates,
  keepHeightBetweenStates,
  children,
  ...props
}: AnimatedProps) => {
  const source = children.pipe(
    scan<React.ReactNode, { prev: React.ReactNode | undefined; next: React.ReactNode | undefined }>(
      (prev, next) => ({ prev: (prev.next || next)!, next: next! }),
      { prev: undefined, next: undefined }
    ),
    switchMap(components =>
      from(
        states.map(state => ({
          state,
          components
        }))
      ).pipe(
        concatMap(x => {
          // console.log(x.components.prev === x.components.next, x.components.prev, x.components.next)
          if (x.components.prev === x.components.next) return of(x)
          return of(x).pipe(delay((window as any).delays || delays[x.state]))
        })
      )
    )
  )

  const nextRef = React.useRef<HTMLDivElement | null>(null)
  const prevRef = React.useRef<HTMLDivElement | null>(null)
  const elRef = React.useRef<HTMLDivElement | null>(null)
  let size: { width?: string; height?: string } = {}

  return (
    <F.Fragment>
      {source.pipe(
        map(({ state, components }) => {
          if (state === 'start' && prevRef.current) {
            size = {
              width: prevRef.current.clientWidth + 'px',
              height: prevRef.current.clientHeight + 'px'
            }
            // console.log('Start size', size)
          }

          if (state === 'end' && nextRef.current) {
            size = {
              width: keepWidthBetweenStates ? size.width : nextRef.current.clientWidth + 'px',
              height: keepHeightBetweenStates ? size.height : nextRef.current.clientHeight + 'px'
            }
            // console.log('end size', keepWidthBetweenStates, size, nextRef.current.clientWidth)
          }

          if (state === 'cleanup' || isNotAdjustWidth) {
            size = {}
          }

          const nextDiv = (
            <div
              ref={nextRef}
              {...classes(components.prev !== components.next && style.next, nextClassName)}
            >
              {components.next}
            </div>
          )
          const prevDiv = (
            <div ref={prevRef} {...classes(style.prev, prevClassName)}>
              {components.prev}
            </div>
          )
          // console.log(

          //   state,
          //   components.prev === components.next || state === 'cleanup',
          //   components.prev,
          //   components.next
          // )
          return components.prev === components.next || state === 'cleanup' ? (
            components.next
          ) : (
            <div
              ref={elRef}
              {...classes(style.animated, style[transitionType], style[state], className)}
              {...props}
              style={size}
            >
              {transitionType === 'slideDown' || transitionType === 'appear' ? (
                <>
                  {nextDiv}
                  {prevDiv}
                </>
              ) : (
                <>
                  {prevDiv}
                  {nextDiv}
                </>
              )}
            </div>
          )
        })
      )}
    </F.Fragment>
  )
}

interface AnimatedExpandProps extends Omit<AnimatedProps, 'children'> {
  children: React.ReactNode
  isExpanded: Observable<boolean>
}

export const AnimatedExpand = ({ children, isExpanded, ...props }: AnimatedExpandProps) => {
  props = {
    ...props,
    prevClassName: style.expand100,
    nextClassName: style.expand100
  }

  return (
    <Animated {...props} transitionType="appear" keepWidthBetweenStates>
      {isExpanded.pipe(map(x => (x ? children : <div className={style.empty} />)))}
    </Animated>

    // <F.div {...props}>
    //   {isExpanded.pipe(map(x => (x ? children : <div className={style.empty} />)))}
    // </F.div>
  )
}
