import { Atom, classes, F } from '@grammarly/focal'
import { connect } from 'formik'
import React from 'react'
import { Button } from '../buttons'
import * as style from './mediaForm.styl'
import { ExpandPanel } from './expandLink'
import * as linkStyle from './expandLink.styl'
import { Input } from '../input'

export const ShortMedia = ({ values }: { values?: string[] }) => {
  return (
    <>
      {values && values.length > 0
        ? values.map(x => (
            <span key={x}>
              <span className={style.tag}>{x}</span>{' '}
            </span>
          ))
        : ' (no media selected)'}
    </>
  )
}

export const MediaForm = connect<
  {
    expandLink: React.ReactNode
    tooltip: string
    className?: string
    expandLinkWrapClassName?: string
    buttonsClassName?: string
    isExpanded?: Atom<boolean>
    isShowSelectedItems?: boolean
    possibleValues: Record<string, string>
  },
  { mediaSelectors?: string[] }
>(props => {
  const xs = Object.keys(props.possibleValues)
  const values = props.formik.values.mediaSelectors || []

  const expandLink = props.isShowSelectedItems ? (
    <>
      <span {...classes(linkStyle.pseudoLink, style.tagsTitle)}>{props.expandLink}</span>
      <F.Fragment>
        {props.isExpanded?.view(isExpanded =>
          !isExpanded ? (
            <span key={'_' + isExpanded} className={style.appear}>
              :
              <ShortMedia values={values} />
            </span>
          ) : null
        )}
      </F.Fragment>
    </>
  ) : (
    props.expandLink
  )

  return (
    <div {...classes(style.mediaForm, props.className)}>
      <ExpandPanel
        isExpanded={props.isExpanded}
        expandLinkChildren={expandLink}
        tooltip={props.tooltip}
        expandLinkWrapClassName={classes(style.mediaLabel, props.expandLinkWrapClassName).className}
        expandPseudoLinkClassName={props.isShowSelectedItems && style.noBorder}
        expandIconClassName={props.isShowSelectedItems && style.expandIcon}
        expandIconLeft
      >
        <div {...classes(style.buttons, props.buttonsClassName)}>
          {xs.map(x => (
            <Button
              key={x}
              name={x}
              isOn={Boolean(values.find(y => x === y))}
              onToggle={value => {
                const result = value
                  ? [...values, props.possibleValues[x]]
                  : values.filter(y => y !== x)
                props.formik.setFieldValue('mediaSelectors', result)
              }}
              isToggleMode
            >
              {x}
            </Button>
          ))}
          <div>
            <Input
              type="checkbox"
              name="isPin"
              label="Include only pinned messages"
              containerClass={style.chatsPadding}
            />
          </div>
        </div>
      </ExpandPanel>
    </div>
  )
})
