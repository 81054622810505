import React from 'react'
import { classes } from '@grammarly/focal'
import * as style from './emptyList.styl'
import { Icons, DialogIcon } from './icons'

export const EmptyList = ({
  children,
  label,
  icon,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  icon: Icons
  label: string | React.ReactNode
}) => (
  <div {...classes(style.emptyList, className)} {...other}>
    <DialogIcon icon={icon} className={style.emptyIcon} />
    <span className={style.emptyLabel}>{label}</span>
    {children}
  </div>
)
