export interface Command {
  undo(): void
  redo(): void
}

export interface UndoState {
  commands: Command[]
  index: number
  limit: number
}

export function getDefaultUndoState(): UndoState {
  return {
    commands: [],
    index: -1,
    limit: 5
  }
}

export function redo(state: UndoState): UndoState {
  if (state.index < state.commands.length - 1) {
    state.index++
    state.commands[state.index].redo()
  }
  // console.log('redo', state)
  return state
}

export function undo(state: UndoState): UndoState {
  if (state.index >= 0) {
    state.commands[state.index].undo()
    state.index--
  }
  // console.log('undo', state)
  return state
}

export function add(command: Command, { index, commands, limit }: UndoState): UndoState {
  commands = commands.slice(0, index + 1)
  commands.push(command)
  if (limit > 0 && commands.length > limit) {
    commands.shift()
  } else {
    index++
  }
  // console.log('add', { index, commands, limit })
  return { index, commands, limit }
}
