import { Atom, classes, F, ReadOnlyAtom } from '@grammarly/focal'
import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { animated, useChain, useSpring, useTransition } from 'react-spring'
import { Observable } from 'rxjs'

import { scrollElement } from '../utils/dom'
import { track } from '../utils/tracking'
import { Animated } from './animated'
import { IconButton } from './buttons'
import * as style from './dialog.styl'
import { Icons } from './icons'
import { Notification } from './notification'

function getValue<T>(x: T | ReadOnlyAtom<T>) {
  return x instanceof Observable ? (x as ReadOnlyAtom<T>).get() : x
}

const dialogState: Record<string, boolean> = {}

export const Dialog = ({
  isShow,
  isModal,
  isFullScreen,
  noOverlay,
  showCloseButton,
  className,
  onClose,
  children,
  outsideComponent,
  top,
  dialogModeName
}: {
  isShow: boolean
  isFullScreen?: boolean
  isModal?: boolean | ReadOnlyAtom<boolean>
  noOverlay?: boolean
  showCloseButton?: boolean
  className?: string
  onClose: Function
  children: React.ReactNode | Observable<React.ReactNode>
  outsideComponent?: React.ReactNode
  top?: number | ReadOnlyAtom<number>
  dialogModeName?: string | ReadOnlyAtom<string>
}) => {
  let popupEl: HTMLDivElement | undefined

  const onKey = (e: KeyboardEvent) => {
    if (!isShow) return
    if (e.keyCode === 27) onClose()
  }

  function getMarginTop() {
    if (isFullScreen) return 0
    return document.body.clientWidth < 600 ? 0 : getValue(top) || 5
  }

  const root = useRef<HTMLDivElement | null>(null)
  const onResize = () => {
    if (isFullScreen) return
    if (!popupEl || !popupEl.parentNode || !root.current) return
    const height = (popupEl as HTMLDivElement).offsetHeight
    root.current.style.height = height + height * 0.1 + 55 + 'px'

    if ([`${top}%`, '0%', '5%'].includes(popupEl.style.marginTop || '')) {
      popupEl.style.marginTop = `${getMarginTop()}%`
    }
  }
  const ResizeObserver = (window as any).ResizeObserver
  const resizeObserver = ResizeObserver && new ResizeObserver(onResize)

  const popupSizeRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (isShow) {
      scrollElement().scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      document.addEventListener('keydown', onKey)
      window.addEventListener('resize', onResize)
      // console.log('mount dialog')
      document.documentElement.classList.add(style.dialogMode)
      document.documentElement.dataset.dialog = getValue(dialogModeName)

      if (getValue(dialogModeName) && !dialogState[getValue(dialogModeName) || '']) {
        dialogState[getValue(dialogModeName) || ''] = true
        track(`dialog_${getValue(dialogModeName)}`, 'open')
      }

      onResize()
    } else {
      // console.log('unmount dialog')
      document.documentElement.classList.remove(style.dialogMode)
      delete document.documentElement.dataset.dialog
      document.removeEventListener('keydown', onKey)
      window.removeEventListener('resize', onResize)
      if (resizeObserver && popupEl) {
        resizeObserver.unobserve(popupEl)
      }
      if (getValue(dialogModeName) && dialogState[getValue(dialogModeName) || '']) {
        track(`dialog_${getValue(dialogModeName)}`, 'close')
        dialogState[getValue(dialogModeName) || ''] = false
      }
    }
  }, [isShow])

  const outerRef = useRef(null)
  const transitions = useTransition(isShow, null, {
    ref: outerRef,

    from: { backgroundColor: 'rgba(100,107,131,0)' },
    enter: { backgroundColor: 'rgba(100,107,131,.4)' },
    leave: { backgroundColor: 'rgba(100,107,131,0)' }
  })

  const innerRef = useRef(null)

  // console.log('get element height', popupEl, popupRef.current)
  const props = useSpring({
    [isShow ? 'from' : 'to']: { marginTop: `-5%`, transform: 'translateY(-100%)' },
    [isShow ? 'to' : 'from']: { marginTop: `${getMarginTop()}%`, transform: 'translateY(0%)' },
    ref: innerRef,
    onStart: () => {
      // console.log('onStart', popupEl, popupRef.current)
      if (popupEl) popupEl.dataset.animationStart = 'true'
    },
    onRest: () => {
      // console.log('onRest', popupEl, popupRef.current)
      if (popupEl) popupEl.dataset.animationStart = 'false'
    }
  })

  const innerDialog = (
    <animated.div
      style={props}
      ref={_el => {
        if (_el) {
          popupEl = _el
          if (resizeObserver) {
            resizeObserver.observe(popupEl)
          }
        }
      }}
      className={style.popup}
    >
      <div ref={popupRef} {...classes(style.popupWrap, isFullScreen && style.fullScreen)}>
        <div ref={popupSizeRef}>
          {(isModal || showCloseButton) && (
            <IconButton className={style.btnClose} onClick={() => onClose()} icon={Icons.close} />
          )}
          {children instanceof Observable ? (
            <Animated
              transitionType={'appear'}
              children={children as Observable<React.ReactNode>}
            />
          ) : (
            children
          )}
        </div>
      </div>
      {outsideComponent}
    </animated.div>
  )

  useChain(isShow ? [outerRef, innerRef] : [innerRef, outerRef], [0, 0.01])

  return (
    <>
      {createPortal(
        transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                ref={root}
                key={key}
                style={props}
                role="dialog"
                {...classes(style.dialog, noOverlay && style.noOverlay, className)}
                onClick={e => {
                  if ((e.target as HTMLElement).classList.contains(style.dialog) && !isModal)
                    onClose()
                }}
              >
                {innerDialog}
              </animated.div>
            )
        ),
        document.getElementById('dialogContainer') || document.body
      )}
    </>
  )
}

export const DialogError = ({
  isError,
  className,
  isAppear
}: {
  isError: Atom<{ message: string; isShow: boolean }>
  className?: string
  isAppear?: boolean
}) => {
  return (
    <F.Fragment>
      {isError.view(x => (
        <Notification
          isAppear={isAppear}
          className={className}
          big
          onClose={() => {
            if (isError) isError.modify(x => ({ ...(x || { message: '' }), isShow: false }))
          }}
          isShow={Boolean(x && x.isShow)}
        >
          {x ? x.message : ''}
        </Notification>
      ))}
    </F.Fragment>
  )
}

export const DialogContent = ({
  children,
  className,
  isError,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  isError?: Atom<{ message: string; isShow: boolean }>
}) => (
  <div {...classes(style.content, className)} {...other}>
    {isError && <DialogError isError={isError} />}
    {children}
  </div>
)

export const DialogForm = ({
  children,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...classes(style.padding, className)} {...other}>
    {children}
  </div>
)

export const DialogHeader = ({
  children,
  className,
  ...other
}: React.HTMLAttributes<HTMLHeadElement>) => (
  <h1 {...classes(style.header, style.padding, className)} {...other}>
    {children}
  </h1>
)

export const DialogButtons = ({
  children,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...classes(style.buttons, className)} {...other}>
    {children}
  </div>
)
