import React, { useState } from 'react'

import { classes, Atom, F } from '@grammarly/focal'
import { Input } from '../input'

import { Formik, connect } from 'formik'

import * as style from './auth.styl'

import { IconButton, MainButton } from '../buttons'

import { DialogHeader, DialogButtons, DialogForm, DialogError, Dialog } from '../dialog'
import { useSubmit, ErrorState } from './utils'
import { Link } from '../link'
import { Icons } from '../icons'
import { CognitoUser } from '@aws-amplify/auth'
import page from 'page'
import { Animated } from '../animated'
import { Logo } from '../logo'
import { track, login as trackLogin, signUp as trackSignup } from '../../utils/tracking'

export enum Step {
  Signin = 'signin',
  Signin2FA = 'signin2fa',
  Signup = 'signup',
  Confirm = 'confirm',
  Reset = 'reset',
  ResetNew = 'reset-new'
}

export const titles = {
  [Step.Signin]: 'Sign in to your account',
  [Step.Signin2FA]: 'Confirm Sign in',
  [Step.Signup]: 'Create a new account',
  [Step.Confirm]: 'Confirm email',
  [Step.Reset]: 'Reset your password',
  [Step.ResetNew]: 'Set new password'
}

export const buttonTitles = {
  [Step.Signin]: 'Sign in',
  [Step.Signin2FA]: 'Confirm',
  [Step.Signup]: 'Create account',
  [Step.Confirm]: 'Confirm',
  [Step.Reset]: 'Send code',
  [Step.ResetNew]: 'Submit'
}

const backToSignin = (onNavigation: (toStep: Step) => void) => (
  <Link
    trackingName="backToSigninLink"
    onNavigation={() => onNavigation(Step.Signin)}
    href="/signin"
  >
    Back to Sign In
  </Link>
)

export const navigation = {
  [Step.Signin]: (onNavigation: (toStep: Step) => void) => (
    <>
      No account?{' '}
      <Link
        trackingName="createAccountLink"
        onNavigation={() => onNavigation(Step.Signup)}
        href="/signup"
      >
        Create account
      </Link>
    </>
  ),
  [Step.Signup]: (onNavigation: (toStep: Step) => void) => (
    <>
      Have an account?{' '}
      <Link
        trackingName="signinLink"
        onNavigation={() => {
          // console.log('hehehe', Step.Signin)
          onNavigation(Step.Signin)
        }}
        href="/signin"
      >
        Sign in
      </Link>
    </>
  ),
  [Step.Confirm]: backToSignin,
  [Step.Reset]: backToSignin,
  [Step.ResetNew]: backToSignin,
  [Step.Signin2FA]: backToSignin
}

export interface FormValues {
  username: string
  password: string
  code: string
  step: Step
}

export interface FormResult extends FormValues {
  user?: CognitoUser
}

export const initialValues: FormValues = {
  username: '',
  password: '',
  code: '',
  step: Step.Signin
}

export interface AuthValidation {
  item?: CognitoUser
  username?: string
  password?: string
  code?: string
  step?: Step
  message?: string
  isValid: boolean
}

export interface AuthFormProps {
  onFinish(user: CognitoUser): void
  onSubmit(x: FormResult): Promise<AuthValidation>
  onResendConfirmation(username: string): Promise<void>
  step: Atom<Step>
}

export const AuthForm = ({ onFinish, onSubmit, onResendConfirmation, step }: AuthFormProps) => {
  let submit: Function
  useSubmit(() => submit && submit())
  let user: CognitoUser | undefined = undefined

  const error = new ErrorState()

  const onNavigation = (toStep: Step) => {
    // console.log('go to step', step, toStep)
    step.set(toStep)
  }

  const buttonTitle = step.view(x => buttonTitles[x])

  const navigationComponent = step.view(x => (
    <span className={style.navWrap}>{navigation[x](onNavigation)}</span>
  ))
  const headerTitle = step.view(x => titles[x])

  return (
    <div className={style.authForm}>
      <DialogError isError={error.state} />
      <DialogForm>
        <DialogHeader className={style.header}>
          <Logo className={style.logo} />
          <Animated children={headerTitle} />
        </DialogHeader>
      </DialogForm>
      <Formik
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          values.step = step.get()
          track('authForm_' + values.step, 'submit')
          const result = await onSubmit({ ...values, user })
          // console.log('onsubmit', result)
          user = result.item
          if (result.isValid && onFinish) {
            track('authFormSuccess', 'success')
            if (values.step === Step.Signin2FA || values.step === Step.Signin) {
              trackLogin()
            }
            if (values.step === Step.Confirm) {
              trackSignup(result.item!.getUsername())
            }

            onFinish(result.item!)
          } else {
            if (result.step) {
              step.set(result.step)
            } else {
              track('authFormError_' + values.step, 'error', result.message)
              setErrors(result)
              error.show(result.message)
            }
          }

          setSubmitting(false)
          return result
        }}
        initialValues={initialValues}
        validate={() => {
          error.hide()
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          submit = handleSubmit
          return (
            <form>
              <DialogForm className={style.formContent}>
                <F.div className={style.forms}>
                  {step.view(
                    currentStep =>
                      ({
                        [Step.Signin]: (
                          <Login
                            isShowCode={false}
                            onBack={() => onNavigation(Step.Signin)}
                            onReset={() => {
                              onNavigation(Step.Reset)
                            }}
                          />
                        ),
                        [Step.Signin2FA]: (
                          <Login
                            isShowCode={true}
                            onBack={() => onNavigation(Step.Signin)}
                            onReset={() => {
                              //
                            }}
                          />
                        ),
                        [Step.Reset]: (
                          <Login
                            isShowCode={false}
                            isReset={true}
                            onBack={() => onNavigation(Step.Signin)}
                            onReset={() => {
                              //
                            }}
                          />
                        ),
                        [Step.Signup]: <Registration isShowCode={false} />,
                        [Step.Confirm]: (
                          <Registration
                            onBack={() => onNavigation(Step.Signup)}
                            onResend={() => onResendConfirmation(values.username)}
                            isShowCode={true}
                          />
                        ),

                        [Step.ResetNew]: (
                          <ResetNew
                            onResend={() => {
                              onSubmit({
                                username: values.username,
                                step: Step.Reset,
                                password: '',
                                code: ''
                              })
                            }}
                          />
                        )
                      }[currentStep])
                  )}
                </F.div>
              </DialogForm>
              <DialogButtons className={style.buttons}>
                <div className={style.navigation}>
                  <Animated
                    className={style.navigationAnimation}
                    transitionType={'slideDown'}
                    children={navigationComponent}
                  />
                </div>
                <MainButton
                  isLoading={isSubmitting}
                  type="submit"
                  onClick={e => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                  {...classes(style.saveBtn)}
                >
                  <Animated transitionType={'appear'} children={buttonTitle} />
                </MainButton>
              </DialogButtons>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export const Login = connect<
  { onReset: Function; onBack?: Function; isShowCode: boolean; isReset?: boolean },
  { username: string; password: string }
>(({ onReset, isReset, ...props }) => {
  const codeInput = (
    <Input
      name="code"
      label="Code from 2FA device"
      helper="Temporary code from two-step verification application"
      helperClass={style.helper}
      disabled={!props.isShowCode}
      tabIndex={!props.isShowCode ? -1 : undefined}
    />
  )

  return (
    <LoginPasswordCode
      {...props}
      {...classes(isReset && style.shrinkHeight, style.loginForm)}
      isLogin
      onReset={onReset}
      codeInput={codeInput}
    />
  )
})

export const Registration = connect<
  { isShowCode: boolean; onBack?: Function; onResend?: Function },
  { username: string; password: string; code: string }
>(({ onResend, ...props }) => {
  const input = (
    <Input
      name="code"
      label="Code from email"
      helperClass={style.helper}
      tabIndex={!props.isShowCode ? -1 : undefined}
      disabled={!props.isShowCode}
      helper={
        <span>
          Don't have your code?{' '}
          <Resend
            trackingName="resendRegistrationCodeLink"
            onResend={() => {
              if (onResend) onResend()
            }}
          />
        </span>
      }
    />
  )
  return <LoginPasswordCode {...props} codeInput={input} />
})

export const LoginPasswordCode = connect<
  {
    isShowCode: boolean
    isLogin?: boolean
    onBack?: Function
    codeInput: React.ReactChild
    onReset?: Function
    className?: string
  },
  { username: string; password: string; code: string }
>(props => {
  return (
    <div {...classes(props.isShowCode && style.confirm, props.className)}>
      <Input
        disabled={props.isShowCode}
        maxLength={150}
        type="email"
        autoFocus
        name="username"
        label="Email"
        button={
          <IconButton
            className={style.btnEdit}
            trackingName="editEmailBtn"
            tabIndex={-1}
            icon={Icons.edit}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (props.onBack) props.onBack()
            }}
          />
        }
      />
      <div className={style.codePassword}>
        <div className={style.codePasswordWrap}>
          <Input
            name="password"
            label="Password"
            type="password"
            tabIndex={props.isShowCode ? -1 : undefined}
            containerClass={style.passwordWrap}
            disabled={props.isShowCode}
            helperClass={style.helper}
            helper={
              props.isLogin ? (
                <span>
                  Forget your password?{' '}
                  <Link
                    trackingName="resetLink"
                    href="/reset"
                    tabIndex={-1}
                    onNavigation={() => {
                      if (props.onReset) props.onReset()
                    }}
                  >
                    Reset password
                  </Link>
                </span>
              ) : (
                'Minimal password length is 8'
              )
            }
          />

          <div className={style.code}>{props.codeInput}</div>
        </div>
      </div>
    </div>
  )
})

export const ResetNew = connect<{ onResend?: Function }, { code: string; password: string }>(
  props => {
    return (
      <>
        <div className={style.growAppear}>
          <Input
            name="code"
            helperClass={style.helper}
            helper={
              <span>
                Don't have your code?{' '}
                <Resend
                  trackingName="resendPasswordCodeLink"
                  onResend={() => {
                    if (props.onResend) props.onResend()
                  }}
                />
              </span>
            }
            label="Code from email"
          />
        </div>
        <Input
          name="password"
          label="New Password"
          helper="Minimal password length is 8"
          helperClass={style.helper}
          type="password"
        />
      </>
    )
  }
)

const Resend = ({ onResend, trackingName }: { onResend: Function; trackingName: string }) => {
  const [isSent, setSent] = useState<NodeJS.Timer | undefined>(undefined)

  React.useEffect(() => {
    return () => {
      // console.log('clear timeout', isSent)
      if (isSent) clearTimeout(isSent)
    }
  }, [isSent])

  return (
    <span {...classes(style.resendContainer, isSent && style.isSent)}>
      <Link
        trackingName={trackingName}
        onNavigation={() => {
          if (onResend) onResend()
          setSent(setTimeout(() => setSent(undefined), 5000))
        }}
        href="/resendCode"
      >
        Resend Code
      </Link>
    </span>
  )
}

const authRoutes = [Step.Signin, Step.Signup, Step.Reset]

export const AuthDialog = ({ onFinish, step, ...props }: AuthFormProps) => {
  const isOpen = Atom.create(true)
  const initialStep = authRoutes.find(x => document.location.pathname.startsWith('/' + x))

  step.set(initialStep || Step.Signin)

  React.useEffect(() => {
    const s = step.subscribe(x => {
      // console.log('step', x)
      if ([Step.Signin, Step.Signup, Step.Reset].includes(x)) {
        page('/' + x)
      }
    })
    return () => {
      s.unsubscribe()
    }
  })

  return (
    <F.Fragment>
      {isOpen.view(x => {
        return (
          <Dialog
            key="authDialog"
            isShow={x}
            isModal={false}
            onClose={() => {
              //
            }}
            noOverlay
          >
            <AuthForm
              onFinish={user => {
                isOpen.set(false)
                page('/')
                setTimeout(() => onFinish(user), 300)
              }}
              step={step}
              {...props}
            />
          </Dialog>
        )
      })}
    </F.Fragment>
  )
}
