import { MediaSelector } from './../types'
import { Contact, SearchExpression } from '../types'
import { ellipsisInTheMiddle } from '../utils/string'

export enum PartType {
  text = 'text',
  boldText = 'boldText',
  user = 'user',
  users = 'users',
  exceptUsers = 'exceptUsers',
  chat = 'chat',
  chats = 'chats',
  exceptChats = 'exceptChats',
  values = 'values',
  regExp = 'regExp',
  grouped = 'grouped',
  chatNotify = 'chatNotify'
}
export interface Part {
  text?: string
  id?: string
  items?: Part[]
  title?: string
  type: PartType
}
export function transformArray(xs: string[], type: PartType): Part[] {
  return xs.map(x => ({
    text: x,
    type
  }))
}

function getName(x: Contact) {
  const providedName = ((x.firstName || '') + ' ' + (x.lastName || '')).trim()
  return providedName || x.username || x.id
}

// function joinWithOr(xs: string[]) {
//   if (xs.length < 2) return xs.join(', ')
//   const last = xs[xs.length - 1]
//   return xs.slice(0, xs.length - 1).join(', ') + ' or ' + last
// }

export function transformMembers(xs: Contact[], type: PartType): Part[] {
  return xs.map(x => ({
    text: getName(x),
    id: x.id,
    type
  }))
}

export function except(xs: string[], type: PartType) {
  return [
    {
      text: 'except (',
      type: PartType.text
    },
    ...transformArray(xs, type),
    {
      text: ') ',
      type: PartType.text
    }
  ]
}

function space(result: Part[]) {
  result.push({
    text: ' ',
    type: PartType.text
  })
}

function keywords(
  values: string[],
  isRegExp: boolean | undefined,
  isIgnoreCase: boolean | undefined,
  result: Part[]
) {
  if (isRegExp) {
    result.push({
      text: values[0],
      id: 'keywords',
      type: PartType.regExp
    })
  } else {
    let value = values.join(', ')
    value = isIgnoreCase ? value.toLowerCase() : value
    result.push({
      text: ellipsisInTheMiddle(value),
      title: value,
      id: 'keywords',
      type: PartType.boldText
    })
  }
}

function excludeKeywords(x: SearchExpression, result: Part[]) {
  if (x.valuesExclude !== undefined && x.valuesExclude.length > 0) {
    result.push({
      text: ' excluding ',
      type: PartType.text
    })
    keywords(x.valuesExclude, x.isRegExpExclude, x.isIgnoreCaseExclude, result)
  }
}

export function transform(x: SearchExpression): Part[] {
  const result: Part[] = [
    {
      text: 'When ',
      type: PartType.text
    }
  ]
  if (!x.users || x.users.length === 0) {
    result.push({
      text: 'any user',
      id: 'users',
      type: PartType.boldText
    })
    space(result)
  } else {
    result.push({
      text: `${x.users.length === 1 ? 'user' : 'users'}`,
      id: 'users',
      type: PartType.boldText
    })

    space(result)

    result.push({
      type: PartType.users,
      id: 'users',
      items: transformMembers(x.users, PartType.user)
    })
  }
  if (x.ignoreUsers && x.ignoreUsers.length) {
    result.push({
      type: PartType.exceptUsers,
      id: 'users',
      items: transformMembers(x.ignoreUsers, PartType.user)
    })
  }

  if (x.isIncludeMy) {
    result.push({
      text: ` (including me)`,
      id: 'users',
      type: PartType.boldText
    })
    space(result)
  }

  result.push({
    text: 'in ',
    type: PartType.text
  })
  if (!x.chats || x.chats.length === 0) {
    result.push({
      text: 'any common chat',
      id: 'chats',
      type: PartType.boldText
    })

    space(result)

    if (x.isIncludePrivateChats || x.isIncludeBots) {
      result.push({
        text: ` (including${x.isIncludePrivateChats ? ' private chats' : ''}${
          x.isIncludeBots ? `${x.isIncludePrivateChats ? ' and' : ''} bots` : ''
        })`,
        id: 'chats',
        type: PartType.boldText
      })
      space(result)
    }
  } else {
    result.push({
      text: `${x.chats.length === 1 ? 'chat' : 'chats'}`,
      id: 'chats',
      type: PartType.boldText
    })
    space(result)

    result.push({
      type: PartType.chats,
      id: 'chats',
      items: transformMembers(x.chats, PartType.chat)
    })
  }
  if (x.ignoreChats && x.ignoreChats.length) {
    result.push({
      type: PartType.exceptChats,
      id: 'chats',
      items: transformMembers(x.ignoreChats, PartType.chat)
    })
  }
  if (x.values !== undefined && x.values.length > 0) {
    result.push({
      text: x.users && x.users.length > 1 ? 'mention ' : 'mentions ',
      type: PartType.text
    })

    keywords(x.values, x.isRegExp, x.isIgnoreCase, result)

    // result.push({
    //   type: PartType.values,
    //   items: x.values.map(v => ({
    //     text: x.isIgnoreCase && !x.isRegExp ? v.toLowerCase() : v,
    //     type: x.isRegExp ? PartType.regExp : PartType.boldText
    //   }))
    // })

    excludeKeywords(x, result)

    if (x.mediaSelectors && x.mediaSelectors.length > 0) {
      result.push({
        text: ' with ',
        type: PartType.text
      })

      transformMedia(x, result)
    }
  } else if (x.isWildcard) {
    result.push({
      text: x.users && x.users.length > 1 ? 'post ' : 'posts ',
      type: PartType.text
    })
    result.push({
      text: 'anything',
      id: 'keywords',
      type: PartType.boldText
    })
  } else if (x.mediaSelectors && x.mediaSelectors.length > 0) {
    result.push({
      text: x.users && x.users.length > 1 ? 'post ' : 'posts ',
      type: PartType.text
    })

    transformMedia(x, result)
  } else {
    result.push({
      text: x.users && x.users.length > 1 ? 'post ' : 'posts ',
      type: PartType.text
    })
    result.push({
      text: 'anything',
      id: 'keywords',
      type: PartType.boldText
    })

    excludeKeywords(x, result)
  }

  if (x.isPin) {
    result.push({
      text: ' (only pinned)',
      id: 'keywords',
      type: PartType.boldText
    })
  }

  return result
}
function transformMedia(x: SearchExpression, result: Part[]) {
  if (!x.mediaSelectors || x.mediaSelectors.length === 0) return
  if (x.mediaSelectors.length === Object.keys(MediaSelector).length) {
    result.push({
      type: PartType.boldText,
      id: 'keywords',
      text: 'any media'
    })
  } else {
    result.push({
      type: PartType.boldText,
      id: 'keywords',
      text: x.mediaSelectors.join(', ').toLowerCase()
    })
  }
}
