import { Atom, classes, F, Lens } from '@grammarly/focal'
import React from 'react'
import { Observable, of } from 'rxjs'
import { map, scan, switchMap } from 'rxjs/operators'

import * as style from './animatedList.styl'

interface KeyToRemove {
  id: string
  isRemoved: boolean
}

interface ListUpdate<T extends { id: string }> {
  prev: T[]
  next: T[]
  keysToRemove: Atom<KeyToRemove[]>
}

export const FoldableList = <T extends { id: string }>({
  items,
  onItem,
  className,
  emptyClassName,
  empty,
  isAnimateOnlyOne = true,
  category,
  children
}: {
  items: Observable<T[]>
  className?: string
  emptyClassName?: string
  empty?: React.ReactNode
  category?: Observable<string>
  isAnimateOnlyOne?: boolean
  onItem(item: T, i: number, key: string | number): React.ReactNode
  children?: React.ReactNode
}) => {
  const isInTag = Boolean(children || className)

  const data = (category || of('')).pipe(
    scan<string, [string, string]>(
      (result, current) => {
        return [result[1], current]
      },
      ['', '']
    ),
    switchMap(([prevKey, nextKey]) =>
      items.pipe(
        scan<T[], ListUpdate<T>>(
          (result, current) => {
            const prev = result.next
            const next = current
            const keysToRemove: KeyToRemove[] = prev
              .filter(x => !next.find(y => y.id === x.id))
              .map(x => ({ id: x.id, isRemoved: false }))

            return { prev, next, keysToRemove: Atom.create(keysToRemove) }
          },
          { prev: [], next: [], keysToRemove: Atom.create([]) }
        ),
        switchMap(({ prev, next, keysToRemove }) => {
          // console.log('Lifecycle', prev, next, keysToRemove.get())
          return keysToRemove.view(_keysToRemove => {
            const unfinishedKeys = _keysToRemove.filter(x => !x.isRemoved).length

            const isFinished =
              (unfinishedKeys === 0 ||
                (next.filter(x => prev.find(y => y.id === x.id)).length === 0 &&
                  isAnimateOnlyOne)) &&
              !(unfinishedKeys === 1 && isAnimateOnlyOne && next.length === 0)

            const items = isFinished
              ? next
              : prev.filter(item => !_keysToRemove.find(x => x.id === item.id && x.isRemoved))

            return {
              items,
              prev,
              keysToRemove,
              _keysToRemove,
              isFinished,
              prevKey,
              nextKey
            }
          })
        })
      )
    )
  )

  const content = data.pipe(
    map(({ items, keysToRemove, _keysToRemove, isFinished, prev, prevKey, nextKey }) => {
      const list = (
        <>
          {items.length > 0
            ? items.map((item, i) => {
                const indexOfRemoval = _keysToRemove.findIndex(x => x.id === item.id)

                return (
                  <FoldableDiv
                    key={item.id}
                    onFoldEnd={() => {
                      const x = keysToRemove.lens(Lens.index<KeyToRemove>(indexOfRemoval))

                      x.lens(
                        Lens.create(
                          (x: KeyToRemove | undefined) => x && x.isRemoved,
                          (v: boolean, x) => x && { ...x, isRemoved: v }
                        )
                      ).set(true)
                    }}
                    {...classes(style.animationContainer)}
                    isFold={!isFinished && indexOfRemoval !== -1}
                  >
                    {onItem(item, i, i)}
                  </FoldableDiv>
                )
              })
            : empty}
          {children}
        </>
      )

      // console.log('HEHE', items, prev, _keysToRemove, isFinished)
      return isInTag ? (
        <div
          key={nextKey}
          {...classes(
            style.list,
            className,
            prevKey !== nextKey ? style.init : style.inited,
            items.length === 0 && emptyClassName
            // items.length === 1 &&
            //   prev.length === 1 &&
            //   _keysToRemove.length === 1 &&
            //   style.deletingLast,
            // items.length === 0 && prev.length === 1 && style.deletedLast
          )}
        >
          {list}
        </div>
      ) : (
        list
      )
    })
  )

  return <F.Fragment>{content}</F.Fragment>
}
export const FoldableDiv = ({
  children,
  isFold,
  onFoldEnd,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & { isFold: boolean; onFoldEnd: Function }) => {
  const el = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!el.current) return
    el.current.style.setProperty('--current-height', el.current.clientHeight + 'px')
  }, [isFold])

  return (
    <div
      ref={el}
      onAnimationEnd={e => {
        if (e.target === el.current) onFoldEnd()
      }}
      {...classes(className, isFold && style.fold)}
      {...other}
    >
      {children}
    </div>
  )
}
