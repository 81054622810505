import 'antd/es/select/style/index.css'

import { classes } from '@grammarly/focal'
import Select from 'antd/es/select'
import debounce from 'lodash.debounce'
import uniqBy from 'lodash/uniqBy'
import React, { useEffect, useState } from 'react'

import { Contact } from '../types'
import { getDefaultUndoState, UndoState } from '../undo'
import * as Undo from '../undo'
import { getParentBySelector } from '../utils/dom'
import { AvatarWithTitle } from './avatar'
import { Icons, SvgIcon } from './icons'
import { OvalLoader } from './loader'
import * as style from './memberSelect.styl'
import { Ripple } from './ripple'

export const MemberItem = ({
  member,
  phone,
  noRipple
}: {
  member: Contact
  phone: string
  noRipple?: boolean
}) => {
  return (
    <>
      {!noRipple && <Ripple />}
      <AvatarWithTitle member={member} isBig isUser phone={phone} />
    </>
  )
}

interface MemberSelectValue {
  key: string
  label: { props: { member: Contact } }
}

export const MemberSelect = (props: {
  phone: string
  type: 'user' | 'chat'
  isSingle?: boolean
  label?: string
  helper?: React.ReactNode
  helperClass?: string
  disabled?: boolean
  value?: Contact[]
  debounceTime?: number
  openOnFocus?: boolean
  autoFocus?: boolean
  button?: React.ReactNode
  getData(phone: string, query: string): Promise<Contact[]>
  onChange?(x: Contact[]): void
}) => {
  const [undoState, setUndoState] = useState<UndoState>(getDefaultUndoState())
  const [fetchError, setFetchError] = useState(false)
  const [query, setQuery] = useState('')

  const [stateValue, setValue] = useState(props.value || [])

  const [state, setState] = useState<{
    dropDownData: Contact[]
    fetching: boolean
  }>({
    dropDownData: [],
    fetching: false
  })

  const innerFetch = async (value: string) => {
    try {
      setState({
        dropDownData: await props.getData(props.phone, value),
        fetching: false
      })
    } catch (e) {
      console.error(e)
      setFetchError(true)
    }
  }

  const debouncedGetData =
    props.debounceTime !== undefined ? debounce(innerFetch, props.debounceTime) : innerFetch

  const fetchUser = (value: string) => {
    if (!value.trim() && !props.openOnFocus) {
      setIsOpen(false)
      return
    }

    setFetchError(false)
    setQuery(value)
    setState({ dropDownData: [], fetching: true })
    setIsOpen(true)

    debouncedGetData(value)
  }

  function updateUndo(prev: Contact[], next: Contact[]) {
    setUndoState(
      Undo.add(
        {
          undo: () => updateValue(prev),
          redo: () => updateValue(next)
        },
        undoState
      )
    )
  }

  const handleChange = (x: MemberSelectValue[]) => {
    // console.log('HEHE', x, close)
    setIsOpen(false)
    if (props.isSingle && x.length > 0) {
      updateValue([x.map(_x => _x.label.props.member)[x.length - 1]])
    } else {
      updateValue(x.map(_x => _x.label.props.member))
    }
  }

  function serialize() {
    return JSON.stringify({ type: props.type, phone: props.phone, items: getSelectedChoices() })
  }

  function onCopy(e: ClipboardEvent) {
    if (!e.clipboardData) return
    e.clipboardData.setData('text/json', serialize())
    e.preventDefault()
  }

  function onCut(e: ClipboardEvent) {
    if (!e.clipboardData) return
    e.clipboardData.setData('text/json', serialize())
    deleteSelected()
    e.preventDefault()
  }

  function updateValue(_value: Contact[]) {
    const value = uniqBy(_value, 'id')
    if (
      value.length === stateValue.length &&
      !value.find(x => !stateValue.find(y => y.id === x.id))
    )
      return
    updateUndo(stateValue, value)
    setValue(value)
    if (props.onChange) props.onChange(value)
  }

  function onPaste(e: ClipboardEvent) {
    if (!e.clipboardData) return
    if (!e.clipboardData.getData('text/json')) return

    try {
      const obj = JSON.parse(e.clipboardData.getData('text/json'))
      if (obj.phone !== props.phone) {
        console.warn('wrong phone', obj, props.phone)
        return
      }
      if (obj.type !== props.type) {
        console.warn('wrong type', obj, props.type)
        return
      }
      const contacts = obj.items as Contact[]

      if (Array.isArray(contacts) && contacts.every(x => x.id)) {
        const choices = getSelectedChoices()
        const valueWithoutSelected = stateValue.filter(x => !choices.includes(x))
        unselectAll()
        updateValue([...valueWithoutSelected, ...contacts])
      } else {
        console.warn('wrong input', obj)
      }
    } catch (e) {
      console.error(e)
    }

    e.preventDefault()
  }

  function deleteSelected() {
    const choices = getSelectedChoices()
    updateValue(stateValue.filter(x => !choices.includes(x)))
  }

  const tagSelector = '.ant-select-selection__choice'

  let selectionIndex = -1

  function getChoices() {
    if (!choicesContainer) return ([] as any) as NodeListOf<Element>
    return choicesContainer.querySelectorAll(tagSelector)
  }

  function getSelectedChoices() {
    if (!choicesContainer) return []
    const ids = Array.from(
      choicesContainer.querySelectorAll(`${tagSelector}.${style.selected} [data-id]`)
    ).map(x => x.getAttribute('data-id')!)

    return stateValue.filter(x => ids.includes(x.id))
  }

  function toggleSelection(i: number) {
    const x = getChoices()[i]
    if (!x) return console.warn('not found element with index', i)
    x.classList.toggle(style.selected)
  }
  function selectAll() {
    const xs = getChoices()
    xs.forEach(x => x.classList.add(style.selected))
    selectionIndex = xs.length
  }

  function unselectAll() {
    getChoices().forEach(x => x.classList.remove(style.selected))
    selectionIndex = -1
  }

  function selectLeft() {
    if (selectionIndex === 0 || getChoices().length === 0) return
    if (selectionIndex === -1) {
      selectionIndex = getChoices().length - 1
    } else {
      selectionIndex--
    }
    toggleSelection(selectionIndex)
  }

  function selectRight() {
    const length = getChoices().length
    if (selectionIndex === -1 || length === 0 || selectionIndex === length) return
    toggleSelection(selectionIndex)
    selectionIndex++
    if (selectionIndex === length) selectionIndex = -1
  }

  function onKeyDown(e: KeyboardEvent) {
    if (isOpen && e.code === 'Escape') {
      setIsOpen(false)
      return
    }

    if (props.openOnFocus && !isOpen && (e.code === 'Enter' || e.code === 'ArrowDown')) {
      e.stopImmediatePropagation()
      e.stopPropagation()
      setIsOpen(true)
      return
    }

    if ((e.code === 'KeyC' || e.code === 'KeyX' || e.code === 'KeyV') && (e.metaKey || e.ctrlKey)) {
      return
    }

    if ((e.code === 'KeyA' && (e.metaKey || e.ctrlKey)) || (e.code === 'ArrowUp' && e.shiftKey)) {
      selectAll()
      e.preventDefault()
      return
    }

    if (e.code === 'KeyZ' && (e.metaKey || e.ctrlKey) && e.shiftKey && input && !input.value) {
      setUndoState(Undo.redo(undoState))
      e.preventDefault()
      return
    }

    if (e.code === 'KeyZ' && (e.metaKey || e.ctrlKey) && input && !input.value) {
      setUndoState(Undo.undo(undoState))
      e.preventDefault()
      return
    }

    if (input && !input.value) {
      if (e.code === 'ArrowLeft' && e.shiftKey) {
        selectLeft()
        e.preventDefault()
        return
      }

      if (e.code === 'ArrowRight' && e.shiftKey) {
        selectRight()
        e.preventDefault()
        return
      }
    }

    const isTyping =
      e.code.includes('Digit') ||
      e.code.includes('Bracket') ||
      e.code === 'Backquote' ||
      e.code === 'Minus' ||
      e.code === 'Equal' ||
      e.code === 'Period' ||
      e.code === 'Comma' ||
      e.code === 'Slash' ||
      e.code === 'Backslash' ||
      e.code === 'Semicolon' ||
      e.code === 'Backspace' ||
      e.code.indexOf('Key') === 0

    if (isTyping && getSelectedChoices().length > 0) {
      deleteSelected()
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      return
    }

    // prevent pasting same stuff
    // preserve selection for undo/redo
    // make it inactive when blur window
    //
    if (
      e.code.includes('Arrow') ||
      e.code === 'Escape' ||
      e.code === 'Enter' ||
      e.code === 'Space' ||
      isTyping
    ) {
      unselectAll()
    }
  }
  function onFocus() {
    unselectAll()
    if (props.openOnFocus) {
      fetchUser('')
    }
  }

  function onMainElementClick() {
    setTimeout(() => {
      if (props.isSingle && !isOpen) {
        onFocus()
      }
      if (props.isSingle && isOpen) {
        setIsOpen(false)
      }
    }, 100)
  }

  function onClick(e: MouseEvent) {
    if (!e.metaKey && !e.ctrlKey) return
    if (!e.target) return
    const target = e.target as HTMLElement
    if (!target.matches(`${tagSelector},${tagSelector} *`)) return

    const el = target.matches(tagSelector) ? target : getParentBySelector(target, tagSelector)

    if (el) el.classList.toggle(style.selected)
  }

  useEffect(() => {
    if (props.disabled) return
    if (input) {
      input.addEventListener('copy', onCopy, true)
      input.addEventListener('paste', onPaste, true)
      input.addEventListener('cut', onCut, true)
      input.addEventListener('keydown', onKeyDown, true)
    }

    if (choicesContainer) {
      choicesContainer.addEventListener('click', onClick, true)
    }
    if (mainEl) {
      mainEl.addEventListener('click', onMainElementClick, true)
    }

    return () => {
      if (input) {
        input.removeEventListener('copy', onCopy, true)
        input.removeEventListener('cut', onCut, true)
        input.removeEventListener('paste', onPaste, true)
        input.removeEventListener('keydown', onKeyDown, true)
      }
      if (choicesContainer) {
        choicesContainer.removeEventListener('click', onClick, true)
      }

      if (mainEl) {
        mainEl.removeEventListener('click', onMainElementClick, true)
      }
    }
  })

  const { fetching, dropDownData } = state

  let input: HTMLInputElement | undefined
  let choicesContainer: HTMLDivElement | undefined
  let mainEl: HTMLDivElement | undefined

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={style.selectWrap}>
      <Select
        ref={_el => {
          if (_el) {
            // console.log((_el as any).rcSelect)
            const select = (_el as any).rcSelect
            input = select.getInputDOMNode()
            choicesContainer = select.topCtrlRef
            if (
              choicesContainer &&
              choicesContainer.parentElement &&
              choicesContainer.parentElement.parentElement
            ) {
              mainEl = choicesContainer.parentElement.parentElement as HTMLDivElement
            }
          }
        }}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        open={isOpen}
        showArrow={props.isSingle}
        // onDropdownVisibleChange={setIsOpen}
        mode={'multiple'}
        animation={null}
        transitionName={null}
        choiceTransitionName={null}
        labelInValue
        value={stateValue.map(x => ({
          key: x.id,
          label: <MemberItem noRipple member={x} phone={props.phone} />
        }))}
        placeholder={
          props.label ? props.label : `Select ${props.type === 'chat' ? 'chat' : 'user'}s`
        }
        dropdownClassName={style.dropdown}
        notFoundContent={
          fetchError ? (
            <div className={style.searchError}>
              <div className={style.errorWrap}>
                <SvgIcon className={style.errorIcon} icon={Icons.error} />
                Error getting data. Retry later.
              </div>
            </div>
          ) : fetching ? (
            <div className={style.loader}>
              <OvalLoader />
            </div>
          ) : query.trim() ? (
            <div className={style.searchError}>
              <div className={style.errorWrap}>
                No matches for <b>„{query}”</b>
              </div>
            </div>
          ) : (
            <div>No data</div>
          )
        }
        filterOption={false}
        onSearch={fetchUser}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={() => {
          unselectAll()
          setIsOpen(false)
        }}
        className={style.memberSelect}
        menuItemSelectedIcon={<></>}
        removeIcon={<SvgIcon icon={Icons.close} />}
        {...classes(
          style.memberSelect,
          props.isSingle && style.isSingle,
          stateValue.length > 0 && style.notEmpty
        )}
        style={{ width: '100%' }}
        {...({ useAnim: false } as any)}
      >
        {dropDownData
          .filter(x => !stateValue.find(y => x.id === y.id))
          .map(x => (
            <Select.Option key={x.id} className={style.item}>
              <MemberItem member={x} phone={props.phone} />
            </Select.Option>
          ))}
      </Select>
      {props.button}
      {props.helper && <div {...classes(style.helper, props.helperClass)}>{props.helper}</div>}
    </div>
  )
}
