import { Atom } from '@grammarly/focal'
import { useEffect, useRef } from 'react'

export class ErrorState {
  emptyError = { message: '', isShow: false }
  state = Atom.create<{ message: string; isShow: boolean }>(this.emptyError)
  show(message?: string) {
    if (message) {
      this.state.set({
        message,
        isShow: true
      })
    }
  }
  hide() {
    this.state.modify(x => ({ ...x, isShow: false }))
  }
}

export function useFocus(focus: Function, timeout = 200) {
  useEffect(() => {
    const timer = setTimeout(() => focus(), timeout)
    return () => {
      if (timer) clearTimeout(timer)
    }
  })
}

export function useFocusRef<T extends { focus: Function }>(timeout = 200) {
  const ref = useRef<T>()

  useFocus(() => {
    if (ref.current) ref.current.focus()
  }, timeout)
  return ref
}

export function useSubmit(submit: Function) {
  useEventListener('keydown', (e: KeyboardEvent) => {
    if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
      submit()
    }
  })
}

// Hook
export function useEventListener(eventName: string, handler: Function, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef<any>()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Make sure element supports addEventListener
    // On
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: any) => savedHandler.current(event)

    // Add event listener
    element.addEventListener(eventName, eventListener)

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element]) // Re-run if eventName or element changes
}
