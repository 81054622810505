import React, { useRef, useEffect } from 'react'
import { classes } from '@grammarly/focal'
import { track } from '../utils/tracking'

export const Link = ({
  children,
  className,
  onNavigation,
  trackingName,
  ...other
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  onNavigation?: Function
  trackingName?: string
}) => {
  const elRef = useRef<HTMLAnchorElement | null>(null)
  const onClick_ = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    if (trackingName) {
      track(trackingName)
    }
    if (onNavigation) onNavigation()
    return false
  }
  useEffect(() => {
    if (elRef.current) {
      elRef.current.addEventListener('click', onClick_, false)
    }
    return () => {
      if (elRef.current) elRef.current.removeEventListener('click', onClick_, false)
    }
  })
  return (
    <a ref={elRef} {...classes(className)} {...other}>
      {children}
    </a>
  )
}
