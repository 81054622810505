export function setWithExpiration(key: string, value: string, ttl: number) {
  localStorage.setItem(key, JSON.stringify({ value: value, expiration: Date.now() + ttl }))
}

export function getWithExpiration(key: string) {
  const x = localStorage.getItem(key)
  if (!x) return
  const data = JSON.parse(x)

  if (data.expiration) {
    if (data.expiration < Date.now()) {
      localStorage.removeItem(key)
    } else {
      return data.value as string
    }
  }

  return undefined
}
