import { Atom, ReadOnlyAtom } from '@grammarly/focal'

export enum TooltipAlignment {
  'top',
  'bottom'
}

export const tooltipDefaultState = {
  visible: false,
  content: '',
  alignment: TooltipAlignment.bottom,
  pos: {
    top: 0,
    left: 0
  },
  className: ''
}

export type TooltipState = typeof tooltipDefaultState

export interface ShowTooltipModel {
  show(
    target: ClientRect,
    content: string,
    alignment: TooltipAlignment,
    tooltipClassName?: string
  ): void
  hide(): void
}

export interface ViewTooltipModel {
  readonly tooltip: ReadOnlyAtom<TooltipState>
  readonly isVisible: boolean
}

export interface ITooltipModel extends ViewTooltipModel, ShowTooltipModel {}

export class TooltipModel implements ShowTooltipModel {
  constructor(private _state: Atom<TooltipState>) {}

  private _visible = this._state.lens('visible')

  tooltip = this._state.view()

  get isVisible() {
    return this._state.get().visible
  }

  show(
    target: ClientRect,
    content: string,
    alignment: TooltipAlignment,
    tooltipClassName?: string
  ) {
    const { left, bottom, top, width } = target
    const top_ = top - 26 // 14px - height of the tooltip element + 12px padding
    this._state.set({
      visible: true,
      content,
      alignment,
      pos: {
        top: alignment === TooltipAlignment.top ? top_ : bottom,
        left: left + width / 2
      },
      className: tooltipClassName || ''
    })
  }

  hide() {
    this._visible.set(false)
  }
}
