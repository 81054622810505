// import { withAuthenticator } from 'aws-amplify-react'
import '@aws-amplify/core'

import Auth, { CognitoUser } from '@aws-amplify/auth'
import { Atom, classes, F, Lens } from '@grammarly/focal'
import page from 'page'
import * as React from 'react'

import { authApi, getEmail, refreshIdToken, resendConfirmation } from '../authApi'
import aws_exports from '../aws-exports'
import { AddButton } from '../components/buttons'
import { Conditions } from '../components/conditions'
import { Filters } from '../components/filters'
import { Footer } from '../components/footer'
import { AuthDialog, Step } from '../components/forms/auth'
import { TargetResult } from '../components/forms/targets'
import { Header } from '../components/header'
import { ScrollableDiv } from '../components/layout'
import * as style from '../components/layout.styl'
import { AppLoader } from '../components/loader'
import { Profile } from '../components/profile'
import { AddServiceDialog, Services, ServiceChooserDialog } from '../components/services'
import { Targets } from '../components/targets'
import { RootTooltip } from '../components/tooltip'
import { authConfig, allowedIds, enableRegistrations } from '../config'
import { ifvisible } from '../libs/ifvisible'
import {
  AppState,
  AppUser,
  getState,
  getTransformedUser,
  getWriteApi,
  pageParams,
  resetState,
  planById
} from '../model'
import {
  Filter,
  SearchExpression,
  TelegramService,
  TelegramTarget,
  WebhookTarget,
  ExportJob,
  Plan
} from '../types'
import { isValidUrl } from '../utils/string'
import * as tracking from '../utils/tracking'
import { Welcome } from '../components/welcome'
import { PlanSelectorDialog } from '../components/forms/telegram'
// import { Export } from '../components/forms/export'

Auth.configure({
  ...aws_exports,
  ...authConfig
})

function selectedService(x: AppState) {
  return x.user.telegramServicesArray[x.selection.service]
}

function hasExportJob(service: TelegramService) {
  return Boolean(service.filters.find(x => x.exportJobs?.find(j => j.isRunning)))
}

const stripPlus = (x?: string) => x && x.replace('+', '').replace(' ', '')

const DashboardWithState = ({
  state,
  isOpenDialog,
  isDelete,
  closeProfile,
  isOpenProfile,
  openProfile,
  initUserState,
  cognitoUser
}: {
  state: Atom<AppState>
  isOpenDialog: Atom<boolean>
  isDelete: Atom<boolean>
  closeProfile: Function
  openProfile: Function
  isOpenProfile: Atom<boolean>
  initUserState: Function
  cognitoUser: CognitoUser
}) => {
  const selection = state.lens('selection')
  const userLens = state.lens('user')
  const services = userLens.lens('telegramServicesArray')

  const isEmptySelector = Lens.create(
    (xs: TelegramService[]) => xs.length === 0,
    // and the setter: returns a new object with the `a` property updated to a new value
    (value, x) => (value ? [] : x)
  )

  let openTelegramDialogCommand:
    | React.Dispatch<
        React.SetStateAction<{
          isOpen: boolean
          item?: TelegramService | undefined
        }>
      >
    | undefined = undefined

  let serviceItemCommands:
    | {
        setLoadingItem: (phone: string, value: boolean) => void
        setError: (phone: string, message: string) => void
        onPause: (x: TelegramService) => Promise<void>
      }
    | undefined = undefined
  const openTelegramDialog = () => {
    if (openTelegramDialogCommand) openTelegramDialogCommand({ isOpen: true })
  }

  const openFilterDialog = Atom.create<{ isOpen: boolean; item?: Filter }>({ isOpen: false })

  const RouteUpdater = () => {
    const getUrl = (phone: string | undefined, filter: string | number | undefined) =>
      `/${stripPlus(phone)}${filter ? `/${filter}` : ''}`

    const Url = ({ url }: { url: string }) => {
      React.useEffect(() => {
        // console.log('set url', url)
        page(url)
      }, [url])

      return <></>
    }

    React.useEffect(() => {
      pageParams.subscribe(x => {
        if (!x) return
        updateSelection(state, x, openProfile, openTelegramDialog)
      })
    }, [])

    return (
      <F.Fragment>
        {selection.view(x => {
          const service = state.get().user.telegramServicesArray[x.service]
          if (!service) return
          if (['/profile', '/connect'].find(x => document.location.pathname.includes(x))) return
          const filter = x.filter

          return <Url url={getUrl(service.phone, filter)} />
        })}
      </F.Fragment>
    )
  }

  function serviceByIndex(index: number) {
    const serviceSelector = Lens.index<TelegramService>(index)
    return services.lens(serviceSelector) as Atom<TelegramService>
  }

  function selectedFilters() {
    return serviceByIndex(selection.lens('service').get()).lens('filters')
  }

  function selectedFilter() {
    const filters = selectedFilters()
    const selectedFilterLens = Lens.index<Filter>(selection.lens('filter').get())

    return filters.lens(selectedFilterLens) as Atom<Filter>
  }

  function onNewCondition(x: SearchExpression) {
    const searchExpressions = selectedFilter().lens('searchExpressions')

    searchExpressions.modify(currentSearchExpressions => [...currentSearchExpressions, x])
  }

  ;(window as any).finishJob = () => {
    const jobs = selectedFilter().lens('exportJobs')
    const x = jobs.lens(Lens.index<ExportJob>(0)) as Atom<ExportJob>
    x.lens('isRunning').set(false)
    x.lens('isOver').set(true)
  }

  async function onTargetSubmit(x: TargetResult, isEdit?: boolean) {
    if (x.webhook) {
      if (!isValidUrl(x.webhook.url)) {
        return {
          url: 'Not valid URL',
          isValid: false
        }
      }
      const filterId = selectedFilter().get().id
      const result = await getWriteApi().editWebhookTarget(
        x.phone,
        filterId,
        x.webhook,
        isEdit ? x.webhook.id : undefined
      )

      if (!result.validation.isValid) {
        return result.validation
      }

      const targets = selectedFilter().lens('webhookTargets')

      if (isEdit) {
        targets.lens(Lens.find<WebhookTarget>(y => y.id === x.webhook!.id)).set(x.webhook)
      } else {
        targets.modify(current => [...(current || []), result.item!])
      }

      return result.validation
    }

    if (x.telegram) {
      const targets = selectedFilter().lens('telegramTargets')

      const filterId = selectedFilter().get().id
      const result = await getWriteApi().editTelegramTarget(
        x.phone,
        filterId,
        x.telegram,
        isEdit ? x.telegram.id : undefined
      )
      if (!result.validation.isValid) {
        return result.validation
      }

      if (isEdit) {
        targets.lens(Lens.find<TelegramTarget>(y => y.id === x.telegram!.id)).set(x.telegram)
      } else {
        targets.modify(current => [...(current || []), result.item!])
      }
      return result.validation
    }

    if (x.export) {
      const xs = selectedFilter().lens('exportJobs')
      const filterId = selectedFilter().get().id
      // window.appState.lens('user').lens('telegramServicesArray').lens('0').lens('filters').lens('0').lens('exportJobs').lens('0').lens('isOver').set(true)

      const result = await getWriteApi().editExportJob(
        x.phone,
        filterId,
        x.export,
        isEdit ? x.export.id : undefined
      )

      if (!result.validation.isValid) {
        return result.validation
      }

      if (result.item && isEdit) {
        xs.lens(Lens.find<ExportJob>(y => y.id === x.export!.id)).set(result.item!)
      } else {
        xs.modify(current => [...(current || []), result.item!])
      }
    }

    return {
      isValid: true
    }
  }

  async function onNewService(x: TelegramService) {
    // console.log('modify services')
    services.modify(currentServices => [...currentServices, x])
    const newIndex = Object.keys(services.get() || {}).length - 1
    selection.lens('service').set(newIndex)
    currentService.set(x)
    const plan = state.get().user.plans.find(p => p.id === x.planId)
    if (plan) {
      bindPlanWithService(plan, x)
    }
  }

  const emptyServiceDialogOpen = Atom.create(services.get().length === 0)
  const openTelegramDialogDelayed = Atom.create(services.get().length === 0)

  const seenWelcome = state.lens('user').lens('seenWelcome')
  const whitelisted = state.lens('user').lens('isWhitelisted')

  const w = window as any

  w.setFirstService = (x: any) => {
    services.lens(Lens.find<TelegramService>(x => x.phone === x.phone)).set(x)
  }

  function setOpenDialog() {
    // console.log('open dialog')
    isOpenDialog.set(true)
  }
  function setCloseDialog() {
    isOpenDialog.set(false)
    // console.log('close dialog')
  }

  function setDeleteModeOn() {
    // console.log('setDeleteModeOn')
    isDelete.set(true)
  }
  function setDeleteModeOff() {
    // console.log('setDeleteModeOff')
    isDelete.set(false)
  }

  const isOpenServiceChooser = Atom.create(false)
  const isOpenPlanSelector = Atom.create(false)

  const currentPlan: Atom<Plan | undefined> = Atom.create(undefined)
  const currentService: Atom<TelegramService | undefined> = Atom.create(undefined)

  const forcedCloseInitial = Atom.create(false)

  const closeServiceChooser = () => {
    isOpenServiceChooser.set(false)
    setCloseDialog()
  }
  const openServiceChooser = (service?: TelegramService) => {
    isOpenServiceChooser.set(true)
    setOpenDialog()
  }

  const closePlanSelector = () => {
    isOpenPlanSelector.set(false)
    setCloseDialog()
  }
  const openPlanSelector = (service: TelegramService) => {
    currentService.set(service)
    isOpenPlanSelector.set(true)
    setOpenDialog()
  }

  const bindPlanWithService = (plan: Plan, service: TelegramService) => {
    const index = services.get().indexOf(service)
    if (index === -1 || !plan) return

    // if switching to other plan
    removeServiceFromPlan(service, state)

    const modifiedService = {
      ...services.get()[index],
      ...{
        planId: plan.id,
        plan: plan
      }
    }
    serviceByIndex(index).set(modifiedService)

    planById(state.lens('user').lens('plansWithService'), plan.id)
      .lens('service')
      .set(modifiedService)

    return modifiedService
  }

  const servicesComponent = (isDialogMode = false) => (
    <Services
      onChange={() => selection.lens('filter').set(0)}
      onNew={onNewService}
      plans={state.lens('user').lens('plansWithService')}
      canTrial={state.lens('user').lens('canTrial')}
      isWhitelisted={state.lens('user').lens('isWhitelisted')}
      onEdit={service => {
        services.lens(Lens.find<TelegramService>(x => x.phone === service.phone)).set(service)
      }}
      onSelectPlanForPausedService={openPlanSelector}
      onDelete={service => {
        const index = services.get().indexOf(service)

        selection.lens('service').modify(x => {
          if (x >= index) return Math.max(x - 1, 0)
          return x
        })

        console.log(state.get())
        removeServiceFromPlan(service, state)
        console.log(state.get())

        if (services.get().length === 1) {
          setTimeout(() => services.lens(isEmptySelector).set(true), 10)
          emptyServiceDialogOpen.set(true)
          openTelegramDialogDelayed.set(true)
          return
        }

        services.modify(currentServices => [...currentServices.filter(y => y !== service)])
      }}
      data={services}
      selectedItem={selection.lens('service')}
      onCreateFilter={() => openFilterDialog.set({ isOpen: true })}
      onCloseDialog={setCloseDialog}
      onOpenDialog={setOpenDialog}
      onShowDelete={setDeleteModeOn}
      onHideDelete={setDeleteModeOff}
      setOpenCommand={x => (openTelegramDialogCommand = x)}
      setItemCommands={x => (serviceItemCommands = x)}
      currentPlan={currentPlan}
      currentService={currentService}
      isDialogMode={isDialogMode}
    />
  )

  return (
    <>
      <Profile
        onDeleteUser={() => {
          closeProfile()
          initUserState()
        }}
        isWhitelisted={state.lens('user').lens('isWhitelisted')}
        plans={state.lens('user').lens('plansWithService')}
        user={cognitoUser}
        canTrial={state.lens('user').lens('canTrial')}
        onClose={closeProfile}
        isShow={isOpenProfile}
        onConnectService={(plan, service) => {
          currentPlan.set(plan)
          currentService.set(service)
          // .filter(x => !x.planId)
          if (services.get().length > 0 || service) {
            openServiceChooser(service)
          } else {
            openTelegramDialog()
          }
        }}
      />

      <div className={style.fullWidth}>
        <RouteUpdater />

        <F.Fragment>
          {Atom.combine(
            emptyServiceDialogOpen,
            forcedCloseInitial,
            seenWelcome,
            whitelisted,
            (isEmptyServiceDialogOpen, isForcedCloseInitial, isSeenWelcome, isWhitelisted) => {
              if (!isSeenWelcome) {
                return (
                  <Welcome
                    isShow={!isSeenWelcome}
                    // todo app public or not public
                    isWhitelisted={
                      isWhitelisted ||
                      allowedIds.includes(state.get().user.entryId) ||
                      enableRegistrations
                    }
                    onClose={async () => {
                      seenWelcome.set(true)
                      await getWriteApi().settings({
                        seenWelcome: true
                      })
                    }}
                    user={state.get().user}
                  />
                )
              }

              return isEmptyServiceDialogOpen ? (
                <F.Fragment>
                  <ScrollableDiv style={{ paddingTop: '40px' }}>
                    <AddButton
                      onClick={() => {
                        forcedCloseInitial.set(false)
                      }}
                      trackingName="addServiceInitialBtn"
                      tooltip={'Connect your Telegram account, so you can setup filters for it.'}
                    >
                      Connect Telegram
                    </AddButton>
                    {servicesComponent(true)}
                  </ScrollableDiv>
                  {!isForcedCloseInitial && (
                    <AddServiceDialog
                      plans={state.lens('user').lens('plansWithService')}
                      canTrial={state.lens('user').lens('canTrial')}
                      isWhitelisted={state.lens('user').lens('isWhitelisted')}
                      openTelegramDialog={openTelegramDialogDelayed}
                      onCreateFilter={() =>
                        setTimeout(() => {
                          // console.log('on create filter')
                          openFilterDialog.set({ isOpen: true })
                        }, 200)
                      }
                      onNew={onNewService}
                      onClose={() => {
                        if (services.get().length > 0) {
                          openTelegramDialogDelayed.set(false)
                          // wait animation completion
                          setTimeout(() => emptyServiceDialogOpen.set(false), 100)
                        } else {
                          forcedCloseInitial.set(true)
                        }
                        setCloseDialog()
                      }}
                      currentService={currentService}
                      onShow={setOpenDialog}
                    />
                  )}
                </F.Fragment>
              ) : (
                <div {...classes(style.simple, style.appear)} data-dialog-hide={true}>
                  <ScrollableDiv {...classes(style.colContent, style.colSeparator, style.services)}>
                    {servicesComponent()}
                  </ScrollableDiv>
                  <ScrollableDiv {...classes(style.colMiddle, style.colSeparator, style.filters)}>
                    <Filters
                      data={state.view(x => {
                        const service = selectedService(x)
                        // console.log('render filters for service', service)
                        return {
                          filters: service.filters,
                          phone: service.phone,
                          service,
                          serviceHasExportJob: hasExportJob(service)
                        }
                      })}
                      openFilterDialog={openFilterDialog}
                      onTargetSubmit={onTargetSubmit}
                      selectedItem={selection.lens('filter')}
                      onNewCondition={onNewCondition}
                      onNew={x => {
                        // console.log('modify filters')
                        // console.log(services.get()[currentService.id], services.get())
                        const filters = selectedFilters()

                        filters.modify(currentFilters => [...currentFilters, x])
                        const newIndex = filters.get().length - 1
                        selection.lens('filter').set(newIndex)
                      }}
                      onEdit={x => {
                        const filters = selectedFilters()
                        const index = filters.get().findIndex(y => y.id === x.id)
                        if (index === -1) return

                        filters.lens(Lens.index<Filter>(index)).set(x)
                      }}
                      onDelete={filter => {
                        const filters = selectedFilters()
                        const index = filters.get().indexOf(filter)

                        selection.lens('filter').modify(x => {
                          if (x >= index) return Math.max(x - 1, 0)
                          return x
                        })

                        filters.modify(xs => [...xs.filter(y => y !== filter)])
                      }}
                      onCloseDialog={setCloseDialog}
                      onOpenDialog={setOpenDialog}
                      onShowDelete={setDeleteModeOn}
                      onHideDelete={setDeleteModeOff}
                    />
                  </ScrollableDiv>

                  <ScrollableDiv {...classes(style.simpleRight, style.conditionsTargets)}>
                    <div {...classes(style.flex)} style={{ paddingTop: '20px' }}>
                      <div {...classes(style.col100p, style.wideBlock)}>
                        <Conditions
                          onAddFilter={() => openFilterDialog.set({ isOpen: true })}
                          onTargetSubmit={onTargetSubmit}
                          onNew={onNewCondition}
                          onDelete={x => {
                            const searchExpressions = selectedFilter().lens('searchExpressions')
                            searchExpressions.modify(xs => [...xs.filter(y => y.id !== x.id)])
                          }}
                          onEdit={x => {
                            const searchExpressions = selectedFilter().lens('searchExpressions')

                            searchExpressions
                              .lens(Lens.find<SearchExpression>(y => y.id === x.id))
                              .set(x)
                          }}
                          data={state.view(x => {
                            const service = selectedService(x)
                            const filter = service.filters[x.selection.filter]
                            return {
                              service,
                              filter,
                              serviceHasExportJob: hasExportJob(service),
                              conditions: filter ? filter.searchExpressions : [],
                              phone: service.phone,
                              filterId: filter ? filter.id : '',
                              hasFilters: service.filters.length > 0,
                              hasTargets:
                                filter &&
                                (filter.telegramTargets.length > 0 ||
                                  (filter.webhookTargets || []).length > 0 ||
                                  (filter.exportJobs || []).length > 0)
                            }
                          })}
                          onCloseDialog={setCloseDialog}
                          onOpenDialog={setOpenDialog}
                          onShowDelete={setDeleteModeOn}
                          onHideDelete={setDeleteModeOff}
                        />
                      </div>
                      <div {...classes(style.rightBlock)}>
                        <Targets
                          isWhitelisted={state.lens('user').lens('isWhitelisted')}
                          onAddFilter={() => openFilterDialog.set({ isOpen: true })}
                          onSubmit={onTargetSubmit}
                          onDelete={x => {
                            if (x.webhook) {
                              const targets = selectedFilter().lens('webhookTargets')
                              targets.modify(xs => [...(xs || []).filter(y => y !== x.webhook!)])
                            }
                            if (x.telegram) {
                              const targets = selectedFilter().lens('telegramTargets')
                              targets.modify(xs => [...(xs || []).filter(y => y !== x.telegram!)])
                            }
                            if (x.export) {
                              const jobs = selectedFilter().lens('exportJobs')
                              jobs.modify(xs => [...(xs || []).filter(y => y !== x.export!)])
                            }
                          }}
                          value={state.view(x => {
                            const service = selectedService(x)
                            const filter = service.filters[x.selection.filter]
                            return {
                              service,
                              telegramTargets: filter?.telegramTargets || [],
                              webhookTargets: filter?.webhookTargets || [],
                              exportJobs: filter?.exportJobs || [],
                              phone: service.phone,
                              filterId: filter ? filter.id : '',
                              filter,
                              hasFilters: Boolean(filter),
                              serviceHasExportJob: hasExportJob(service)
                            }
                          })}
                          onCloseDialog={setCloseDialog}
                          onOpenDialog={setOpenDialog}
                          onShowDelete={setDeleteModeOn}
                          onHideDelete={setDeleteModeOff}
                        />
                      </div>
                    </div>
                  </ScrollableDiv>
                </div>
              )
            }
          )}
        </F.Fragment>
        <F.Fragment>{seenWelcome.view(x => x && <Footer />)}</F.Fragment>
        <PlanSelectorDialog
          onClose={closePlanSelector}
          isOpen={isOpenPlanSelector}
          currentPlan={currentPlan}
          onSelect={async plan => {
            closePlanSelector()
            const phone = currentService.get()!.phone
            serviceItemCommands?.setLoadingItem(phone, true)
            const result = await getWriteApi().assignPlan(phone, plan.id)
            serviceItemCommands?.setLoadingItem(phone, false)

            if (!result.validation.isValid) {
              serviceItemCommands?.setError(
                phone,
                result.validation.message || 'Error assigning plan.'
              )
            } else {
              const modifiedService = bindPlanWithService(plan, currentService.get()!)
              if (modifiedService?.isPaused) {
                serviceItemCommands?.onPause(modifiedService)
              }
            }
          }}
          plans={state.lens('user').lens('plansWithService')}
          canTrial={state.lens('user').lens('canTrial')}
        />

        <ServiceChooserDialog
          services={services}
          isOpen={isOpenServiceChooser}
          currentPlan={currentPlan}
          currentService={currentService}
          onNew={plan => {
            closeServiceChooser()
            currentPlan.set(plan)
            openTelegramDialog()
          }}
          onSelect={x => {
            // console.log('selected service', x)
            bindPlanWithService(currentPlan.get()!, x)
            closeServiceChooser()
          }}
          onClose={closeServiceChooser}
        ></ServiceChooserDialog>
        <RootTooltip />
      </div>
    </>
  )
}

function removeServiceFromPlan(service: TelegramService, state: Atom<AppState>) {
  if (service.planId) {
    planById(state.lens('user').lens('plansWithService'), service.planId)
      .lens('service')
      .set(undefined)
  }
}

function updateSelection(
  state: Atom<AppState>,
  params: Record<string, string>,
  openProfile: Function,
  openTelegramDialog: Function
) {
  if (document.location.pathname.includes('/profile')) {
    openProfile()
    return
  }

  if (document.location.pathname.includes('/connect')) {
    openTelegramDialog()
    return
  }

  const serviceIndex = state.get().selection.service
  let service = state.get().user.telegramServicesArray[serviceIndex]

  if (!service) return
  const selection = state.lens('selection')

  const x: {
    service?: number
    filter?: number
  } = {}

  let isModifySelection = false
  if (params.phone && stripPlus(params.phone) !== stripPlus(service.phone)) {
    isModifySelection = true
    x.service = state
      .get()
      .user.telegramServicesArray.findIndex(y => stripPlus(y.phone) === stripPlus(params.phone))
    service = state.get().user.telegramServicesArray[x.service]
  }
  if (!service) return

  const filter = parseInt(params.filter, 10) || 0
  if (filter >= 0 && filter < service.filters.length && filter !== selection.get().filter) {
    isModifySelection = true
    x.filter = filter
  }

  if (isModifySelection) {
    selection.modify(s => ({
      ...s,
      ...x
    }))
  }
}

class StateUpdater {
  constructor(private isOpenDialog: Atom<boolean>, private isDelete: Atom<boolean>) {}
  public user: Atom<AppUser> | undefined = undefined
  public userSelector = Lens.create(
    (x: AppState) => x.user,
    // and the setter: returns a new object with the `a` property updated to a new value
    (value, x) => (value && x ? { ...x, user: value } : x)
  )

  private interval: NodeJS.Timeout | undefined
  private sessionInterval: NodeJS.Timeout | undefined

  private isRequesting = false
  private isForce = false
  // private id = Math.random()

  async updateUser() {
    // console.log('update user', this.id)
    if (this.isRequesting) return
    if (this.user && this.user.get().date) {
      this.isRequesting = true
      try {
        const isEditMode =
          this.isOpenDialog.get() ||
          this.isDelete.get() ||
          document.querySelector('[role="dialog"]')
        //  if (this.user && this.user.get().date) {
        const user = await getTransformedUser(
          !isEditMode && this.isForce ? 0 : this.user.get().date
        )
        // handling update during editing
        if (isEditMode) {
          if (user) this.isForce = true
          this.isRequesting = false
          return
        } else {
          this.isForce = false
        }

        if (user && (this.user.get().date || 0) < (user.date || 0)) {
          // console.log('update to latest server state', this.user.get().date, user.date)
          this.user.set(user)
        }
      } catch (e) {
        console.warn(`Can't get fresh user ${e.message}`)
      }

      this.isRequesting = false
    }
  }

  stop() {
    // console.log('Stopped')
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  }

  start() {
    // console.log('Started')
    if (!this.interval) this.interval = setInterval(() => this.updateUser(), 10 * 1000)
    // once in 30 min refresh id token
    refreshIdToken()
    if (!this.sessionInterval) this.interval = setInterval(() => refreshIdToken(), 60 * 1000 * 30)
  }
}

export type AppStateAtom = Atom<{
  state?: Atom<AppState> | undefined
  error?: React.ReactNode
}>

const Dashboard_ = (props: {
  user: CognitoUser
  appState: AppStateAtom
  closeProfile: Function
  openProfile: Function
  isOpenProfile: Atom<boolean>
  initUserState: Function
}) => {
  const appState = props.appState
  // console.log('auth props', props)
  const isOpenDialog = Atom.create(false)
  const isDelete = Atom.create(false)
  const stateUpdater = new StateUpdater(isOpenDialog, isDelete)
  const email = getEmail(props.user)

  getState()
    .then(state => {
      stateUpdater.user = state.lens(stateUpdater.userSelector)
      updateSelection(state, pageParams.get(), props.openProfile, () => {})
      appState.set({ state })
      // tslint:disable-next-line: semicolon
      ;(window as any).appStateAtom = state

      tracking.setUser(stateUpdater.user.get().entryId)
    })
    .catch(e => {
      if (e) {
        console.warn(`Error loading initial state ${e.message}`)
        appState.set({
          error: (
            <div>
              Oops. We canʼt load application data. Try to refresh page.
              <br /> If it doesnʼt help,{' '}
              <a
                href={`mailto:support.filter@sitg.app?subject=Filter app loading error&body=Hi, my app is not loading. My account is ${email}`}
              >
                please let us know.
              </a>
            </div>
          )
        })
      }
    })

  React.useEffect(() => {
    const onIdle = () => {
      stateUpdater.stop()
    }
    const onWakeup = () => {
      stateUpdater.start()
    }
    ifvisible.on('idle', onIdle)
    ifvisible.on('wakeup', onWakeup)

    stateUpdater.start()

    return () => {
      // console.log('stop state updater')
      ifvisible.off('idle', onIdle)
      ifvisible.off('wakeup', onWakeup)
      stateUpdater.stop()
      resetState()
    }
  }, [])

  // console.log('Render again')
  return (
    <F.Fragment>
      {appState.view(x =>
        x.state ? (
          <DashboardWithState
            closeProfile={props.closeProfile}
            openProfile={props.openProfile}
            isOpenProfile={props.isOpenProfile}
            isDelete={isDelete}
            isOpenDialog={isOpenDialog}
            state={x.state}
            cognitoUser={props.user}
            initUserState={props.initUserState}
          />
        ) : !x.error ? (
          <AppLoader />
        ) : (
          <>
            <div className={style.appLoadingError}>{x.error}</div>
            <Footer />
          </>
        )
      )}
    </F.Fragment>
  )
}

export const Console = () => {
  const state = Atom.create<{ user: CognitoUser | undefined; step: 'loading' | 'auth' | 'ok' }>({
    user: undefined,
    step: 'loading'
  })

  function initUserState() {
    Auth.currentUserPoolUser()
      .then(x => {
        // console.log(x)
        state.set({ user: x, step: x ? 'ok' : 'auth' })
      })
      .catch(e => {
        console.log(e)
        if (e.code === 'UserNotFoundException') {
          Auth.signOut()
          window.location.reload()
        }
        if (e === 'No current user') state.set({ user: undefined, step: 'auth' })
      })
  }

  tracking.init()

  initUserState()

  const isOpenProfile = Atom.create(false)
  const isOpenExport = Atom.create(false)

  function openProfile() {
    page('/profile')
    isOpenProfile.set(true)
  }
  function closeProfile() {
    if (!isOpenProfile.get()) return
    page('/')
    isOpenProfile.set(false)
  }

  function openExport() {
    page('/export')
    isOpenExport.set(true)
  }

  // function closeExport() {
  //   page('/')
  //   isOpenExport.set(false)
  // }

  return (
    <F.Fragment>
      {state.view(x => {
        // console.log('isAuth', x.step, x.user)
        const isAuthorized = x.step === 'ok' && x.user
        const appState = Atom.create<{ state?: Atom<AppState>; error?: string | React.ReactNode }>(
          {}
        )

        return (
          <div className={style.fullWidth}>
            <div id="dialogContainer" />
            <Header
              data-dialog-hide={true}
              className={style.appear}
              onSignOut={async () => {
                tracking.signout()
                if (x.user) await x.user.signOut()
                initUserState()
              }}
              onProfile={() => {
                openProfile()
              }}
              onExport={() => {
                openExport()
              }}
              email={getEmail(x.user)}
            />
            {/* <Export onClose={closeExport} isShow={isOpenExport} /> */}
            {isAuthorized ? (
              <Dashboard_
                isOpenProfile={isOpenProfile}
                closeProfile={closeProfile}
                openProfile={openProfile}
                initUserState={initUserState}
                appState={appState}
                user={x.user!}
              />
            ) : x.step === 'loading' ? (
              <AppLoader />
            ) : (
              <AuthDialog
                onFinish={x => {
                  // console.log(x)
                  state.set({ user: x, step: 'ok' })
                }}
                onSubmit={authApi}
                onResendConfirmation={resendConfirmation}
                step={Atom.create(Step.Signin)}
              />
            )}
          </div>
        )
      })}
    </F.Fragment>
  )
}
