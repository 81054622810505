import React from 'react'
import * as style from './itemBadges.styl'
import { classes } from '@grammarly/focal'
import { SvgIcon, Icons } from './icons'
import { TooltipAnchor, TooltipModel, tooltipState } from './tooltip'
import { OvalLoader } from './loader'

export const PausedBadge = ({
  children,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...classes(style.paused, className)} {...other}>
    <span className={style.pausedLabel}>{children || 'paused'}</span>
  </div>
)

export const ExportBadge = ({
  children,
  className,
  tooltip = tooltipState,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & { tooltip?: TooltipModel }) => (
  <div {...classes(style.exporting, className)} {...other}>
    <TooltipAnchor tooltip={tooltip} content={'Ongoing export job'} align="top">
      <span className={style.exportingLabel}>export</span>{' '}
      <OvalLoader className={style.exportingLoader} isDarkLoader />
    </TooltipAnchor>
  </div>
)

export const ErrorBadge = ({
  children,
  tooltip = tooltipState,
  className,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & { tooltip?: TooltipModel }) => (
  <div {...classes(style.errorBadge, className)} {...other}>
    <TooltipAnchor
      tooltip={tooltip}
      content={'Service is logged out. Click to reauthorize'}
      align="top"
    >
      <SvgIcon icon={Icons.error} />
    </TooltipAnchor>
  </div>
)
