import { Atom, classes } from '@grammarly/focal'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import { searchUsersCached } from '../api'
import { Avatar, AvatarWithTitle } from '../components/avatar'
import { Button, IconButton } from '../components/buttons'
import { Dialog } from '../components/dialog'
import { ChatsForm, ConditionForm, KeywordForm, UsersForm } from '../components/forms/condition'

import { Chooser, TelegramTargetForm, WebhookTargetForm } from '../components/forms/targets'
import { AddTelegram } from '../components/forms/telegram'
import * as telegramStyle from '../components/forms/telegram.styl'
import { ErrorState } from '../components/forms/utils'
import { Icons } from '../components/icons'
import * as layout from '../components/layout.styl'
import { MemberSelect } from '../components/memberSelect'
import { PopupMenu, PopupMenuItem } from '../components/menu'
import { Tooltip, TooltipAnchor, tooltipDefaultState, TooltipModel } from '../components/tooltip'
import { TelegramRegistrationStep as Step } from '../types'
import * as style from './components.styl'

const tooltip = new TooltipModel(Atom.create(tooltipDefaultState))

export const AnimationComponent = () => {
  const transitions = useTransition(true, null, {
    from: { position: 'absolute' as any, opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })
  useEffect(() => {
    console.log('mounted1')
  })
  return (
    <div>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              ✌️
            </animated.div>
          )
      )}
    </div>
  )
}

export const Components2 = () => {
  const [openDialog, setOpenDialog] = useState(false)

  const [menuAnchor2, setMenuAnchor2] = useState<HTMLElement | null>(null)

  const [showAnimation, setShowAnimation] = useState(false)

  return (
    <div className={layout.simple}>
      <div style={{ marginTop: '40px' }} {...classes(style.components, layout.simpleLeft)}>
        <p />
        <div
          style={{
            borderRadius: '5px',
            boxShadow: '0 12px 48px 0 rgba(96,101,123,.24)',
            width: 'fit-content'
          }}
        >
          <WebhookTargetForm errorState={new ErrorState()} />
        </div>
        <p />
        <div
          style={{
            borderRadius: '5px',
            boxShadow: '0 12px 48px 0 rgba(96,101,123,.24)',
            width: 'fit-content'
          }}
        >
          <TelegramTargetForm errorState={new ErrorState()} phone={'+16282029306'} />
        </div>

        <p />
        <div
          style={{
            borderRadius: '5px',
            boxShadow: '0 12px 48px 0 rgba(96,101,123,.24)',
            width: 'fit-content'
          }}
        >
          <Chooser
            filter={{} as any}
            step={Atom.create('chooser') as any}
            onNextStep={() => {
              //
            }}
            onSubmit={async () => {
              return {
                isValid: true
              }
            }}
            phone="+16282029306"
          />
        </div>
        <p />
        <ConditionForm phone="+16282029306" />

        <Button onClick={() => setShowAnimation(!showAnimation)}>Toggle animation</Button>
        {showAnimation && <AnimationComponent />}
        <IconButton ref={setMenuAnchor2} icon={Icons.edit} className={style.menuAnchor} />

        <IconButton
          icon={Icons.add2}
          onClick={() => {
            setOpenDialog(!openDialog)
          }}
        />

        <p />
        <p />
        <Avatar id="48732710" name="Collin Crypto" isBig isUser phone="+16282029306" />
        <p />
        <AvatarWithTitle
          member={{ id: '48732710', firstName: 'Collin Crypto', username: 'collincrypto' }}
          isBig
          isUser
          phone="+16282029306"
        />
        <p />

        <MemberSelect
          phone="+16282029306"
          type="user"
          getData={searchUsersCached}
          debounceTime={400}
          value={[
            { id: '48732710', username: 'collincrypto', firstName: 'Collin Crypto' },
            { id: '326208103', username: 'primo' },
            { id: '100132983', username: 'fran', firstName: 'Fran Franns' },
            { id: '193629252', username: 'HungryJack', firstName: 'Hungry Jack' },
            { id: '95930998', username: 'satoshi' }
          ]}
        />

        {/* <MemberSelect phone="+16282029306" type="chat" getData={getChatsCached} openOnFocus /> */}
        <p />
        <p />
        <Formik
          onSubmit={e => {
            console.log(e)
          }}
          initialValues={{}}
        >
          <>
            <KeywordForm
              isExpandedMediaForm={Atom.create(false)}
              isExpandedExcludeForm={Atom.create(false)}
            />
            {/* <MediaForm isExpanded={Atom.create(true)} /> */}
            <ChatsForm phone="+16282029306" />
            <UsersForm phone="+16282029306" />
          </>
        </Formik>
        <Dialog isShow={openDialog} onClose={() => setOpenDialog(false)}>
          <AddTelegram
            currentService={Atom.create(undefined)}
            plans={Atom.create([])}
            canTrial={Atom.create(true)}
            isWhitelisted={Atom.create(true)}
            onFinish={() => setOpenDialog(false)}
            onCreateFilter={() => {}}
            onSubmit={async (step, values) => {
              await new Promise(r => setTimeout(r, 100))
              if (step === Step.Phone) {
                if (values.phone === '1') {
                  return {
                    phone: 'Not valid phone number',
                    isValid: false,
                    isAuthorized: false
                  }
                }
                if (values.phone === '2') {
                  return {
                    isValid: false,
                    isAuthorized: false,
                    message: 'Something bad happened'
                  }
                }

                if (values.phone === '3') {
                  return {
                    isValid: true,
                    isAuthorized: true
                  }
                }
              }

              if (step === Step.Code) {
                if (values.code === '1') {
                  return {
                    code: 'Not valid code',
                    isValid: false,
                    isAuthorized: true
                  }
                }

                if (values.code === '2') {
                  return {
                    isValid: true,
                    isAuthorized: true
                  }
                }
              }

              if (step === Step.Password) {
                if (values.password === '1') {
                  return {
                    password: 'Not valid password',
                    isValid: false,
                    isAuthorized: false
                  }
                }

                if (values.password === '2') {
                  return {
                    isValid: true,
                    isAuthorized: true
                  }
                }
              }

              return {
                isAuthorized: true,
                isValid: true
              }
            }}
          />
        </Dialog>

        <PopupMenu isShowOnHover={true} anchor={menuAnchor2}>
          <PopupMenuItem icon={Icons.pause}>Pause processing updates</PopupMenuItem>
          <PopupMenuItem icon={Icons.remove}>Delete service with filters</PopupMenuItem>
        </PopupMenu>
        <p />

        <p />
        <Button className={telegramStyle.continue} isLoading={true}>
          Hi I'm loading
        </Button>
        <p />
        <TooltipAnchor tooltip={tooltip} content={'Some cool text'} align="top">
          <div>Element</div>
        </TooltipAnchor>
        <p />
        <p />
        <TooltipAnchor tooltip={tooltip} content={'Some cool text'} align="top">
          <Button
            className="button-alternate"
            onClick={e => {
              console.log('clicked!')
            }}
          >
            Click Me!
          </Button>
        </TooltipAnchor>
        <p />

        <Tooltip model={tooltip} />
        <p />

        <div />
        <div>
          <br />
        </div>
        <span>Some good old</span>
      </div>
    </div>
  )
}
