import React from 'react'
import { PlanType } from '../types'
import * as style from './features.styl'
import { MainLightButton } from './buttons'
import { TooltipAnchor, tooltipState } from './tooltip'

const features = [
  [
    {
      name: 'Telegram forwarding',
      tip: 'When a message is forwarded to another Telegram chat'
    },
    '✓',
    '✓'
  ],
  [
    {
      name: 'Webhook forwarding',
      tip: 'When a message is posted to the webhook entrypoint'
    },
    '-',
    '✓'
  ],
  [
    {
      name: 'Export chats history',
      tip:
        'We run a filter through chats for a given time period. All matched messages go to JSON file which you can later download or get it straight to your AWS S3 bucket.'
    },
    '-',
    '✓'
  ]
]
export const Features = ({ plan }: { plan: PlanType }) => {
  const valueIndex = plan === PlanType.basic ? 0 : 1
  return (
    <div className={style.features}>
      <table>
        <tbody>
          {features.map(
            ([feature, ...value]: [
              {
                name: string
                tip: string
              },
              string,
              string
            ]) => {
              return (
                <tr key={feature.name}>
                  <td className={style.feature}>
                    <div className={style.featureText}>
                      <TooltipAnchor
                        wrapperClassName={style.tooltip}
                        tooltip={tooltipState}
                        content={feature.tip}
                        align={'top'}
                      >
                        <span className={style.featureName}>{feature.name}</span>
                      </TooltipAnchor>
                    </div>
                  </td>
                  <td className={style.featureValue}>{value[valueIndex]}</td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

export const FeaturesChooser = ({
  onSelectPlan,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { onSelectPlan: (x: PlanType) => void }) => {
  return (
    <div {...props} className={style.features}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Basic</th>
            <th>Pro</th>
          </tr>
        </thead>
        <tbody>
          {features.map(([feature, user, pro]: [{ name: string; tip: string }, string, string]) => {
            return (
              <tr key={feature.name}>
                <td className={style.feature}>
                  <div className={style.featureText}>
                    <TooltipAnchor
                      wrapperClassName={style.tooltip}
                      tooltip={tooltipState}
                      content={feature.tip}
                      align={'top'}
                    >
                      <span className={style.featureName}>{feature.name}</span>
                    </TooltipAnchor>
                  </div>
                </td>
                <td className={style.featureValue}>{user}</td>
                <td className={style.featureValue}>{pro}</td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td className={style.featureValue}>
              <MainLightButton
                trackingName="planBasicBtn"
                onClick={() => onSelectPlan(PlanType.basic)}
              >
                Select
              </MainLightButton>
            </td>
            <td className={style.featureValue}>
              <MainLightButton trackingName="planProBtn" onClick={() => onSelectPlan(PlanType.pro)}>
                Select
              </MainLightButton>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
