import * as React from 'react'
import { F, Atom } from '@grammarly/focal'
import { getWithExpiration, setWithExpiration } from '../utils/localStorage'
export const PlaceholderedImage = ({
  src,
  title,
  placeholder,
  className
}: {
  src: string
  title: string
  placeholder: React.ReactNode
  className?: string
}) => {
  const isOk = Atom.create(true)
  const isRetry = Atom.create(false)
  const isBroken = getWithExpiration(src) === 'broken'
  // console.log(src, className)

  return (
    <F.Fragment>
      {Atom.combine(isOk, isRetry, (x, retry) =>
        x && !isBroken ? (
          <img
            className={className}
            src={src}
            title={title}
            alt={title}
            onError={_ => {
              isOk.set(false)
              if (!retry) {
                isRetry.set(true)
              }
              setWithExpiration(src, 'broken', 1000 * 60 * 60 * 24)
            }}
          />
        ) : (
          <F.Fragment>{placeholder}</F.Fragment>
        )
      )}
    </F.Fragment>
  )
}
