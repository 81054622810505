import { classes } from '@grammarly/focal'
import * as React from 'react'

import * as style from './logo.styl'

const logoSvg = require('!file-loader!./filter-logo.svg')

export const Logo = ({ className }: { className: string }) => {
  return (
    <a {...classes(style.logo, className)} href="/">
      <img alt="Filter for Telegram" src={logoSvg} />
    </a>
  )
}
