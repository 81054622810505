export interface ISelectable {
  isSelected: boolean
}

export interface Contact {
  id: string
  firstName?: string
  lastName?: string
  username?: string
}

export interface TelegramAuthValues {
  phone?: string
  code?: string
  password?: string
  planId?: string
}

export interface TelegramAuthValidation extends TelegramAuthValues {
  message?: string
  isValid: boolean
  isAuthorized: boolean
}

export enum TelegramRegistrationStep {
  Phone = 'Phone',
  Code = 'Code',
  Password = 'Password',
  Authorized = 'Authorized'
}

export interface AppConfig {
  users: User[]
}

export interface AppEntry {
  id: string
  entryId: string
}

export interface User extends AppEntry {
  email: string
  state: UserState
  membership: Membership
  telegramServices: Record<string, TelegramService>
  date?: number
  seenWelcome?: boolean
  isWhitelisted?: boolean
  plans: Plan[]
  stripeId?: string
  canTrial: boolean
}

export enum PlanType {
  pro = 'pro',
  basic = 'basic'
}

export enum PlanPeriod {
  year = 'year',
  month = 'month'
}

export interface Plan {
  id: string
  type: PlanType
  start: number
  nextBill: number
  price: number
  period: PlanPeriod
  isCanceled?: boolean
  isTrial?: boolean
  trialEnd?: number
}

export interface PlanWithService {
  id: string
  service?: TelegramService
  plan: Plan
}

export enum UserState {
  disabled = 0,
  enabled = 1
}

export interface Membership {
  type: 'paid' | 'free'
}

/** Services */
export type ServiceTypeUnion = TelegramService | TwitterService

export type ServiceStateTypeUnion = TelegramServiceState | TwitterServiceState

export type ServiceType = 'telegram' | 'twitter'

export type DeleteState = 'deleted' | 'deleting' | 'undo'

export interface Service {
  isAuthorized: boolean
  isSkipLambda?: boolean
  id: string
  name?: string
  filters: Filter[]
  deleteState?: DeleteState
  isPaused?: boolean
  plan?: Plan
}

export interface ServiceState extends AppEntry {
  type: ServiceType
  lastUpdate?: number
}

export interface TelegramServiceState extends ServiceState {
  type: 'telegram'
  chats: TelegramChat[]
}

export interface TwitterServiceState extends ServiceState {
  type: 'twitter'
}

export interface TelegramService extends Service {
  username?: string
  firstName?: string
  lastName?: string
  phone: string
  isPendingCode?: boolean
  isPendingPassword?: boolean
  authError?: string
  isPaused?: boolean
  planId?: string
  plan?: Plan // only for tranformed
}

export interface TwitterService extends Service {
  type: 'twitter'
}

export interface TelegramChat {
  id: number
  lastMessage: number
  lastSyncDate?: number
}
/** end services */

/** Filtering */

export enum MediaSelector {
  photo = 'photo',
  video = 'video',
  url = 'url',
  file = 'file',
  animation = 'animation',
  audio = 'audio',
  voice = 'voice',
  story = 'story',
  sticker = 'sticker',
  contact = 'contact',
  poll = 'poll',
  location = 'location',
  venue = 'venue'
}

export enum MediaDownloadSelector {
  photo = 'photo',
  video = 'video',
  voice = 'voice',
  audio = 'audio',
  story = 'story',
  sticker = 'sticker',
  animation = 'animation',
  file = 'file'
}

export interface Member {
  id: string
  name: string
}

export interface SearchExpression {
  id: string
  values?: string[]
  isWildcard?: boolean
  isRegExp?: boolean
  isIgnoreCase?: boolean
  isWholeWord?: boolean
  valuesExclude?: string[]
  isWildcardExclude?: boolean
  isRegExpExclude?: boolean
  isIgnoreCaseExclude?: boolean
  isWholeWordExclude?: boolean
  isIncludePrivateChats?: boolean
  isIncludeBots?: boolean
  isIncludeMy?: boolean
  isPin?: boolean
  users?: Contact[]
  chats?: Contact[]
  ignoreChats?: Contact[]
  ignoreUsers?: Contact[]
  mediaSelectors?: MediaSelector[]
}

export interface Filter {
  id: string
  label?: string
  isPaused?: boolean
  searchExpressions: SearchExpression[]
  telegramTargets: TelegramTarget[]
  webhookTargets?: WebhookTarget[]
  exportJobs?: ExportJob[]
}

/** Targets */
export type TargetType = 'telegram' | 'webhook' | 'export'
export type TargetTypeUnion = TelegramTarget | WebhookTarget

export interface Target {
  id: string
}

export interface Replacement {
  search: string
  replacement: string
  isRegExp?: boolean
  isCaseSensitive?: boolean
  isWholeWord?: boolean
}

export interface TelegramTarget extends Target {
  name?: string
  chat: number
  isSkipLink: boolean
  isDebounceLink: boolean
  isCopy: boolean
  notify: boolean
  replacements: Replacement[]
}

export interface WebhookTarget extends Target {
  url: string
  secret?: string
  isAsync?: boolean
  bucket?: string | undefined
  mediaSelectors?: MediaDownloadSelector[] | undefined
}

export interface ExportJob extends Target {
  start?: number | undefined
  end?: number | undefined
  downloadType: 'file' | 's3'
  bucket?: string | undefined
  mediaSelectors?: MediaDownloadSelector[] | undefined
  date?: number
  timeTook?: number
  isRunning?: boolean
  isOver?: boolean
  size?: number
  errorMessage?: string
  chatIds: string[]
  currentSnapshot?: number
  processedChats?: number
  currentChatId?: number
  currentChatTitle?: string
  currentDate?: number
  currentChatStartDate?: number
}

/** end targets */

export enum ChartType {
  Private = 'chatTypePrivate',
  Basic = 'chatTypeBasicGroup',
  Super = 'chatTypeSupergroup',
  Secret = 'chatTypeSecret'
}

// export interface MessageWithChatType extends Message {
//   chatType: ChartType
// }

/** Entry that system filters */
export interface SearchEntry {
  id: string
  text: string
  authorId: string
  chatId: string
  chatType: ChartType
  media: MediaSelector[]
}

export interface CompactTelegramMessage {
  id: number
  chatId: number
}

/** Actions */
export type ActionTypeUnion<T extends Target> = TelegramAction<T>
export interface TelegramAction<T extends Target> {
  target: T
  message: CompactTelegramMessage
}

export interface TelegramActionState<T extends Target> extends AppEntry, TelegramAction<T> {
  userId: string
  phone: string
  retries: number
  date: number
}

/** Data providers */
export interface ActionsResult {
  actions: TelegramAction<Target>[]
  state: ServiceStateTypeUnion
}
export interface DataProvider {
  getActions(): Promise<ActionsResult>
}

export interface Setting extends AppEntry {
  value: string
}

// example
// export const mockState: AppConfig = {
//   users: [
//     {
//       id: 'user_0',
//       email: 'some@gmail.com',
//       entryId: '1',
//       state: UserState.enabled,
//       membership: {
//         type: 'paid'
//       },
//       services: [
//         {
//           type: 'telegram',
//           isAuthorized: false,
//           isPendingCode: false,
//           isPendingPassword: false,
//           id: '383434',
//           userId: 21321,
//           phone: '+995599093978',
//           filters: [
//             {
//               /**
//                * Filter messages which contains eth, btc
//                * and post to webhook and telegram channel
//                */

//               searchExpressions: [
//                 { values: ['btc', 'eth'], isIgnoreCase: true, chats: ['12322', '3954954'] }
//               ],
//               targets: [
//                 {
//                   type: 'telegram',
//                   chat: 343545,
//                   notify: true
//                 },
//                 {
//                   type: 'webhook',
//                   url: 'https://127.0.0.1:8080'
//                 }
//               ]
//             },
//             {
//               // any message from users
//               searchExpressions: [{ users: ['2323'], chats: ['12322', '3954954'] }],
//               targets: [
//                 {
//                   type: 'telegram',
//                   chat: 343545,
//                   notify: true
//                 }
//               ]
//             },
//             {
//               searchExpressions: [{ users: ['2323'] }],
//               targets: [
//                 {
//                   type: 'telegram',
//                   chat: 343545,
//                   notify: true
//                 }
//               ]
//             }
//           ]
//         }
//       ]
//     }
//   ]
// }

export function isDisabledService({ service }: { service?: TelegramService }) {
  return service ? service.isPaused || !service.isAuthorized : false
}

export const mockTelegramServiceState: TelegramServiceState = {
  id: '1',
  entryId: '6282029306',
  type: 'telegram',
  chats: [
    {
      id: 23232,
      lastMessage: 3434
    }
  ]
}

// export const testTelegramService: TelegramService = {
//   type: 'telegram',
//   isAuthorized: false,
//   isPendingCode: false,
//   isPendingPassword: false,
//   id: '147227732',
//   userId: 147227732,
//   phone: '+16282029306',
//   filters: [
//     {
//       /**
//        * Filter messages which contains eth, btc
//        * and post to webhook and telegram channel
//        */

//       searchExpressions: [
//         { values: ['bitcoin'], isIgnoreCase: true },
//         { values: ['xtz\\s'], isIgnoreCase: true, isRegExp: true }
//       ],
//       targets: [
//         {
//           type: 'telegram',
//           chat: 343545,
//           notify: true
//         },
//         {
//           type: 'webhook',
//           url: 'https://127.0.0.1:8080'
//         }
//       ]
//     }
//   ]
// }

export const testTelegramServiceState: TelegramServiceState = {
  id: 'partition',
  entryId: '1',
  type: 'telegram',
  chats: [
    {
      id: 179488298,
      lastMessage: 163455172608
    },
    {
      id: 231509100,
      lastMessage: 163313614848
    },
    {
      id: 399768980,
      lastMessage: 163454124032
    },
    {
      id: 689041291,
      lastMessage: 161934737408
    },
    {
      id: -240853726,
      lastMessage: 163435249664
    },
    {
      id: -1001204828785,
      lastMessage: 83692093440
    },
    {
      id: 414222543,
      lastMessage: 163456221184
    },
    {
      id: 410144407,
      lastMessage: 163449929728
    },
    {
      id: -1001231295810,
      lastMessage: 9068085248
    },
    {
      id: -1001257836014,
      lastMessage: 176160768
    },
    {
      id: -1001109432276,
      lastMessage: 14134804480
    },
    {
      id: -1001123102879,
      lastMessage: 93801414656
    },
    {
      id: -1001085370787,
      lastMessage: 7521435648
    },
    {
      id: -1001111528897,
      lastMessage: 64428703744
    },
    {
      id: -1001051507530,
      lastMessage: 2670723072
    },
    {
      id: -1001131766979,
      lastMessage: 1280311296
    },
    {
      id: -1001108617586,
      lastMessage: 124165029888
    },
    {
      id: -1001112752777,
      lastMessage: 502267904
    },
    {
      id: -1001218707667,
      lastMessage: 301989888
    },
    {
      id: -1001145433584,
      lastMessage: 21967667200
    },
    {
      id: -1001065761891,
      lastMessage: 266962206720
    },
    {
      id: -267979007,
      lastMessage: 163387015168
    },
    {
      id: -1001265233757,
      lastMessage: 8780775424
    },
    {
      id: -1001037843059,
      lastMessage: 3501195264
    },
    {
      id: -1001356821559,
      lastMessage: 605028352
    },
    {
      id: -1001379468222,
      lastMessage: 2414870528
    },
    {
      id: -1001248438720,
      lastMessage: 17452498944
    },
    {
      id: -1001219305551,
      lastMessage: 176160768
    },
    {
      id: -1001217601308,
      lastMessage: 85983232
    },
    {
      id: -1001110094710,
      lastMessage: 29086449664
    },
    {
      id: 865999119,
      lastMessage: 162970730496
    },
    {
      id: -343638249,
      lastMessage: 162864824320
    },
    {
      id: 777000,
      lastMessage: 162788278272
    },
    {
      id: 147227732,
      lastMessage: 162666643456
    },
    {
      id: -1001308962946,
      lastMessage: 450887680
    },
    {
      id: -1001384425840,
      lastMessage: 121634816
    },
    {
      id: -1001214281807,
      lastMessage: 515899392
    },
    {
      id: -1001254681327,
      lastMessage: 523239424
    },
    {
      id: -379356380,
      lastMessage: 162084683776
    },
    {
      id: 825588046,
      lastMessage: 161980874752
    },
    {
      id: -1001485199950,
      lastMessage: 4194304
    },
    {
      id: 240044026,
      lastMessage: 161942077440
    },
    {
      id: 102698446,
      lastMessage: 161915863040
    },
    {
      id: -1001006503122,
      lastMessage: 110100480
    },
    {
      id: -1001067941021,
      lastMessage: 110100480
    },
    {
      id: -1001344283944,
      lastMessage: 2069889024
    },
    {
      id: 306437180,
      lastMessage: 161623310336
    },
    {
      id: 334513770,
      lastMessage: 161566687232
    },
    {
      id: -1001094732749,
      lastMessage: 143654912
    },
    {
      id: 752565429,
      lastMessage: 161202831360
    },
    {
      id: 56181931,
      lastMessage: 160464633856
    },
    {
      id: 278152574,
      lastMessage: 160295813120
    },
    {
      id: -1001201579614,
      lastMessage: 659554304
    },
    {
      id: 1282989,
      lastMessage: 160043106304
    },
    {
      id: -1001244390052,
      lastMessage: 1785724928
    },
    {
      id: 25161302,
      lastMessage: 158099046400
    },
    {
      id: 851322456,
      lastMessage: 158000480256
    },
    {
      id: 255884291,
      lastMessage: 157174202368
    },
    {
      id: 389852135,
      lastMessage: 155382185984
    },
    {
      id: 194295841,
      lastMessage: 154081951744
    },
    {
      id: 821278838,
      lastMessage: 153843924992
    },
    {
      id: 743210606,
      lastMessage: 153603801088
    },
    {
      id: -1001218356554,
      lastMessage: 525336576
    },
    {
      id: -1001144529351,
      lastMessage: 177209344
    },
    {
      id: 59011046,
      lastMessage: 150615359488
    },
    {
      id: -357381348,
      lastMessage: 149588803584
    },
    {
      id: -1001322845752,
      lastMessage: 750780416
    },
    {
      id: 326208103,
      lastMessage: 148904083456
    },
    {
      id: 349275581,
      lastMessage: 147923664896
    },
    {
      id: -225196134,
      lastMessage: 147766378496
    },
    {
      id: 680968149,
      lastMessage: 146886623232
    },
    {
      id: 336327327,
      lastMessage: 144935223296
    },
    {
      id: 690686963,
      lastMessage: 141215924224
    },
    {
      id: 777175365,
      lastMessage: 140672761856
    },
    {
      id: 263368184,
      lastMessage: 140584681472
    },
    {
      id: 763960155,
      lastMessage: 136557101056
    },
    {
      id: 196116423,
      lastMessage: 135796883456
    },
    {
      id: 743230874,
      lastMessage: 131766157312
    },
    {
      id: 148629796,
      lastMessage: 131134914560
    },
    {
      id: 363802783,
      lastMessage: 128119209984
    },
    {
      id: 460078591,
      lastMessage: 127725993984
    },
    {
      id: 293656827,
      lastMessage: 127328583680
    },
    {
      id: 345757886,
      lastMessage: 125415981056
    },
    {
      id: 378966251,
      lastMessage: 124261498880
    },
    {
      id: 672358982,
      lastMessage: 122922467328
    },
    {
      id: 93372553,
      lastMessage: 122921418752
    },
    {
      id: -238226497,
      lastMessage: 121010913280
    },
    {
      id: 13105,
      lastMessage: 120544296960
    },
    {
      id: -1001109121056,
      lastMessage: 1334837248
    },
    {
      id: 664173184,
      lastMessage: 119457972224
    },
    {
      id: 66000329,
      lastMessage: 118547808256
    },
    {
      id: 427856511,
      lastMessage: 112576167936
    },
    {
      id: 382697988,
      lastMessage: 111571632128
    },
    {
      id: -1001231244766,
      lastMessage: 848297984
    },
    {
      id: -155262676,
      lastMessage: 108571656192
    },
    {
      id: 48732710,
      lastMessage: 108262326272
    },
    {
      id: 176774263,
      lastMessage: 106616061952
    },
    {
      id: 157843276,
      lastMessage: 103716749312
    },
    {
      id: 278933472,
      lastMessage: 102204702720
    },
    {
      id: 404559474,
      lastMessage: 102134448128
    }
  ]
}
