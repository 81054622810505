import { classes } from '@grammarly/focal'
import { Input } from '../input'
import * as inputStyle from '../input.styl'
import * as style from './bucket.styl'
import React from 'react'
import { IconButton } from '../buttons'
import { Icons } from '../icons'
import { selectText } from '../../utils/dom'
import { TooltipAnchor, tooltipState } from '../tooltip'
import { ExpandPanel } from './expandLink'

export const BucketForm = ({
  children,
  className,
  instructionOnBottom,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & { instructionOnBottom?: boolean }) => (
  <div
    {...classes(style.bucket, instructionOnBottom && style.instructionOnBottom, className)}
    {...other}
  >
    <ExpandPanel
      expandLinkChildren={'How to setup S3 bucket for Filter'}
      tooltip={'Create a bucket and grant permissions for Filter account'}
      className={style.howTo}
      expandIconClassName={style.expandIcon}
    >
      <ul>
        <li>
          <div>
            <a
              target="_blank"
              href="https://docs.aws.amazon.com/AmazonS3/latest/gsg/CreatingABucket.html"
            >
              Create AWS S3 bucket
            </a>{' '}
            preferably in <b>us-east-1</b> region
          </div>
        </li>
        <li>
          <div>
            <a
              target="_blank"
              href="https://docs.aws.amazon.com/AmazonS3/latest/user-guide/set-bucket-permissions.html"
            >
              Grant permissions
            </a>
            : <b>List objects</b> and <b>Write objects</b> for <br />
            <div className={style.idWrap}>
              <i>Canonical{'\u00A0'}ID</i>:{'\u00A0'}
              <span className={style.canonicalId}>
                <span data-canonical-id>
                  a7c81e1dab43ead0fdd3def32b0d10a900b367a5a7afce48062ae7e9adbdcba3
                </span>
                <TooltipAnchor
                  isShowOnClick
                  tooltip={tooltipState}
                  content={'Copied!'}
                  align="bottom"
                  tooltipClassName={style.tooltip}
                >
                  <IconButton
                    onClick={() => {
                      selectText(document.querySelector('[data-canonical-id]'))
                      document.execCommand('copy')
                      window.getSelection()?.removeAllRanges()
                    }}
                    className={style.clipboard}
                    icon={Icons.clippy}
                  />
                </TooltipAnchor>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </ExpandPanel>
    <Input
      containerClass={inputStyle.inputMiddle}
      name="bucket"
      label="Specify S3 bucket name"
      maxLength={300}
      helper={''}
    />

    {children}
  </div>
)
