import { Atom, classes, F, Lens, ReadOnlyAtom } from '@grammarly/focal'
import * as React from 'react'

import { limits } from '../api'
import { getWriteApi } from '../model'
import {
  isDisabledService,
  TelegramService,
  TelegramTarget,
  WebhookTarget,
  Filter,
  ExportJob,
  PlanType
} from '../types'
import { ellipsisInTheMiddle } from '../utils/string'
import { track } from '../utils/tracking'
import { FoldableList } from './animatedList'
import { AddButton } from './buttons'
import { Dialog } from './dialog'
import { EmptyList } from './emptyList'
import {
  Chooser,
  Step,
  TargetResult,
  TelegramTargetValidation,
  WebhookTargetValidation
} from './forms/targets'

import { ExportListItem } from './forms/export'
import * as exportStyle from './forms/export.styl'
import { IconList } from './iconList'
import { Icons } from './icons'
import { ListItem } from './listItem'
import { PopupMenuItem } from './menu'
import { PartType } from './parts'
import * as style from './targets.styl'
import { ShortMedia } from './forms/mediaForm'
import { BillingPortalLink } from './billingPortalLink'

function getTelegramParts(xs: TelegramTarget[]) {
  return xs.map(x => ({
    text:
      x.name ||
      String(x.chat)
        .replace('-', '')
        .slice(0, 2),
    id: String(x.chat),
    type: x.notify ? PartType.chatNotify : PartType.chat
  }))
}

export const Targets = ({
  value,
  isWhitelisted,
  onAddFilter,
  onSubmit,
  onDelete,
  onOpenDialog,
  onCloseDialog,
  onShowDelete,
  onHideDelete
}: {
  isWhitelisted: Atom<boolean | undefined>
  value: ReadOnlyAtom<{
    service: TelegramService
    phone: string
    filterId: string
    filter: Filter
    telegramTargets: TelegramTarget[]
    webhookTargets: WebhookTarget[]
    exportJobs: ExportJob[]
    hasFilters?: boolean
    serviceHasExportJob?: boolean
  }>
  onAddFilter: Function
  onSubmit(
    x: TargetResult,
    isEdit: boolean
  ): Promise<WebhookTargetValidation | TelegramTargetValidation>
  onDelete?(x: TargetResult): void
  onOpenDialog: Function
  onCloseDialog: Function
  onShowDelete(): void
  onHideDelete(): void
}) => {
  interface DialogState {
    isOpen: boolean
    item?: TargetResult
    step: Atom<Step>
  }

  const getStep = (item?: TargetResult | undefined) =>
    Atom.create<Step>(!item ? 'chooser' : item.telegram ? 'telegram' : 'webhook')

  const dialogState = Atom.create<DialogState>({
    isOpen: false,
    step: getStep()
  })

  /*
      if (dialogState.get().item) {
    initialStep = dialogState.get().item!.telegram ? 'telegram' : 'webhook'
  }
  */

  function setDialogState(x: DialogState) {
    dialogState.set(x)
  }

  function closeDialog() {
    setDialogState({ isOpen: false, step: dialogState.get().step })
    onCloseDialog()
  }

  function openDialog(item?: TargetResult | undefined) {
    setDialogState({ isOpen: true, item, step: getStep(item) })
    onOpenDialog()
  }

  const isLoadingItem = Atom.create<Record<string, boolean>>({})
  const isErrorItem = Atom.create<Record<string, { message: string; isShow: boolean }>>({})
  const setError = (id: string, message: string) => {
    isErrorItem.lens(Lens.key(id)).set({ message, isShow: true })
  }

  const getLatestExportJob = (xs: ExportJob[]) => {
    if (!xs) return []
    const running = xs.find(x => x.isRunning)
    if (running) return [running]

    // last finished
    if (xs.length > 0) return [xs[xs.length - 1]]

    return []
  }

  return (
    <>
      <FoldableList
        {...classes(style.targetsWrap)}
        items={value.view(x => [
          ...x.telegramTargets,
          ...x.webhookTargets,
          ...getLatestExportJob(x.exportJobs)
        ])}
        category={value.view(x => x.filterId)}
        emptyClassName={style.empty}
        empty={<EmptyList icon={Icons.forward} label="No actions added" />}
        onItem={item => {
          const asWebhook = item as WebhookTarget
          const asTelegram = item as TelegramTarget
          const asExportJob = item as ExportJob

          const isWebhook = Boolean(asWebhook.url)
          const isExportJob = Boolean(asExportJob.isRunning || asExportJob.isOver)

          const resultItem = {
            ...(isWebhook
              ? { webhook: asWebhook }
              : isExportJob
              ? { export: asExportJob }
              : { telegram: asTelegram }),
            phone: value.get().phone
          }

          const isPro = value.get().service.plan?.type === PlanType.pro || isWhitelisted.get()

          // console.log(resultItem)
          return (
            <ListItem
              trackingName="target"
              key={item.id}
              id={item.id}
              {...classes(
                style.item,
                isWebhook && style.itemUrl,
                isExportJob && style.export,
                isExportJob && asExportJob.isOver && style.exportOver,
                isExportJob && asExportJob.errorMessage && style.exportError
              )}
              onDoubleClick={() => {
                if (isDisabledService(value.get())) return
                if (isExportJob) return
                openDialog(resultItem)
              }}
              isReadOnly={value.view(isDisabledService)}
              selectedClassName={style.selected}
              editButtonClassName={style.editButton}
              menuClassName={style.menu}
              isLoading={isLoadingItem.lens(Lens.key(item.id))}
              isError={isErrorItem.lens(Lens.key(item.id))}
              onDelete={async () => {
                const lbl = isWebhook
                  ? 'deleteWebhookTarget'
                  : isExportJob
                  ? 'deleteExportJob'
                  : 'deleteTelegramTarget'
                track(lbl, 'submit')
                const result = await getWriteApi()[lbl](
                  value.get().phone,
                  value.get().filterId,
                  item.id
                )

                if (!result.validation.isValid) {
                  setError(item.id, result.validation.message || '')
                  track(lbl, 'error', result.validation.message)
                  return false
                }
                if (onDelete) onDelete(resultItem)
                return true
              }}
              onShowDelete={onShowDelete}
              onHideDelete={onHideDelete}
              onAction={action => {
                if (action === 'edit') {
                  openDialog(resultItem)
                }
              }}
              getMenuItems={() => (
                <>
                  {!isExportJob && (
                    <PopupMenuItem action="edit" icon={Icons.edit}>
                      Edit
                    </PopupMenuItem>
                  )}
                  <PopupMenuItem action="delete" icon={Icons.remove}>
                    {isExportJob && asExportJob.isRunning ? 'Cancel and delete' : 'Delete'}
                  </PopupMenuItem>
                </>
              )}
            >
              {isWebhook ? (
                <div {...classes(!isPro && style.disabledWebhook)}>
                  Post to webhook{' '}
                  <span key={asWebhook.url} title={asWebhook.url} className={style.url}>
                    {ellipsisInTheMiddle(asWebhook.url.replace(/https?:\/\//i, ''), 30)}
                  </span>
                  <div className={style.listItemMedia}>
                    {asWebhook.mediaSelectors && asWebhook.mediaSelectors.length > 0 && 'Media: '}
                    <ShortMedia values={asWebhook.mediaSelectors} />
                  </div>
                  {asWebhook.bucket && (
                    <div className={style.listItemBucket}>
                      <span className={style.listItemBucketLabel}>S3 bucket: </span>
                      <span className={exportStyle.listItemBucket}>{asWebhook.bucket}</span>
                    </div>
                  )}
                  {!isPro && (
                    <div className={style.listItemDisabledWebhook}>
                      To use webhooks{' '}
                      <BillingPortalLink showError={err => setError(item.id, err || '')} />.
                    </div>
                  )}
                </div>
              ) : isExportJob ? (
                <ExportListItem
                  phone={value.get().phone}
                  onResume={async () => {
                    const x = await onSubmit(
                      { phone: value.get().phone, export: asExportJob },
                      true
                    )
                    if (!x.isValid) {
                      setError(asExportJob.id, x.message!)
                    }
                  }}
                  filter={value.get().filter}
                  value={asExportJob}
                />
              ) : (
                <div>
                  Forward to Telegram chat
                  {(asTelegram.replacements || []).filter(x => x.search).length > 0 ? (
                    <i> (has transforms) </i>
                  ) : (
                    ' '
                  )}
                  <IconList
                    isUser={true}
                    phone={value.get().phone}
                    value={getTelegramParts([asTelegram])}
                  />
                </div>
              )}
            </ListItem>
          )
        }}
      >
        <F.Fragment>
          {value.view(x => {
            let isDisabled =
              x.telegramTargets.length >= limits.telegramTarget &&
              x.webhookTargets.length >= limits.webhookTarget
            let disabledText = `Canʼt add more targets as limits of ${limits.telegramTarget} telegram targets and ${limits.webhookTarget} webhook targets has been reached. Please, let us know if you need more.`

            if (isDisabledService(x)) {
              isDisabled = true
              disabledText = `Read-only for a non-active service`
            }

            return (
              <AddButton
                trackingName="addTargetBtn"
                className={style.btnAdd}
                onClick={() => {
                  const { hasFilters } = value.get()
                  if (hasFilters) {
                    openDialog()
                    return
                  }
                  if (!hasFilters) {
                    onAddFilter()
                    return
                  }
                }}
                disabled={isDisabled}
                disabledText={disabledText}
                tooltip={'Forward or export messages'}
              >
                Action
              </AddButton>
            )
          })}
        </F.Fragment>
      </FoldableList>
      <F.Fragment>
        {dialogState.view(state => {
          return (
            <Dialog
              dialogModeName="targetsDialog"
              isShow={state.isOpen}
              showCloseButton
              onClose={closeDialog}
            >
              <Chooser
                step={state.step}
                isDisabledTelegram={value.get().telegramTargets.length >= limits.telegramTarget}
                isDisabledWebhook={value.get().webhookTargets.length >= limits.webhookTarget}
                planType={value.get().service.plan?.type}
                filter={value.get().filter}
                serviceHasExportJob={value.get().serviceHasExportJob}
                editItem={state.item}
                phone={value.get().phone}
                onFinish={closeDialog}
                onSubmit={async x => {
                  const result = await onSubmit(x, Boolean(state.item))
                  if (result.isValid) closeDialog()
                  return result
                }}
                onNextStep={() => {
                  /**
                   */
                }}
              />
            </Dialog>
          )
        })}
      </F.Fragment>
    </>
  )
}
