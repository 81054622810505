export const googleClientId =
  '274348658658-r5voobhop596b5ep925qmmlsumdunvsj.apps.googleusercontent.com'

export const isDevelopment = process.env.NODE_ENV === 'development'
export const isMock = process.env.REACT_APP_ENV === 'mock'
export const isForceDisableWhitelisted = false
export const isForceEnableTrial = false

export const enableRegistrations = true

export const apiUrlProd = 'https://api2.filter.sitg.app'

export const imageUrl = (phone: string, x: string) => `${apiUrl}/image/${phone}/${x}`

export const domain = isDevelopment ? 'filter.local' : 'filter.sitg.app'

console.log('ENV', process.env)
export const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : apiUrlProd

export const trackingTokens = {
  mixpanel: '85f25596c6300624524655f8c2f8cfd0',
  google: 'UA-153616301-1'
}

export const stripe = {
  key: isDevelopment ? 'pk_test_U6PAEFY7KiIMZ3ljoD5Ylnrh' : 'pk_live_4iSSDd8YyoM8HxcLvVGiYc1O'
}

export const filesBucketUrl = 'https://filter-files-store.s3.amazonaws.com'
export const getExportFile = (id: string) => `${filesBucketUrl}/export/${id}.json.gz`

export const apiUrls = {
  user: `${apiUrl}/user`,
  getUser: (ts?: number) => `${apiUrls.user}${ts ? `/${ts}` : ''}`,
  chats: (
    phone: string,
    isWritable?: boolean,
    isIncludePrivate?: boolean,
    isIncludeBots?: boolean
  ) =>
    `${apiUrl}/chats/${phone}?p${isWritable ? '&writable' : ''}${
      isIncludePrivate ? '&private' : ''
    }${isIncludeBots ? '&bots' : ''}`,
  contacts: (phone: string, query: string) => `${apiUrl}/contacts/${phone}/${query}`,
  registerTelegramService: (phone?: string) => `${apiUrl}/login/${phone}`,
  deleteTelegramService: (phone: string) => `${apiUrl}/delete/${phone}`,
  toggleTelegramService: (phone: string) => `${apiUrl}/toggle/${phone}`,
  toggleTelegramFilter: (phone: string, filterId: string) =>
    `${apiUrl}/toggleFilter/${phone}/${filterId}`,
  editTelegramFilter: (phone: string, filterId: string) =>
    `${apiUrl}/editFilter/${phone}${filterId ? '/' + filterId : ''}`,
  deleteTelegramFilter: (phone: string, filterId: string) =>
    `${apiUrl}/deleteFilter/${phone}/${filterId}`,

  editTelegramCondition: (phone: string, filterId: string, conditionId?: string) =>
    `${apiUrl}/editCondition/${phone}/${filterId}${conditionId ? '/' + conditionId : ''}`,
  deleteTelegramCondition: (phone: string, filterId: string, conditionId: string) =>
    `${apiUrl}/deleteCondition/${phone}/${filterId}/${conditionId}`,
  editWebhookTarget: (phone: string, filterId: string, targetId?: string) =>
    `${apiUrl}/editWebhookTarget/${phone}/${filterId}${targetId ? '/' + targetId : ''}`,
  editTelegramTarget: (phone: string, filterId: string, targetId?: string) =>
    `${apiUrl}/editTelegramTarget/${phone}/${filterId}${targetId ? '/' + targetId : ''}`,

  deleteTelegramTarget: (phone: string, filterId: string, targetId: string) =>
    `${apiUrl}/deleteTelegramTarget/${phone}/${filterId}/${targetId}`,

  deleteExportJob: (phone: string, filterId: string, id: string) =>
    `${apiUrl}/deleteExportJob/${phone}/${filterId}/${id}`,

  editExportJob: (phone: string, filterId: string, id?: string) =>
    `${apiUrl}/editExportJob/${phone}/${filterId}${id ? '/' + id : ''}`,

  deleteWebhookTarget: (phone: string, filterId: string, targetId: string) =>
    `${apiUrl}/deleteWebhookTarget/${phone}/${filterId}/${targetId}`,
  deleteUser: () => `${apiUrl}/deleteUser`,
  settings: () => `${apiUrl}/settings`,
  stripeSession: () => `${apiUrl}/stripeSession`,
  stripePortal: () => `${apiUrl}/stripePortal`,
  plan: (planId: string, command: 'cancel' | 'renew') => `${apiUrl}/plans/${planId}/${command}`,
  assignPlan: (phone: string, planId: string) => `${apiUrl}/assignPlan/${phone}/${planId}`
}

export const authConfig = {
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain,
    // OPTIONAL - Cookie path
    path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    // secure: !isDevelopment
    secure: true
  }
}

export const allowedIds = [
  'c0effa82-9f02-474b-8e49-a70724de20bd',
  '49eb5d13-bb0c-4109-b355-33822bba1c48'
]
