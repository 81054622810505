import { Atom, classes, F, Lens, ReadOnlyAtom } from '@grammarly/focal'
import * as React from 'react'

import { limitMessage, limits } from '../api'
import { getWriteApi } from '../model'
import { isDisabledService, SearchExpression, TelegramService, Filter } from '../types'
import { scrollElement } from '../utils/dom'
import { track } from '../utils/tracking'
import { FoldableList } from './animatedList'
import * as bracketStyle from './bracket.styl'
import { AddButton } from './buttons'
import * as style from './conditions.styl'
import { Dialog } from './dialog'
import { EmptyList } from './emptyList'
import { ConditionForm } from './forms/condition'
import {
  Chooser,
  Step as ChooserStep,
  TargetResult,
  TelegramTargetValidation,
  WebhookTargetValidation
} from './forms/targets'
import { IconList } from './iconList'
import { Icons } from './icons'
import { ListItem } from './listItem'
import { PopupMenuItem } from './menu'
import { PartType, transform } from './parts'

export const RegExpValue = ({
  text,
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & { text: string }) => {
  const maxValueLength = 40
  return (
    <span
      {...props}
      {...classes(style.regExp, text && text.length > 40 && style.ellipsis, className)}
      title={text}
    >
      <span>{text && text.length > 40 ? text.substr(0, maxValueLength) : text}</span>
    </span>
  )
}

export const Condition = ({
  value,
  phone,
  filterId,
  className,
  selectedId,
  selectedClass,
  isReadOnly,
  isNonSelectable,
  onDoubleClick,
  onDelete,
  onAction,
  onShowDelete,
  onHideDelete,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  value: SearchExpression
  phone: string
  filterId?: string
  isReadOnly?: ReadOnlyAtom<boolean>
  isNonSelectable?: boolean
  selectedId?: string
  selectedClass?: string
  onAction?(action: string): void
  onDelete?(x: SearchExpression): void
  onShowDelete?(): void
  onHideDelete?(): void
}) => {
  const isLoadingItem = Atom.create<Record<string, boolean>>({})
  const isErrorItem = Atom.create<Record<string, { message: string; isShow: boolean }>>({})
  const setError = (id: string, message: string) => {
    isErrorItem.lens(Lens.key(id)).set({ message, isShow: true })
  }

  return (
    <ListItem
      key={value.id}
      id={value.id}
      {...classes(style.condition, className)}
      onDoubleClick={onDoubleClick}
      isReadOnly={isReadOnly}
      isNonSelectable
      selectedClassName={style.selected}
      readOnlyClassName={style.readOnly}
      editButtonClassName={style.editButton}
      isLoading={isLoadingItem.lens(Lens.key(value.id))}
      isError={isErrorItem.lens(Lens.key(value.id))}
      onDelete={async () => {
        track('deleteCondition', 'submit')
        const result = await getWriteApi().deleteTelegramCondition(phone, filterId!, value.id)
        if (!result.validation.isValid) {
          setError(value.id, result.validation.message || '')
          track('deleteCondition', 'error', result.validation.message)
          return false
        }
        if (onDelete) onDelete(value)
        return true
      }}
      onShowDelete={onShowDelete}
      onHideDelete={onHideDelete}
      onAction={onAction}
      trackingName="condition"
      getMenuItems={() => (
        <>
          <PopupMenuItem action="edit" icon={Icons.edit}>
            Edit
          </PopupMenuItem>
          <PopupMenuItem action="delete" icon={Icons.remove}>
            Delete
          </PopupMenuItem>
        </>
      )}
    >
      <ConditionContent
        selectedId={selectedId}
        value={value}
        phone={phone}
        selectedClass={selectedClass}
        {...props}
      />
    </ListItem>
  )
}
export const ConditionContent = ({
  value,
  phone,
  selectedId,
  selectedClass,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  value: SearchExpression
  phone: string
  selectedId?: string
  selectedClass?: string
}) => {
  let parts = transform(value)
  const hasUsers =
    parts.filter(x => x.type === PartType.users && x.items && x.items.length > 0).length > 0
  const hasChats =
    parts.filter(x => x.type === PartType.chats && x.items && x.items.length > 0).length > 0

  if (hasUsers) parts = parts.filter(x => x.type !== PartType.exceptUsers)
  if (hasChats) parts = parts.filter(x => x.type !== PartType.exceptChats)

  const _selectedClass = selectedClass || style.selected
  return (
    <div {...props}>
      {parts.map((x, i) => {
        switch (x.type) {
          case PartType.text:
            return (
              <span key={i} title={x.title} className={style.text}>
                {x.text}
              </span>
            )

          case PartType.values:
            return (
              <span key={i} className={style.values}>
                {x.items &&
                  x.items.map((y, j) =>
                    y.type === PartType.regExp ? (
                      <RegExpValue key={j} text={y.text!} />
                    ) : (
                      <span
                        data-id={y.id}
                        key={j}
                        {...classes(style.value, style.bold, y.id === selectedId && _selectedClass)}
                      >
                        {y.text}
                      </span>
                    )
                  )}
              </span>
            )
          case PartType.boldText:
            return (
              <span
                key={i}
                title={x.title}
                data-id={x.id}
                {...classes(style.bold, x.id === selectedId && _selectedClass)}
              >
                {x.text}
              </span>
            )
          case PartType.regExp:
            return (
              <RegExpValue
                data-id={x.id}
                key={i}
                text={x.text!}
                {...classes(style.bold, x.id === selectedId && _selectedClass)}
              />
            )
          case PartType.users:
            return <IconList data-id={x.id} key={i} isUser={true} phone={phone} value={x.items!} />
          case PartType.exceptUsers:
            return (
              <IconList
                data-id={x.id}
                key={i}
                isUser={true}
                phone={phone}
                value={x.items!}
                isExcept={true}
              />
            )

          case PartType.chats:
            return <IconList data-id={x.id} key={i} isUser={false} phone={phone} value={x.items!} />
          case PartType.exceptChats:
            return (
              <IconList
                data-id={x.id}
                key={i}
                isUser={false}
                phone={phone}
                value={x.items!}
                isExcept={true}
              />
            )

          default:
            throw Error(`Type ${x.type} is not supported`)
        }
      })}
    </div>
  )
}

export const Conditions = ({
  data,
  onAddFilter,
  onNew,
  onTargetSubmit,
  onDelete,
  onEdit,
  onOpenDialog,
  onCloseDialog,
  onShowDelete,
  onHideDelete
}: {
  data: ReadOnlyAtom<{
    service: TelegramService
    conditions: SearchExpression[]
    phone: string
    filterId: string
    filter: Filter
    serviceHasExportJob?: boolean
    hasFilters?: boolean
    hasTargets?: boolean
  }>
  onAddFilter?: Function
  onNew?(x: SearchExpression): void
  onEdit?(x: SearchExpression): void
  onTargetSubmit(x: TargetResult): Promise<WebhookTargetValidation | TelegramTargetValidation>
  onDelete?(x: SearchExpression): void
  onOpenDialog: Function
  onCloseDialog: Function
  onShowDelete(): void
  onHideDelete(): void
}) => {
  const [openDialog, setOpenDialog] = React.useState<{ isOpen: boolean; item?: SearchExpression }>({
    isOpen: false
  })
  const [openTargets] = React.useState(Atom.create(false))

  function setOpenTargets(x: boolean) {
    openTargets.set(x)
  }

  const [chooserStep] = React.useState(Atom.create<ChooserStep>('chooser'))

  function closeDialog() {
    // console.trace('closeDialog')
    scrollElement().scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setOpenDialog({ isOpen: false })
    onCloseDialog()
  }
  function openEdit(item: SearchExpression) {
    setOpenTargets(false)
    setOpenDialog({ isOpen: true, item })
    onOpenDialog()
  }

  const dialogComponent = openTargets.view(openTargets => {
    return openTargets ? (
      <Chooser
        filter={data.get().filter}
        serviceHasExportJob={data.get().serviceHasExportJob}
        step={chooserStep}
        onNextStep={() => {
          /*
           */
        }}
        planType={data.get().service.plan?.type}
        phone={data.get().phone}
        onFinish={closeDialog}
        onSubmit={onTargetSubmit}
      />
    ) : (
      <ConditionForm
        editItem={openDialog.item}
        phone={data.get().phone}
        onFinish={closeDialog}
        onSubmit={async x => {
          const item: SearchExpression = openDialog.item
            ? {
                ...openDialog.item,
                ...x
              }
            : x

          const result = await getWriteApi().editTelegramCondition(
            data.get().phone,
            data.get().filterId,
            item,
            openDialog.item && openDialog.item.id
          )

          if (!result.validation.isValid) {
            return result.validation
          }

          if (openDialog.item && onEdit) {
            onEdit(item)
            closeDialog()
            return result.validation
          } else if (onNew && result.item) {
            onNew(result.item)
          } else {
            console.warn("new item didn't come from server")
          }

          if (!data.get().hasTargets) {
            setOpenTargets(true)
          } else {
            closeDialog()
          }

          return result.validation
        }}
      />
    )
  })

  return (
    <>
      <div {...classes(style.conditions)}>
        <FoldableList
          {...classes(style.conditionsWrap)}
          items={data.view(x => x.conditions || [])}
          category={data.view(x => x.filterId)}
          emptyClassName={style.empty}
          empty={<EmptyList icon={Icons.condition} label="No conditions added" />}
          onItem={(item, i) => (
            <Condition
              key={item.id}
              value={item}
              phone={data.get().phone}
              filterId={data.get().filterId}
              className={style.conditionItem}
              onDelete={onDelete}
              onShowDelete={onShowDelete}
              onHideDelete={onHideDelete}
              isReadOnly={data.view(isDisabledService)}
              onAction={action => {
                if (action === 'edit') {
                  track('editConditionMenu')
                  openEdit(item)
                }
              }}
              onDoubleClick={() => {
                track('editConditionDoubleClick')
                if (isDisabledService(data.get())) return
                openEdit(item)
              }}
            />
          )}
        >
          <div className={bracketStyle.bracket + ' ' + style.bracket} />
          <F.Fragment>
            {data.view(x => {
              let isDisabled = x.conditions.length >= limits.condition
              let disabledText = limitMessage('conditions', limits.condition)

              if (isDisabledService(x)) {
                isDisabled = true
                disabledText = `Read-only for a non-active service`
              }

              return (
                <AddButton
                  trackingName="addConditionBtn"
                  className={style.btnAdd}
                  onClick={() => {
                    setOpenTargets(false)
                    if (data.get().hasFilters) {
                      setOpenDialog({ isOpen: true })
                      onOpenDialog()
                    } else if (onAddFilter) {
                      onAddFilter()
                    }
                  }}
                  disabled={isDisabled}
                  disabledText={disabledText}
                  tooltip={'A combination of filter rules selects messages to forward.'}
                >
                  Rule
                </AddButton>
              )
            })}
          </F.Fragment>
        </FoldableList>
      </div>

      <Dialog
        isShow={openDialog.isOpen}
        isModal
        onClose={closeDialog}
        dialogModeName={'condition'}
        top={1}
        children={dialogComponent}
      />
    </>
  )
}
