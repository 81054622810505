import { classes, Atom, F, ReadOnlyAtom } from '@grammarly/focal'
import React, { useState } from 'react'
import * as style from './calendar.styl'

import { Button } from './buttons'
import { Animated } from './animated'
import { PopupMenuItem, PopupMenu } from './menu'

const day = 1000 * 60 * 60 * 24

function arr(length: number) {
  return new Array(length).fill(0)
}

export function daysInMonth(month: number, year: number) {
  return new Date(year, month + 1, 0).getDate()
}

export function getLabels() {
  const monday = +new Date(2017, 0, 2)

  return {
    months: arr(12).map((_, i) => new Date(2009, i, 1).toLocaleString('en-us', { month: 'long' })),
    days: arr(7).map((_, i) =>
      new Date(monday + day * i).toLocaleString('en-us', { weekday: 'short' })
    )
  }
}

const labels = getLabels()

export function month(date: Date) {
  return date.toLocaleString('en-us', { month: 'long' })
}

export const Calendar = ({
  className,
  selectedDate,
  value,
  maxDate,
  minDate,
  children,
  onValue,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  selectedDate?: Date
  value: ReadOnlyAtom<Date | undefined>
  maxDate: Date
  minDate: Date
  onValue(x: Date): void
}) => {
  const selectedDateState = Atom.create(selectedDate || maxDate)

  return (
    <F.div {...classes(style.calendar, className)} {...other}>
      <CalendarHeader
        maxDate={maxDate}
        minDate={minDate}
        onValue={i => {
          selectedDateState.modify(x => new Date(x.getFullYear(), x.getMonth() + i, 1))
        }}
        selectedDate={selectedDateState}
      />
      <CalendarContent
        maxDate={maxDate}
        minDate={minDate}
        onValue={x => onValue(x)}
        value={value}
        selectedDate={selectedDateState}
      />

      {children}
    </F.div>
  )
}

export const CalendarContent = ({
  className,
  selectedDate,
  value,
  onValue,
  maxDate,
  minDate,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  selectedDate: Atom<Date>
  value: ReadOnlyAtom<Date | undefined>
  maxDate: Date
  minDate: Date
  onValue(x: Date): void
}) => {
  const days = selectedDate.view(date => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const maxDays = daysInMonth(month, year)
    const startDay = new Date(year, month, 1).getDay()
    return (
      <table className={style.days}>
        <thead>
          <tr>
            {labels.days.map((_, i, xs) => (
              <td key={i} className={style.dayHeader}>
                {xs[i === 0 ? 6 : i - 1]}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {arr(5).map((_, row) => (
            <tr key={row}>
              {arr(7).map((_, col) => {
                const cell = row * 7 + col
                const day = cell + 1 - startDay
                const isSelected = value.view(
                  x =>
                    x?.getDate() === day &&
                    x.getFullYear() === year &&
                    x.getMonth() === month &&
                    style.selected
                )

                return (
                  <td className={style.day} key={day}>
                    <F.div className={style.dayWrap}>
                      {cell >= startDay && day <= maxDays
                        ? isSelected.view(x => {
                            const d = new Date(year, month, day).getTime()
                            return (
                              <Button
                                disabled={d > maxDate.getTime() || d < minDate.getTime()}
                                onClick={() => {
                                  onValue(new Date(year, month, day))
                                }}
                                className={x}
                              >
                                {day}
                              </Button>
                            )
                          })
                        : '\u00A0'}
                    </F.div>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  })

  return (
    <div {...classes(style.calendarContent, className)} {...other}>
      <Animated
        children={days}
        prevClassName={style.calendarContent}
        nextClassName={style.calendarContent}
        className={style.calendarContent}
        transitionType="appear"
      />
    </div>
  )
}

export const YearWithPopup = ({
  date,
  onYear,
  maxDate,
  minDate
}: React.HTMLAttributes<HTMLDivElement> & {
  date: Date
  maxDate: Date
  minDate: Date
  onYear(year: number): void
}) => {
  const years = 2
  const maxYear = maxDate.getFullYear()
  const minYear = minDate.getFullYear()
  const currentYear = date.getFullYear()

  // lazy hardcode
  const basicMargin = 16
  const itemHeight = 40

  const xs = arr(years * 2 + 1)
    .map((_, i) => (currentYear > maxYear ? currentYear - i : currentYear + years - i))
    .filter(x => (currentYear < maxYear ? x <= maxYear : x <= currentYear))
    .filter(x => x >= minYear)

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

  return (
    <div ref={setMenuAnchor}>
      {xs.length > 1 && (
        <PopupMenu
          isShowOnHover={true}
          anchor={menuAnchor}
          {...classes(style.menu)}
          style={{ marginTop: -(basicMargin + itemHeight * xs.indexOf(currentYear)) + 'px' }}
        >
          {xs.map(x => {
            return (
              <PopupMenuItem onClick={() => onYear(x)} className={style.menuItem} key={x}>
                {x}
              </PopupMenuItem>
            )
          })}
        </PopupMenu>
      )}
      {date.getFullYear()}
    </div>
  )
}

export const CalendarHeader = ({
  className,
  selectedDate,
  onValue,
  maxDate,
  minDate,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & {
  selectedDate: Atom<Date>
  maxDate: Date
  minDate: Date
  onValue(incrementMonth: number): void
}) => {
  const title = selectedDate.view(date => (
    <div className={style.selectedMonth}>
      <div className={style.month}>{month(date)}</div>
      {'\u00A0'}
      <div className={style.year}>
        <YearWithPopup
          maxDate={maxDate}
          minDate={minDate}
          onYear={year => {
            onValue((year - date.getFullYear()) * 12)
          }}
          date={date}
        />
      </div>
    </div>
  ))

  const slideType = Atom.create<'slideDown' | 'slideUp'>('slideDown')

  return (
    <F.div {...classes(style.calendarHeader, className)} {...other}>
      {selectedDate.view(date => {
        return (
          <Button
            key="btnleft"
            onClick={() => {
              slideType.set('slideUp')
              setTimeout(() => onValue(-1), 0)
            }}
            disabled={
              date.getFullYear() === minDate.getFullYear() && date.getMonth() === minDate.getMonth()
            }
          >
            ←
          </Button>
        )
      })}
      {slideType.view(x => (
        <Animated key={x} transitionType={x} children={title} />
      ))}
      {selectedDate.view(date => {
        return (
          <Button
            key={'btn'}
            onClick={() => {
              slideType.set('slideDown')
              setTimeout(() => onValue(1), 0)
            }}
            disabled={
              date.getFullYear() >= maxDate.getFullYear() && date.getMonth() === maxDate.getMonth()
            }
          >
            →
          </Button>
        )
      })}
    </F.div>
  )
}
