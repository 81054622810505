import { classes } from '@grammarly/focal'
import * as React from 'react'

import { PlanType } from '../types'
import * as style from './planBadge.styl'

export const PlanBadge = ({
  planType,
  children,
  className
}: {
  planType?: PlanType
  className?: string
  children?: React.ReactNode
}) => {
  return (
    <div {...classes(style.planBadge, className, planType && style[`planBadge_${planType}`])}>
      {planType || children}
    </div>
  )
}
