import { Atom, classes, F } from '@grammarly/focal'
import * as React from 'react'

import { Condition, Conditions } from '../components/conditions'
import { Filters } from '../components/filters'
import * as layout from '../components/layout.styl'
import { Services } from '../components/services'
import { Targets } from '../components/targets'
import { TelegramService, User } from '../types'
import { setIds } from '../utils/string'
import * as style from './components.styl'

const testUser: User = {
  id: 'user_0',
  email: 'kigorw@gmail.com',
  canTrial: true,
  plans: [],
  membership: {
    type: 'free'
  },
  telegramServices: {
    '+995599093978': {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+995599093978',
      isPendingPassword: false,
      id: '825588046',
      firstName: 'Small',
      lastName: 'Talk',
      name: 'smalltalkek',
      filters: [
        {
          id: '',
          label: 'BTC from private chats',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: true,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              isIgnoreCase: true,
              values: ['BTC'],
              isIncludePrivateChats: true,
              users: [{ id: '825588046', firstName: 'hehe' }]
            }
          ]
        },
        {
          id: '',
          label: 'BTC and XTZ',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              values: ['BTC', 'XTZ'],
              ignoreChats: [
                { id: '414222543', firstName: 'ping' },
                { id: '410144407', firstName: 'pong' }
              ],
              isIgnoreCase: true
            }
          ]
        },
        {
          id: '',
          label: 'tezos mentions',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              values: ['tezos'],
              ignoreChats: [
                { id: '414222543', firstName: 'ping' },
                { id: '410144407', firstName: 'pong' }
              ],
              isIgnoreCase: true
            }
          ]
        },
        {
          id: '',
          label: 'All star GAM',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -386980623,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              users: [
                { id: '48732710', firstName: 'Collin Crypto' },
                { id: '326208103', firstName: 'Primo' },
                { id: '100132983', firstName: 'Fran' },
                { id: '193629252', firstName: 'HungryJack' },
                { id: '95930998', firstName: 'satoshi' },
                { id: '469876564', firstName: 'Zoran Kole' },
                { id: '293656827', firstName: 'Jamal' },
                { id: '194176879', firstName: 'Catoshi' },
                { id: '349275581', firstName: 'BritneySpiers' },
                { id: '196116423', firstName: 'garnett' },
                { id: '157843276', firstName: 'jmo123' },
                { id: '62354794', firstName: 'PhilCrypto77' },
                { id: '341367123', firstName: 'CryptoDonkey' },
                { id: '45900690', firstName: 'actualadvice' },
                { id: '604507589', firstName: 'Lockholm' },
                { id: '2689121', firstName: 'juaningan' },
                { id: '235198442', firstName: 'scarrff' },
                { id: '277345339', firstName: 'Tarang' },
                { id: '193803294', firstName: 'kuanliao' },
                { id: '71709119', firstName: 'CryptOrca' },
                { id: '176774263', firstName: 'whalepada' },
                { id: '825588046', firstName: 'me test' }
              ]
            }
          ]
        }
      ]
    },
    '+16282029306': {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+16282029306',
      isPendingPassword: false,
      firstName: 'Ask',
      lastName: 'Me',
      name: 'askmek',
      id: '147227732',

      filters: [
        {
          id: '',
          label: 'Links and media',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -335152767,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              mediaSelectors: [
                'photo',
                'video',
                'url',
                'animation',
                'sticker',
                'poll',
                'contact',
                'file'
              ] as any
            },
            {
              id: '',
              values: [
                '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
              ],
              isRegExp: true,
              isIgnoreCase: true
            }
          ]
        },
        {
          id: '',
          label: 'BTC',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: true,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              isIgnoreCase: true,
              values: ['BTC'],
              isIncludePrivateChats: true,
              users: [{ id: '825588046', firstName: 'spar' }]
            }
          ]
        },
        {
          id: '',
          label: 'BTC and XTZ',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: setIds([
            {
              values: ['BTC'],
              ignoreChats: [
                { id: '414222543', firstName: 'ping' },
                { id: '410144407', firstName: 'pong' }
              ],
              isIgnoreCase: true
            },
            {
              values: ['XTZ'],
              ignoreChats: [
                { id: '414222543', firstName: 'ping' },
                { id: '410144407', firstName: 'pong' }
              ],
              isIgnoreCase: false
            }
          ])
        },
        {
          label: 'tezos mentions',
          id: '',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -312320321,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              values: ['tezos'],
              ignoreChats: [
                { id: '414222543', firstName: 'ping' },
                { id: '410144407', firstName: 'pong' }
              ],
              isIgnoreCase: true
            }
          ]
        },
        {
          id: '',
          label: 'All start GAM',
          telegramTargets: [
            {
              id: '',
              replacements: [],
              chat: -386980623,
              notify: false,
              isCopy: false,
              isDebounceLink: false,
              isSkipLink: false
            }
          ],
          searchExpressions: [
            {
              id: '',
              users: [
                { id: '48732710', firstName: '' },
                { id: '326208103', firstName: '' },
                { id: '100132983', firstName: '' },
                { id: '193629252', firstName: '' },
                { id: '95930998', firstName: '' },
                { id: '469876564', firstName: '' },
                { id: '293656827', firstName: '' },
                { id: '194176879', firstName: '' },
                { id: '349275581', firstName: '' },
                { id: '196116423', firstName: '' },
                { id: '157843276', firstName: '' },
                { id: '62354794', firstName: '' },
                { id: '341367123', firstName: '' },
                { id: '45900690', firstName: '' },
                { id: '604507589', firstName: '' },
                { id: '2689121', firstName: '' },
                { id: '235198442', firstName: '' },
                { id: '277345339', firstName: '' },
                { id: '193803294', firstName: '' },
                { id: '71709119', firstName: '' },
                { id: '176774263', firstName: '' },
                { id: '825588046', firstName: '' }
              ]
            }
          ]
        }
      ]
    },
    '+380638439334': {
      isAuthorized: true,
      isPendingCode: false,
      phone: '+380638439334',
      isPendingPassword: false,
      id: '964757184',
      firstName: 'Good',
      lastName: 'Man',
      name: 'goodmanek',
      filters: []
    }
  },
  state: 1,
  entryId: '42bec696-3cd3-4dda-9e64-562150d6b923'
}

export const Components = () => {
  const state = Atom.create({
    selectedService: 0,
    selectedFilter: 0
  })

  return (
    <div className={layout.simple}>
      <div {...classes(style.components, layout.simpleLeft)}>
        <Services
          plans={Atom.create([])}
          isWhitelisted={Atom.create(true)}
          canTrial={Atom.create(true)}
          onSelectPlanForPausedService={() => {}}
          currentPlan={Atom.create(undefined)}
          currentService={Atom.create(undefined)}
          onEdit={x => console.log(x)}
          onNew={x => console.log(x)}
          onChange={() => state.lens('selectedFilter').set(0)}
          data={Atom.create(
            Object.keys(testUser.telegramServices).map(x => testUser.telegramServices[x])
          )}
          selectedItem={state.lens('selectedService')}
          onOpenDialog={() => {
            //
          }}
          onCloseDialog={() => {
            //
          }}
          onShowDelete={() => {
            //
          }}
          onHideDelete={() => {
            //
          }}
        />
        <p />
        <Condition
          phone={'1'}
          value={{
            id: '',
            values: ['tezos']
          }}
        />
        <p />
        <Condition
          phone={'+16282029306'}
          value={{
            id: '',
            values: ['tezos'],
            users: [
              { id: '48732710', firstName: 'Collin Crypto' },
              { id: '326208103', firstName: 'Primo' },
              { id: '100132983', firstName: 'Fran' },
              { id: '193629252', firstName: 'HungryJack' },
              { id: '95930998', firstName: 'satoshi' },
              { id: '469876564', firstName: 'Zoran Kole' },
              { id: '293656827', firstName: 'Jamal' },
              { id: '194176879', firstName: 'Catoshi' },
              { id: '349275581', firstName: 'BritneySpiers' },
              { id: '196116423', firstName: 'garnett' },
              { id: '157843276', firstName: 'jmo123' },
              { id: '62354794', firstName: 'PhilCrypto77' },
              { id: '341367123', firstName: 'CryptoDonkey' },
              { id: '45900690', firstName: 'actualadvice' },
              { id: '604507589', firstName: 'Lockholm' },
              { id: '2689121', firstName: 'juaningan' },
              { id: '235198442', firstName: 'scarrff' },
              { id: '277345339', firstName: 'Tarang' },
              { id: '193803294', firstName: 'kuanliao' },
              { id: '71709119', firstName: 'CryptOrca' },
              { id: '176774263', firstName: 'whalepada' },
              { id: '825588046', firstName: 'me test' }
            ],
            ignoreUsers: [
              { id: '1', firstName: 'hero' },
              { id: '2', firstName: 'lohoho' }
            ],
            chats: [
              { id: '66', firstName: 'hol' },
              { id: '77', firstName: 'haha' }
            ],
            ignoreChats: [
              { id: '11', firstName: 'heha' },
              { id: '22', firstName: 'ghg' }
            ]
          }}
        />
        <p />
        <Condition
          phone={'1'}
          value={{
            id: '',
            values: ['tezos'],
            ignoreUsers: [
              { id: '1', firstName: 'hero' },
              { id: '2', firstName: 'lohoho' }
            ],
            chats: [
              { id: '66', firstName: 'hol' },
              { id: '77', firstName: 'haha' }
            ],
            ignoreChats: [
              { id: '11', firstName: 'heha' },
              { id: '22', firstName: 'ghg' }
            ]
          }}
        />
        <p />
        <Condition
          phone={'1'}
          value={{
            id: '',
            users: [{ id: '1', firstName: 'hhehe' }],
            chats: [
              { id: '66', firstName: 'hol' },
              { id: '77', firstName: 'haha' }
            ]
          }}
        />
        <p />
        <Condition
          phone={'1'}
          value={{
            id: '',
            isWildcard: true
          }}
        />

        <p />
        <Targets
          isWhitelisted={Atom.create(true)}
          onSubmit={async () => {
            return {
              isValid: true
            }
          }}
          onAddFilter={() => {
            //
          }}
          onShowDelete={() => {
            //
          }}
          onHideDelete={() => {
            //
          }}
          value={
            Atom.create({
              service: { isPaused: false } as TelegramService,
              telegramTargets: setIds([
                {
                  chat: 48732710,
                  firstName: 'Colling crypto',
                  notify: true
                },
                {
                  chat: -386980623,
                  firstName: 'All star GAM',
                  notify: false
                }
              ]),
              webhookTargets: setIds([
                { url: 'https://www.google.com/goodHood' },
                { url: 'https://www.google.com/dsjjdsf' },
                { url: 'https://www.kingmaaaa.com/dsjjdsf' }
              ]),
              phone: '+16282029306',
              filterId: ''
            }) as any
          }
          onOpenDialog={() => {
            //
          }}
          onCloseDialog={() => {
            //
          }}
        />
      </div>
      <F.div className={layout.simpleRight}>
        <Filters
          onTargetSubmit={async () => {
            return {
              isValid: true
            }
          }}
          openFilterDialog={Atom.create({ isOpen: false })}
          onNew={() => {
            //
          }}
          onEdit={() => {
            //
          }}
          data={state.view(x => {
            const s = Object.keys(testUser.telegramServices).map(s => testUser.telegramServices[s])[
              x.selectedService
            ]
            return { phone: s.phone, filters: s.filters }
          })}
          selectedItem={state.lens('selectedFilter')}
          onOpenDialog={() => {
            //
          }}
          onCloseDialog={() => {
            //
          }}
          onShowDelete={() => {
            //
          }}
          onHideDelete={() => {
            //
          }}
        />

        <Conditions
          onTargetSubmit={async () => {
            return {
              isValid: true
            }
          }}
          data={
            state.view(x => {
              const service = Object.keys(testUser.telegramServices).map(
                s => testUser.telegramServices[s]
              )[x.selectedService]
              const filter = service.filters[x.selectedFilter]
              return {
                service,
                conditions: filter ? filter.searchExpressions : [],
                phone: '+16282029306',
                filterId: ''
              }
            }) as any
          }
          onOpenDialog={() => {
            //
          }}
          onCloseDialog={() => {
            //
          }}
          onShowDelete={() => {
            //
          }}
          onHideDelete={() => {
            //
          }}
        />
        <Targets
          isWhitelisted={Atom.create(true)}
          onSubmit={async () => {
            return {
              isValid: true
            }
          }}
          onAddFilter={() => {
            //
          }}
          value={
            state.view(x => {
              const service = Object.keys(testUser.telegramServices).map(
                s => testUser.telegramServices[s]
              )[x.selectedService]
              const filter = service.filters[x.selectedFilter]
              return {
                service,
                telegramTargets: filter ? filter.telegramTargets : [],
                webhookTargets: [],
                phone: '+16282029306',
                filterId: ''
              }
            }) as any
          }
          onOpenDialog={() => {
            //
          }}
          onCloseDialog={() => {
            //
          }}
          onShowDelete={() => {
            //
          }}
          onHideDelete={() => {
            //
          }}
        />
      </F.div>
    </div>
  )
}
