import React from 'react'

import { ExportContent } from '../components/forms/export'
import { DialogHeader, DialogContent } from '../components/dialog'
import { Atom } from '@grammarly/focal'
import { RootTooltip } from '../components/tooltip'
import { ErrorState } from '../components/forms/utils'
import { MediaDownloadSelector, ExportJob } from '../types'

export const Components6 = () => {
  const state = Atom.create<ExportJob>({
    id: 'id',
    start: Date.now() - 10 * 24 * 60 * 60 * 1000,
    end: Date.now(),
    downloadType: 's3',
    mediaSelectors: [
      MediaDownloadSelector.photo,
      MediaDownloadSelector.video,
      MediaDownloadSelector.animation,
      MediaDownloadSelector.file
    ],
    chatIds: []
  })

  const error = new ErrorState()
  return (
    <div
      style={{
        padding: '40px',
        margin: '0 auto',
        width: 'fit-content'
      }}
    >
      <div style={{ boxShadow: '0 12px 48px 0 rgba(96,101,123,.24)', paddingTop: '10px' }}>
        <DialogContent style={{ width: '100%' }} isError={error.state}>
          <DialogHeader>Export tool</DialogHeader>
          <ExportContent
            error={error}
            state={state}
            filter={{
              id: '',
              searchExpressions: [{ chats: [{ id: '' }] } as any],
              telegramTargets: [],
              webhookTargets: [],
              exportJobs: [],
              label: 'BTC mentions'
            }}
            onSubmit={(() => {}) as any}
          />
        </DialogContent>
      </div>
      <RootTooltip />
    </div>
  )
}
