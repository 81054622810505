import React, { useState, useEffect } from 'react'
import { Button, IconButton } from '../buttons'
import * as style from './telegram.styl'
import * as buttonsStyle from '../buttons.styl'

import { Formik } from 'formik'
import { classes, F, Atom } from '@grammarly/focal'
import { Icons, SvgIcon } from '../../components/icons'
import { Input } from '../../components/input'
import { DialogHeader, DialogForm, DialogButtons, DialogContent, Dialog } from '../dialog'
import {
  TelegramService,
  TelegramAuthValues,
  TelegramRegistrationStep as Step,
  TelegramAuthValidation,
  Plan,
  PlanWithService
} from '../../types'
import { ErrorState } from './utils'
import { track } from '../../utils/tracking'
import { Membership } from '../../components/membership'
import { PlanChooser } from './planChooser'
import { Animated } from '../../components/animated'
import { PlanBadge } from '../../components/planBadge'
import { errorMessages } from '../../api'

const telegramLogoSvg = require('!file-loader!./telegram-logo.svg')

export const steps = [Step.Phone, Step.Code, Step.Password, Step.Authorized]

function nextStep(x: Step) {
  return steps[steps.indexOf(x) + 1]
}

const initialValues: TelegramAuthValues = { phone: '', password: '', code: '' }

export interface AddTelegramProps {
  editItem?: TelegramService
  onFinish: Function
  nonCancelable?: boolean
  canTrial: Atom<boolean>
  isWhitelisted: Atom<boolean | undefined>
  onCreateFilter: Function
  plans: Atom<PlanWithService[]>
  currentPlan?: Atom<Plan | undefined>
  currentService: Atom<TelegramService | undefined>
  onSubmit(step: Step, values: TelegramAuthValues): Promise<TelegramAuthValidation>
}

export const AddTelegram = ({
  plans,
  editItem,
  onFinish,
  onSubmit,
  onCreateFilter,
  nonCancelable,
  canTrial,
  isWhitelisted,
  currentPlan,
  currentService
}: AddTelegramProps) => {
  const [step, setStep] = useState(Step.Phone)

  const isLastStep = steps.indexOf(step) === steps.length - 1

  function focus(currentStep: Step) {
    return setTimeout(() => {
      const input = document.querySelector<HTMLInputElement>(
        `input[name=${currentStep.toLowerCase()}]`
      )
      // console.log(input)
      if (input) input.focus()
    }, 600)
  }
  let timer: NodeJS.Timer

  useEffect(() => {
    // console.log('mounted')
    timer = focus(step)
    return () => {
      // console.log('unmounted')
      if (timer) clearTimeout(timer)
    }
  })
  const error = new ErrorState()
  const calculatedPlan =
    plans.get().filter(x => !x.service).length === 1
      ? plans.get()[0].plan
      : editItem
      ? editItem.plan
      : undefined

  const currentPlan_ = currentPlan ? currentPlan : Atom.create(calculatedPlan)

  // synchronize
  if (!currentPlan_.get()) currentPlan_.set(calculatedPlan)

  // console.log('currentPlan_', currentPlan, currentPlan_.get())

  // if (!currentPlan_.get()) {
  //   console.log(plans.get(), plans.get().filter(x => !x.service).length === 1)
  // }
  const _editItem = editItem ? { ...editItem, phone: editItem.phone.replace('+', '') } : undefined

  const telegramContent = (
    <DialogContent isError={error.state} className={style.telegramForm}>
      <F.Fragment>
        {isWhitelisted.view(
          whitelisted =>
            !whitelisted &&
            step === Step.Phone &&
            !document.location.pathname.includes('/profile') && (
              <Button
                iconClassName={style.backIcon}
                className={style.buttonBack}
                icon={Icons.arrowLeft}
                onClick={() => {
                  currentPlan_.set(undefined)
                }}
              >
                Plans
              </Button>
            )
        )}
      </F.Fragment>
      <DialogHeader className={style.header}>
        <div className={style.iconWrapper}>
          <img src={telegramLogoSvg} className={style.telegramIcon} />
          {isLastStep && <SvgIcon className={style.doneIcon} icon={Icons.done2} />}
        </div>
        <div className={style.titleWrap}>
          {isLastStep ? 'Telegram is connected!' : 'Connect Telegram'}
          <F.Fragment>
            {currentPlan_.view(
              plan => plan && <PlanBadge className={style.titleBadge} planType={plan.type} />
            )}
          </F.Fragment>
        </div>
      </DialogHeader>
      <Formik
        initialValues={_editItem || initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const result = await onSubmit(step, values)
          setSubmitting(false)
          if (result.isValid && result.isAuthorized) {
            setStep(Step.Authorized)
            track('serviceForm', 'success')
            return
          }

          if (result.isValid) {
            const next = nextStep(step)
            setStep(next)
            focus(next)
          } else {
            if (result.code) {
              if (step === Step.Phone) {
                delete result.code
              }
              setStep(Step.Code)
            } else if (result.password) {
              if (step === Step.Code) {
                delete result.password
              }
              setStep(Step.Password)
            } else if (result.phone) {
              setStep(Step.Phone)
            }
            track('serviceForm', 'error', result.message)
            setErrors(result)
            error.show(result.message)
            if (result.message === errorMessages.IS_NOT_ELIGIBLE_FOR_TRIAL) {
              setTimeout(() => onFinish(), 1000)
            }
          }
        }}
        validate={() => {
          error.hide()
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <DialogForm {...classes(style.step, style[step.toLowerCase()])}>
              {isLastStep ? (
                <Authorized />
              ) : (
                <>
                  <Input
                    name="phone"
                    label="Phone number"
                    prefixLabel="+"
                    type="number"
                    title=""
                    helper="example: +1 628 123 3210"
                    helperClass={style.phoneInputHelper}
                    disabled={step !== Step.Phone}
                    button={
                      <IconButton
                        trackingName="editPhoneBtn"
                        className={style.btnEditPhone}
                        icon={Icons.edit}
                        onClick={() => {
                          setStep(Step.Phone)
                          focus(Step.Phone)
                        }}
                      />
                    }
                  />

                  <div className={style.slideRow}>
                    <div className={style.slideRowWrap}>
                      <Input
                        name="code"
                        label="Auth code"
                        helper="You should receive a code on your device"
                        helperClass={style.codeInputHelper}
                        title=""
                        disabled={step !== Step.Code}
                      />
                      <Input
                        name="password"
                        label="Password"
                        type="password"
                        containerClass={style.passwordInput}
                        title=""
                        disabled={step !== Step.Password}
                      />
                    </div>
                  </div>
                </>
              )}
            </DialogForm>
            <DialogButtons>
              {(!nonCancelable || isLastStep) && (
                <Button
                  trackingName={`${isLastStep ? 'Finish' : 'Cancel'}_serviceBtn`}
                  onClick={() => onFinish()}
                  className={style.cancel}
                >
                  {isLastStep ? 'Finish' : 'Cancel'}
                </Button>
              )}
              <Button
                trackingName={`submitServiceBtn`}
                className={buttonsStyle.blueButton}
                isLoading={isSubmitting}
                onClick={() => {
                  if (!isLastStep) {
                    handleSubmit()
                  } else {
                    onCreateFilter()
                  }
                }}
              >
                {isLastStep ? 'Create filter' : 'Continue'}
              </Button>
            </DialogButtons>
          </>
        )}
      </Formik>
    </DialogContent>
  )

  return (
    <Animated
      transitionType="appear"
      children={Atom.combine(
        currentPlan_,
        isWhitelisted,
        currentService,
        (plan, whitelisted, currentService) =>
          plan || whitelisted || currentService ? (
            telegramContent
          ) : (
            <PlanSelector
              onClose={onFinish}
              canTrial={canTrial}
              currentPlan={currentPlan_}
              plans={plans}
            />
          )
      )}
    ></Animated>
  )
}

export const PlanSelectorDialog = ({
  isOpen,
  plans,
  currentPlan,
  canTrial,
  onSelect,
  onClose
}: {
  isOpen: Atom<boolean>
  onSelect: (x: Plan) => void
  plans: Atom<PlanWithService[]>
  currentPlan: Atom<Plan | undefined>
  canTrial: Atom<boolean>
  onClose: Function
}) => {
  return (
    <F.Fragment>
      {isOpen.view(_isOpen => {
        return (
          <Dialog
            dialogModeName="PlanSelectorDialog"
            isShow={_isOpen}
            showCloseButton
            onClose={() => onClose()}
          >
            <PlanSelector
              onClose={onClose}
              onSelect={onSelect}
              canTrial={canTrial}
              currentPlan={currentPlan}
              plans={plans}
            />
          </Dialog>
        )
      })}
    </F.Fragment>
  )
}

/**  */

// if have plans show membership else show chooser

export const PlanSelector = ({
  plans,
  currentPlan,
  canTrial,
  onClose,
  onSelect
}: {
  plans: Atom<PlanWithService[]>
  currentPlan: Atom<Plan | undefined>
  canTrial: Atom<boolean>
  onSelect?(x: Plan): void
  onClose: Function
}) => {
  const count = plans.get().filter(x => !x.service).length
  const isOpenChooser = Atom.create(false)

  return (
    <Animated
      children={isOpenChooser.view(isOpen =>
        count === 0 || isOpen ? (
          <PlanChooser
            hasPlans={plans.get().filter(x => !x.service).length > 0}
            step={Atom.create('chooser')}
            onClose={onClose}
            canTrial={canTrial.get()}
            onRootBack={() => isOpenChooser.set(false)}
          ></PlanChooser>
        ) : (
          <DialogContent className={style.planChooser}>
            <DialogHeader className={style.chooserHeader}>Select plan</DialogHeader>
            <div className={style.plansWrap}>
              <Membership
                isChooser
                onOpenChooser={() => {
                  isOpenChooser.set(true)
                }}
                value={plans}
                canTrial={canTrial}
                onSelect={x => {
                  console.log('selected x', x)
                  currentPlan.set(x)
                  onSelect && onSelect(x)
                }}
              ></Membership>
            </div>
            <DialogButtons>
              <Button
                trackingName="choosePlanCancel"
                onClick={() => {
                  onClose()
                }}
              >
                Cancel
              </Button>
            </DialogButtons>
          </DialogContent>
        )
      )}
      transitionType="appear"
    />
  )
}
export const Authorized = () => {
  return (
    <div className={style.authorized}>
      Congratulations! You have just connected your Telegram account. Now let's create your first
      filter.
    </div>
  )
}
