import * as React from 'react'

import { Console } from './console'
import { Components } from './components'
import { Components2 } from './components2'
import { Components3 } from './components3'
import { Components4 } from './components4'
import { Components5 } from './components5'
import { Components6 } from './components6'
import { Components7 } from './components7'
import page from 'page'
import '../index.scss'
import { Atom, F } from '@grammarly/focal'
import { pageParams } from '../model'
import { Hub } from '@aws-amplify/core'
import { GA } from '../components/ga'

export const Index = () => {
  const routes = [
    { route: '/components', component: Components },
    { route: '/components2', component: Components2 },
    { route: '/components3', component: Components3 },
    { route: '/components4', component: Components4 },
    { route: '/components5', component: Components5 },
    { route: '/components6', component: Components6 },
    { route: '/components7', component: Components7 },
    { route: '/profile', component: Console },
    { route: '/connect', component: Console },
    { route: '/:phone?/:filter?', component: Console }
  ]

  const state = Atom.create<{ route: string; component: React.ComponentClass }>(
    routes[routes.length - 1] as any
  )

  Hub.listen('auth', (data: any) => {
    // console.log('hub', data)
  })

  routes.forEach(x =>
    page(x.route, ctx => {
      if (state.get().route !== x.route) state.set(x as any)
      // console.log('heheh', ctx, ctx.params)
      pageParams.set(ctx.params || {})
    })
  )
  page.start({ hashbang: false })

  return (
    <div>
      <F.Fragment key="page">{state.view(x => React.createElement(x.component))}</F.Fragment>
      <GA key={'ga'} />
    </div>
  )
}
