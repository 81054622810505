import React from 'react'

import { ProfileContent } from '../components/profile'
import { Atom } from '@grammarly/focal'
import { dummyPlans } from './components7'
import { RootTooltip } from '../components/tooltip'

export const Components5 = () => {
  return (
    <div>
      <ProfileContent
        isWhitelisted={Atom.create(true)}
        canTrial={Atom.create(true)}
        plans={Atom.create(dummyPlans)}
        onConnectService={() => {}}
        onDeleteUser={() => {
          //
        }}
        is2FAEnabled={Atom.create(false)}
        email="kigorw@gmail.com"
      />
      <br />
      <ProfileContent
        isWhitelisted={Atom.create(true)}
        canTrial={Atom.create(true)}
        plans={Atom.create(dummyPlans)}
        onConnectService={() => {}}
        onDeleteUser={() => {
          //
        }}
        is2FAEnabled={Atom.create(true)}
        email="kigorw@gmail.com"
      />
      <RootTooltip />
    </div>
  )
}
