import * as React from 'react'
import * as style from './footer.styl'

const twitterSvg = require('!file-loader!./twitter.svg')
const telegramSvg = require('!file-loader!./telegram.svg')

export const Footer = () => {
  return (
    <footer className={style.footer} data-dialog-hide={true} data-dialog-hide-force={true}>
      <div className={style.social}>
        <a href="https://twitter.com/sitgapp">
          <img src={twitterSvg} />
        </a>
        <a href="https://t.me/sitgapp">
          <img src={telegramSvg} />
        </a>
      </div>
    </footer>
  )
}
