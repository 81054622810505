import * as React from 'react'
import { F, Atom } from '@grammarly/focal'
import { TooltipAlignment, ShowTooltipModel, TooltipModel, tooltipDefaultState } from './model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { createPortal } from 'react-dom'
import { Tooltip } from './tooltip'

export { Tooltip } from './tooltip'
export { TooltipModel, tooltipDefaultState }

export function isParent(parent: Node, el: Node): boolean {
  if (!el) return false
  while (el.parentNode) {
    if (parent === el.parentNode) return true
    el = el.parentNode
  }
  return false
}

export type StringlyTooltipAlignment = 'top' | 'bottom'

function normalizeAlignment(alignment: StringlyTooltipAlignment | TooltipAlignment) {
  if (typeof alignment === 'string') {
    return alignment === 'top'
      ? TooltipAlignment.top
      : alignment === 'bottom'
      ? TooltipAlignment.bottom
      : (() => {
          throw new TypeError(`Unknown tooltip alignment ${alignment}`)
        })()
  } else {
    return alignment as TooltipAlignment
  }
}

export interface TooltipAnchorProps {
  content: string | Observable<string>
  align?: StringlyTooltipAlignment | TooltipAlignment
  isShowOnClick?: boolean
  tooltip: ShowTooltipModel
  wrapperClassName?: string
  tooltipClassName?: string
  children: React.ReactNode
}

export const TooltipAnchor = ({
  content,
  align,
  tooltip,
  isShowOnClick,
  wrapperClassName,
  tooltipClassName,
  children
}: TooltipAnchorProps) => {
  let el: HTMLElement
  let positionInterval = 0
  let mouseX = 0
  let mouseY = 0

  let isSubscribed = false
  function listenChanges(targetEl: HTMLElement) {
    if (isSubscribed) return
    window.addEventListener('mousemove', onMouseMove)

    positionInterval = window.setInterval(() => {
      const el = document.elementFromPoint(mouseX, mouseY)
      if (el !== targetEl && !isParent(targetEl, el!)) {
        hide()
      }
    }, 200)

    isSubscribed = true
  }

  function unlistenChanges() {
    window.clearInterval(positionInterval)
    window.removeEventListener('mousemove', onMouseMove)
  }

  const hide = () => {
    tooltip.hide()
    unlistenChanges()
  }

  const onMouseMove = (e: MouseEvent) => {
    mouseX = e.clientX
    mouseY = e.clientY
  }

  React.useEffect(() => {
    return () => {
      hide()
    }
  })

  const pureTooltip = (content: string) => {
    const show = () => {
      listenChanges(el)
      tooltip.show(
        el.getBoundingClientRect(),
        content,
        normalizeAlignment(align || 'bottom'),
        tooltipClassName
      )
    }

    return (
      <span
        ref={_el => (el = _el!)}
        className={wrapperClassName}
        onMouseEnter={_ => {
          if (isShowOnClick) return listenChanges(el)
          show()
        }}
        onMouseLeave={hide}
        onClick={() => {
          if (isShowOnClick) {
            show()
          }
        }}
      >
        {children}
      </span>
    )
  }

  return typeof content === 'string' ? (
    pureTooltip(content)
  ) : (
    <F.span>{content.pipe(map(pureTooltip))}</F.span>
  )
}

export const tooltipState = new TooltipModel(Atom.create(tooltipDefaultState))

export const RootTooltip = () => {
  return <>{createPortal(<Tooltip model={tooltipState} />, document.body)}</>
}
