import { errorMessages } from './api'
import { FormResult, Step, AuthValidation } from './components/forms/auth'
import Auth, { CognitoUser } from '@aws-amplify/auth'
import { isValidEmail } from './utils/string'

const empty = 'Should not be empty'

let lastRefresh = 0
export async function refreshIdToken() {
  if (Date.now() - lastRefresh < 30 * 60 * 1000) return
  lastRefresh = Date.now()
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.signInUserSession
    cognitoUser.refreshSession(currentSession.refreshToken, (e: any, session: any) => {
      if (e) {
        console.warn('error refreshing session', e)
      } else {
        console.log('got new session', session)
      }
    })
  } catch (e) {
    console.warn('error refreshing session', e)
  }
}

function validatePassword(password: string) {
  if (password.includes(' ')) {
    return {
      isValid: false,
      password: 'No spaces allowed'
    }
  }
  if (password.length < 8) {
    return {
      isValid: false,
      password: 'Minimal length should be >= 8'
    }
  }
  return
}

export async function authApi(x: FormResult) {
  // console.log('auth form', x)
  const result: AuthValidation = {
    isValid: true,
    username: '',
    password: '',
    code: '',
    message: ''
  }

  if ([Step.Signin, Step.Signup, Step.Reset].includes(x.step) && !x.username) {
    return {
      isValid: false,
      username: empty
    }
  }

  if ([Step.Signin, Step.Signup].includes(x.step) && !x.password.trim()) {
    return {
      isValid: false,
      password: empty
    }
  }

  if ([Step.Signin, Step.Reset].includes(x.step) && !isValidEmail(x.username)) {
    return {
      isValid: false,
      username: 'Invalid email'
    }
  }

  if ([Step.Signup, Step.ResetNew].includes(x.step)) {
    const validationResult = validatePassword(x.password)
    if (validationResult) return validationResult
  }

  if (x.step === Step.Signin) {
    return {
      ...result,
      ...(await signin(x.username, x.password))
    }
  }

  if (x.step === Step.Signin2FA) {
    if (!x.user) {
      return {
        isValid: false,
        message: 'Pleaase refresh and login again'
      }
    }
    return {
      ...result,
      ...(await confirm2FA(x.user, x.code))
    }
  }

  if (x.step === Step.Signup) {
    return {
      ...result,
      ...(await signup(x.username, x.password))
    }
  }

  if (x.step === Step.Confirm) {
    return {
      ...result,
      ...(await confirm(x.username, x.password, x.code))
    }
  }

  if (x.step === Step.Reset) {
    return {
      ...result,
      ...(await reset(x.username))
    }
  }

  if (x.step === Step.ResetNew) {
    return {
      ...result,
      ...(await completeReset(x.username, x.password, x.code))
    }
  }

  return result
}

export async function getTOTPCode() {
  const user = await Auth.currentAuthenticatedUser()
  return await Auth.setupTOTP(user)
}

export async function disableTOTP() {
  const user = await Auth.currentAuthenticatedUser()
  return await Auth.setPreferredMFA(user, 'NOMFA')
}

export async function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<{
  isValid: boolean
  oldPassword?: string
  newPassword?: string
  message?: string
}> {
  // if (1 * 1 > 0) {
  //   return {
  //     isValid: true
  //   }
  // }
  const newPassValidation = validatePassword(newPassword)
  if (newPassValidation)
    return {
      isValid: false,
      newPassword: newPassValidation.password
    }

  const oldPassValidation = validatePassword(newPassword)
  if (oldPassValidation)
    return {
      isValid: false,
      oldPassword: oldPassValidation.password
    }

  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
    return {
      isValid: true
    }
  } catch (e) {
    console.log(e)

    if (e.code === 'NotAuthorizedException') {
      return {
        isValid: false,
        oldPassword: 'Invalid password'
      }
    }

    if (e.message.toLowerCase().includes('password')) {
      return {
        isValid: false,
        newPassword: 'Invalid password'
      }
    }

    return {
      isValid: false,
      message: errorMessages.ERROR
    }
  }
}

export async function confirm(username: string, password: string, code: string) {
  try {
    await Auth.confirmSignUp(username, code)
    return signin(username, password)
  } catch (e) {
    if (e.code === 'CodeMismatchException') {
      return {
        isValid: false,
        code: 'Invalid code'
      }
    }

    return {
      isValid: false,
      error: errorMessages.ERROR
    }
  }
}

export async function deleteCognitoUser(): Promise<{ isValid: boolean; message: string }> {
  const user: CognitoUser = await Auth.currentAuthenticatedUser()
  return new Promise((resolve, reject) =>
    user.deleteUser((e, result) => {
      if (e) {
        console.log('deleteCognitoUser', e)
        reject({ isValid: false, message: e.message })
      } else {
        resolve({ isValid: true, message: '' })
      }
    })
  )
}

export async function hasTOTP() {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser()
    return (await Auth.setPreferredMFA(user, 'TOTP')) === 'SUCCESS'
  } catch (e) {
    return false
  }
}

export async function setupTOTP(
  code: string
): Promise<{
  isValid: boolean
  code?: string
  message?: string
}> {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser()
    // console.log(code)
    await Auth.verifyTotpToken(user, code.toString())
    await Auth.setPreferredMFA(user, 'TOTP')

    return {
      isValid: true
    }
  } catch (e) {
    console.log(e)

    if (
      e.code === 'InvalidParameterException' ||
      (e.message && e.message.includes('Code mismatch'))
    ) {
      return {
        isValid: false,
        code: 'Invalid value'
      }
    }

    // if (e.message.toLowerCase().includes('password')) {
    //   return {
    //     isValid: false,
    //     newPassword: 'Invalid password'
    //   }
    // }

    return {
      isValid: false,
      message: errorMessages.ERROR
    }
  }
}

export function getEmail(x?: CognitoUser): string | undefined {
  const _user = x as any
  return _user && _user.attributes && _user.attributes.email
}

export async function confirm2FA(user: CognitoUser, code: string) {
  try {
    await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
    return {
      isValid: true,
      item: await Auth.currentAuthenticatedUser()
    }
  } catch (e) {
    if (e.code === 'CodeMismatchException') {
      return {
        item: user,
        isValid: false,
        code: 'Invalid code'
      }
    }

    return {
      isValid: false,
      item: user,
      error: errorMessages.ERROR
    }
  }
}

export async function resendConfirmation(username: string) {
  try {
    await Auth.resendSignUp(username)
    // console.log(x)
  } catch (e) {
    console.log(e)
  }
}

export async function reset(username: string) {
  try {
    await Auth.forgotPassword(username)
    // console.log(x)
    return {
      isValid: false,
      step: Step.ResetNew
    }
  } catch (e) {
    console.log(e)

    if (e.code === 'UserNotFoundException') {
      return {
        isValid: false,
        message: 'User does not exist.'
      }
    }

    if (e.code === 'LimitExceededException') {
      return {
        isValid: false,
        message: 'Attempt limit exceeded, please try after some time.'
      }
    }

    return {
      isValid: false,
      error: errorMessages.ERROR
    }
  }
}

export async function completeReset(username: string, password: string, code: string) {
  try {
    await Auth.forgotPasswordSubmit(username, code, password)
    return signin(username, password)
  } catch (e) {
    if (e.code === 'UserNotFoundException') {
      return {
        isValid: false,
        message: 'User does not exist.'
      }
    }

    if (e.code === 'CodeMismatchException') {
      return {
        isValid: false,
        code: 'Invalid code'
      }
    }

    if (e.message.toLowerCase().includes('password')) {
      return {
        isValid: false,
        password: 'Invalid password'
      }
    }

    return {
      isValid: false,
      message: errorMessages.ERROR
    }
  }
}

async function signin(username: string, password: string) {
  try {
    const item = await Auth.signIn({ username, password })
    if (item.challengeName === 'SOFTWARE_TOKEN_MFA') {
      return {
        isValid: false,
        step: Step.Signin2FA,
        item
      }
    }

    return {
      isValid: true,
      item
    }
  } catch (e) {
    console.log('error', e)
    if (e.code === 'NotAuthorizedException') {
      return {
        isValid: false,
        message: 'Incorrect username or password.'
      }
    }

    if (e.code === 'UserNotFoundException') {
      return {
        isValid: false,
        message: 'User does not exist.'
      }
    }

    if (e.code === 'UserNotConfirmedException') {
      return {
        isValid: false,
        step: Step.Confirm
      }
    }

    return {
      isValid: false,
      message: errorMessages.ERROR
    }
  }
}

async function signup(username: string, password: string) {
  try {
    const item = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username
      }
    })
    // console.log(item)
    return {
      isValid: false,
      step: Step.Confirm,
      item
    }
  } catch (e) {
    console.log('error', e)
    if (e.code === 'UsernameExistsException') {
      return {
        isValid: false,
        message: 'An account with the given email already exists.'
      }
    }

    if (e.code === 'InvalidParameterException' && e.message.toLowerCase().includes('email')) {
      return {
        isValid: false,
        username: 'Invalid email'
      }
    }

    if (e.code === 'InvalidParameterException' && e.message.toLowerCase().includes('password')) {
      return {
        isValid: false,
        password: 'Invalid password'
      }
    }

    return {
      isValid: false,
      message: errorMessages.ERROR
    }
  }
}
