import React, { useState } from 'react'

import { classes, Atom } from '@grammarly/focal'
import * as layout from '../components/layout.styl'
import * as style from './components.styl'
import { Delete } from '../components/delete'
import { Icons } from '../components/icons'
import { IconButton, Button } from '../components/buttons'
import { FoldableList } from '../components/animatedList'
import { AuthDialog, Step } from '../components/forms/auth'
import { authApi, resendConfirmation } from '../authApi'

export const Components3 = () => {
  const [isDelete, setDelete] = useState(true)
  const _items = [{ id: '1' }, { id: '2' }, { id: '3' }]
  const items = Atom.create(_items)

  return (
    <div className={layout.simple}>
      <div style={{ marginTop: '40px' }} {...classes(style.components, layout.simpleLeft)}>
        <AuthDialog
          onFinish={x => {
            console.log(x)
            //
          }}
          onSubmit={authApi}
          onResendConfirmation={resendConfirmation}
          step={Atom.create(Step.Signin)}
        />

        <Button
          onClick={() =>
            items.modify(x => [...x, { id: String(parseInt(x[x.length - 1].id, 10) + 1) }])
          }
        >
          Add item
        </Button>

        <Button
          onClick={() =>
            items.modify(x => {
              return [...x.slice(1)]
            })
          }
        >
          Remove item
        </Button>
        <FoldableList
          items={items}
          onItem={item => {
            return <div key={item.id}>{item.id}</div>
          }}
        />
        <p />
        <p />
        <div
          style={{
            width: '200px',
            height: '100px',
            border: '1px solid gray',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <IconButton
            icon={Icons.remove}
            onClick={() => {
              setDelete(true)
            }}
          />

          <Delete
            isShow={isDelete}
            onUndo={() => setDelete(false)}
            onClose={() => {
              /**/
            }}
          />
        </div>
      </div>
    </div>
  )
}
