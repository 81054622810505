const cssNumber: { [k: string]: number | undefined } = {
  'column-count': 1,
  columns: 1,
  'font-weight': 1,
  'line-height': 1,
  opacity: 1,
  'z-index': 1,
  zoom: 1
}

export function scrollElement() {
  return document.scrollingElement || document.documentElement
}

export function maybeAddPx(name: string, value: number | string) {
  if (name.includes('--')) return value
  return typeof value === 'number' && !cssNumber[dasherize(name)] ? value + 'px' : value
}

export function camelize(str: string) {
  return str.replace(/-+(.)?/g, (_, chr) => (chr ? chr.toUpperCase() : ''))
}

export function dasherize(str: string) {
  return str
    .replace(/::/g, '/')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/_/g, '-')
    .toLowerCase()
}

export function css(__el: HTMLElement, __property: string): undefined | string

export function css(
  __el: HTMLElement,
  __properties: string[]
): undefined | { [k: string]: string | number }

export function css(__el: HTMLElement, __map: { [k: string]: string | number }): string

export function css(
  __el: HTMLElement,
  __property: string | { [k: string]: string | number },
  __value: string | number
): string

export function css(
  el: HTMLElement,
  arg2: string | string[] | { [k: string]: string | number },
  arg3?: string | number
): undefined | { [k: string]: string | number } | string {
  if (arguments.length < 3) {
    const element = el
    if (!element) return undefined

    const computedStyle = getComputedStyle(element, '')
    if (typeof arg2 === 'string') {
      return (
        (element.style as { [k: string]: any })[camelize(arg2)] ||
        computedStyle.getPropertyValue(arg2)
      )
    } else if (Array.isArray(arg2)) {
      const props: { [k: string]: string | number } = {}
      arg2.forEach((val, _) => {
        props[camelize(val)] =
          (element.style as { [k: string]: any })[camelize(val)] ||
          computedStyle.getPropertyValue(val)
      })
      return props
    }
  }

  let result = ''
  if (arg2 === 'string') {
    if (!arg3 && arg3 !== 0) {
      el.style.removeProperty(dasherize(arg2))
    } else {
      result = dasherize(arg2) + ':' + maybeAddPx(arg2, arg3)
    }
  } else {
    arg2 = arg2 as { [k: string]: string | number }

    for (const key in arg2) {
      if (!arg2[key] && arg2[key] !== 0) {
        el.style.removeProperty(dasherize(key))
      } else {
        result += dasherize(key) + ':' + maybeAddPx(key, arg2[key]) + ';'
      }
    }
  }

  return (el.style.cssText += result)
}

export function setCustomCss(
  field: HTMLElement | null | undefined,
  style: { [k: string]: string | number } | null | undefined
) {
  if (!style || !field) return

  const originStyle = css(field, Object.keys(style))
  css(field, style)

  // @TODO non-null assertion doesn't look good
  // also, why do we return either this or nothing??
  return () => css(field, originStyle!)
}
export interface Point {
  x: number
  y: number
}

export function getNormalizedEventCoords(
  evt: Event | undefined,
  pageOffset: Point,
  clientRect: ClientRect
): Point {
  if (!evt) {
    return { x: 0, y: 0 }
  }
  const { x, y } = pageOffset
  const documentX = x + clientRect.left
  const documentY = y + clientRect.top

  let normalizedX
  let normalizedY
  // Determine touch point relative to the ripple container.
  if (evt.type === 'touchstart') {
    const touchEvent = evt as TouchEvent
    normalizedX = touchEvent.changedTouches[0].pageX - documentX
    normalizedY = touchEvent.changedTouches[0].pageY - documentY
  } else {
    const mouseEvent = evt as MouseEvent
    normalizedX = mouseEvent.pageX - documentX
    normalizedY = mouseEvent.pageY - documentY
  }

  return { x: normalizedX, y: normalizedY }
}

export function inContainer(container?: Node | null, el?: Node): boolean {
  if (!el) return false
  if (!container) return false
  if (container === el) return true
  while (el.parentNode) {
    if (container === el.parentNode) return true
    el = el.parentNode
  }
  return false
}

export function getParentBySelector(el: Element, sel: string) {
  let e = el.parentNode
  while (e !== null && (e as Element).matches) {
    if ((e as Element).matches(sel)) return e as Element
    e = e.parentNode
  }
  return false
}

export function selectText(node: HTMLElement | null) {
  if (!node) return
  try {
    const selection = window.getSelection()
    const range = document.createRange()
    if (!selection) return
    range.selectNodeContents(node)
    selection.removeAllRanges()
    selection.addRange(range)
  } catch (e) {
    console.log(e)
  }
}
