import { Atom, classes } from '@grammarly/focal'
import { connect, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import { getChatsCached, searchUsersCached } from '../../api'
import { Contact, SearchExpression, MediaSelector } from '../../types'
import { getParentBySelector, scrollElement } from '../../utils/dom'
import { track } from '../../utils/tracking'
import { AnimatedExpand } from '../animated'
import { Button, IconButton } from '../buttons'
import * as buttonStyle from '../buttons.styl'
import { Condition as ResultCondition } from '../conditions'
import { DialogButtons, DialogError, DialogForm, DialogHeader } from '../dialog'
import { Icons } from '../icons'
import { Input } from '../input'
import { MemberSelect } from '../memberSelect'
import { TooltipAnchor, tooltipState } from '../tooltip'
import * as style from './condition.styl'
import { ErrorState, useSubmit } from './utils'
import { ExpandLink, ExpandPanel } from './expandLink'
import { MediaForm } from './mediaForm'
import { KeywordsInput } from './keywordsInput'

export enum Step {
  Keywords = 'Keywords',
  Chats = 'Chats',
  Users = 'Users'
}

const formTitles = {
  [Step.Keywords]: 'Message filter',
  [Step.Chats]: 'Chat filter',
  [Step.Users]: 'User filter'
}

const formTooltips = {
  [Step.Keywords]: 'Use a message filter to select messages by matched keywords.',
  [Step.Chats]: 'Include or exclude chats from a filter rule.',
  [Step.Users]: 'Include or exclude users from a filter rule.'
}

export const steps = [Step.Keywords, Step.Chats, Step.Users]

export interface ExapandedState {
  media: Atom<boolean>
  exclude: Atom<boolean>
  [Step.Chats]: Atom<boolean>
  [Step.Users]: Atom<boolean>
}

export const forms = {
  [Step.Keywords]: (_: string, expandedState: ExapandedState) => (
    <KeywordForm
      isExpandedMediaForm={expandedState.media}
      isExpandedExcludeForm={expandedState.exclude}
    />
  ),
  [Step.Chats]: (phone: string, _: ExapandedState) => <ChatsForm phone={phone} />,
  [Step.Users]: (phone: string, _: ExapandedState) => <UsersForm phone={phone} />
}

export function nextStep(x: Step) {
  return steps[steps.indexOf(x) + 1]
}

export const initialValues: SearchExpression & { textValue?: string; textValueExclude?: string } = {
  id: '',
  textValue: '',
  textValueExclude: '',
  values: [],
  isWildcard: false,
  isRegExp: false,
  isIgnoreCase: true,
  valuesExclude: [],
  isWildcardExclude: false,
  isRegExpExclude: false,
  isIgnoreCaseExclude: true,
  isIncludePrivateChats: false,
  users: [],
  chats: [],
  ignoreChats: [],
  ignoreUsers: [],
  mediaSelectors: []
}

export interface ConditionValidation {
  textValue?: string
  textValueExclude?: string
  isRegExp?: string
  isIgnoreCase?: string
  isIncludePrivateChats?: string
  isRegExpExclude?: string
  isIgnoreCaseExclude?: string
  isIncludePrivateChatsExclude?: string
  users?: string
  chats?: string
  ignoreChats?: string
  ignoreUsers?: string
  mediaSelectors?: string
  message?: string
  isValid: boolean
}

export interface ConditionProps {
  phone: string
  editItem?: SearchExpression
  step?: number
  onFinish?: Function
  onSubmit?(x: SearchExpression): Promise<ConditionValidation>
}

export function toSearchExpression(x: any) {
  return x as any
}

export const ConditionForm = (props: ConditionProps) => {
  const [step, setStep] = useState(props.step || 0)
  let submit: Function
  useSubmit(() => submit && submit())

  const error = new ErrorState()

  useEffect(() => {
    const el = document.querySelector(`[data-id=${steps[step]}]`)
    if (!el) return
    let top = el.getBoundingClientRect().top + scrollElement().scrollTop - 100
    if (top < 0) return

    if (step === 0) top = 0
    scrollElement().scrollTo({ top, behavior: 'smooth' })
  }, [step])

  const editItem = props.editItem
  const expandedState: ExapandedState = {
    media: Atom.create(
      Boolean(editItem ? editItem.mediaSelectors && editItem.mediaSelectors.length > 0 : false)
    ),
    exclude: Atom.create(
      Boolean(editItem ? editItem.valuesExclude && editItem.valuesExclude.length > 0 : false)
    ),
    [Step.Chats]: Atom.create(
      Boolean(
        editItem
          ? (editItem.chats && editItem.chats.length > 0) ||
              (editItem.ignoreChats && editItem.ignoreChats.length > 0)
          : false
      )
    ),
    [Step.Users]: Atom.create(
      Boolean(
        editItem
          ? (editItem.users && editItem.users.length > 0) ||
              (editItem.ignoreUsers && editItem.ignoreUsers.length > 0)
          : false
      )
    )
  }

  return (
    <div className={style.conditionForm}>
      <Formik
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!props.onSubmit) return

          const result = await props.onSubmit(values)

          if (!result.isValid) {
            track('conditionForm', 'error', result.message)
            setErrors(result)
            error.show(result.message)
          } else {
            track('conditionForm', 'success')
          }

          setSubmitting(false)
        }}
        initialValues={
          props.editItem
            ? {
                ...initialValues,
                ...props.editItem,
                textValue: (props.editItem.values || []).join(', '),
                textValueExclude: (props.editItem.valuesExclude || []).join(', ')
              }
            : initialValues
        }
        validate={() => {
          error.hide()
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          submit = handleSubmit
          return (
            <>
              <DialogForm className={style.formContent}>
                <div className={style.forms}>
                  <DialogHeader className={style.header}>
                    <DialogError className={style.conditionError} isError={error.state} />
                    <div className={style.conditionTitle}>Add Rule</div>{' '}
                    <ResultCondition
                      className={style.result}
                      phone={props.phone}
                      value={toSearchExpression(values)}
                      selectedId={steps[step].toString().toLowerCase()}
                      selectedClass={style.selectedResultItem}
                      isReadOnly={Atom.create(true)}
                      isNonSelectable
                      onClick={e => {
                        const _target = e.target as HTMLElement
                        const target = _target.dataset.id
                          ? _target
                          : (getParentBySelector(_target, 'span[data-id]') as
                              | HTMLElement
                              | undefined)
                        if (!target || !target.dataset.id) return

                        const index = steps.findIndex(
                          x => x.toString().toLowerCase() === target.dataset.id
                        )
                        if (index >= 0) setStep(index)
                      }}
                    />
                  </DialogHeader>
                  <div className={style.formsContainer}>
                    {Object.keys(forms).map(formStep => (
                      <div className={style.formWrap} key={formStep}>
                        <div data-id={formStep} className={style.formTitle}>
                          {formStep === Step.Keywords ? (
                            <TooltipAnchor
                              tooltip={tooltipState}
                              content={formTooltips[formStep]}
                              align="top"
                            >
                              <span style={{ cursor: 'default' }} className={style.pseudoLink}>
                                {formTitles[formStep]}
                              </span>
                            </TooltipAnchor>
                          ) : (
                            <ExpandLink
                              tooltip={formTooltips[formStep]}
                              isExpanded={expandedState[formStep]}
                            >
                              {formTitles[formStep]}
                            </ExpandLink>
                          )}
                        </div>
                        {formStep === Step.Keywords ? (
                          forms[formStep](props.phone, expandedState)
                        ) : (
                          <AnimatedExpand isExpanded={expandedState[formStep]}>
                            {forms[formStep](props.phone, expandedState)}
                          </AnimatedExpand>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </DialogForm>
              <DialogButtons className={style.buttons}>
                <Button
                  trackingName="cancelAddConditionBtn"
                  onClick={() => props.onFinish && props.onFinish()}
                >
                  Cancel
                </Button>
                <Button
                  trackingName="submitAddConditionBtn"
                  isLoading={isSubmitting}
                  onClick={() => {
                    handleSubmit()
                  }}
                  {...classes(buttonStyle.mainButton, style.saveBtn)}
                >
                  Save
                </Button>
              </DialogButtons>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export const KeywordForm = connect<
  { isExpandedMediaForm: Atom<boolean>; isExpandedExcludeForm: Atom<boolean> },
  {
    isRegExp?: boolean
    isWildCard?: boolean
    textValue: string
    isIgnoreCase?: boolean
    isWholeWord?: boolean
  }
>(props => {
  return (
    <div className={style.expandedForm}>
      <KeywordsInput isExclude={false} />
      <ExpandPanel
        tooltip="Skip messages with specified keywords"
        expandLinkChildren={'Exclude messages with keywords'}
        expandIconLeft
        expandLinkWrapClassName={style.excludeLink}
        isExpanded={props.isExpandedExcludeForm}
      >
        <div className={style.excludeWrap}>
          <KeywordsInput isExclude={true} />
        </div>
      </ExpandPanel>

      <MediaForm
        expandLink={'Advanced (media and pins)'}
        possibleValues={MediaSelector}
        tooltip={'Media filter helps to select messages that have some media attachment inside.'}
        isExpanded={props.isExpandedMediaForm}
      />
    </div>
  )
})

export const ChatsForm = connect<
  { phone: string },
  {
    chats?: Contact[]
    ignoreChats?: Contact[]
    isIncludePrivateChats?: boolean
    isIncludeBots?: boolean
  }
>(props => {
  const chats = props.formik.values.chats
  const ignoreChats = props.formik.values.ignoreChats
  const isIncludePrivateChats = Boolean(props.formik.values.isIncludePrivateChats)
  const isIncludeBots = Boolean(props.formik.values.isIncludeBots)
  const isAny = !chats || chats.length === 0

  return (
    <div className={style.expandedForm}>
      <MemberSelect
        phone={props.phone}
        type="chat"
        label="Message from chats"
        getData={(phone, query) =>
          getChatsCached(phone, query, isIncludePrivateChats, isIncludeBots)
        }
        openOnFocus
        value={chats}
        onChange={x => props.formik.setFieldValue('chats', x)}
        helper={
          <span>
            <b>Leave empty to receive messages from any common chat</b>
          </span>
        }
        button={
          <div className={style.memberInputFlags}>
            <TooltipAnchor tooltip={tooltipState} content={'Include private 1:1 chats'} align="top">
              <IconButton
                isToggleMode
                isToggleStyleLight
                isOn={isIncludePrivateChats}
                icon={Icons.people}
                onToggle={value => {
                  props.formik.setFieldValue('isIncludePrivateChats', value)
                }}
              />
            </TooltipAnchor>
            <TooltipAnchor tooltip={tooltipState} content={'Include chats with bots'} align="top">
              <IconButton
                isToggleMode
                isToggleStyleLight
                isOn={isIncludeBots}
                icon={Icons.bot}
                onToggle={value => {
                  props.formik.setFieldValue('isIncludeBots', value)
                }}
              />
            </TooltipAnchor>
          </div>
        }
      />

      <MemberSelect
        phone={props.phone}
        type="chat"
        label="Except chats"
        getData={(phone, query) =>
          getChatsCached(phone, query, isIncludePrivateChats, isIncludeBots)
        }
        openOnFocus
        value={ignoreChats}
        onChange={x => props.formik.setFieldValue('ignoreChats', x)}
        helper="Messages from those chats will be ignored"
        disabled={!isAny}
      />
    </div>
  )
})

export const UsersForm = connect<{ phone: string }, { users?: Contact[]; ignoreUsers?: Contact[] }>(
  props => {
    const users = props.formik.values.users || []
    const ignoreUsers = props.formik.values.ignoreUsers || []
    const isAny = !users || users.length === 0
    return (
      <div className={style.expandedForm}>
        <MemberSelect
          phone={props.phone}
          type="user"
          label="Messages from users"
          getData={searchUsersCached}
          debounceTime={400}
          onChange={x => props.formik.setFieldValue('users', x)}
          helper={
            <span>
              <b>Leave empty to receive messages from any user</b>
            </span>
          }
          value={users}
        />
        <Input
          type="checkbox"
          name="isIncludeMy"
          label="Include my messages"
          containerClass={style.chatsPadding}
        />
        <div>
          <MemberSelect
            phone={props.phone}
            disabled={!isAny}
            type="user"
            label="Except users"
            getData={searchUsersCached}
            debounceTime={400}
            onChange={x => props.formik.setFieldValue('ignoreUsers', x)}
            value={ignoreUsers}
            helper="Messages from those users will be ignored"
          />
        </div>
      </div>
    )
  }
)
