import * as React from 'react'
import * as style from './layout.styl'
import { classes } from '@grammarly/focal'

export const SimpleLayout = ({
  leftBlock,
  rightBlock
}: {
  leftBlock: JSX.Element
  rightBlock: JSX.Element
}) => {
  return (
    <div className={style.simple}>
      <div className={style.simpleLeft}>{leftBlock}</div>
      <div className={style.simpleRight}>{rightBlock}</div>
    </div>
  )
}

export const ScrollableDiv = ({
  children,
  className,
  scrollClassName,
  ...other
}: React.HTMLAttributes<HTMLDivElement> & { scrollClassName?: string }) => {
  const elRef = React.useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [isScrollable, setIsScrollable] = React.useState(false)

  const dataScroll = isScrolled ? { 'data-scrolled': true } : undefined
  const dataScrollable = isScrollable ? { 'data-scrollable': true } : undefined

  const onResize = () => {
    if (!elRef.current) return
    setIsScrollable(elRef.current.scrollHeight !== elRef.current.clientHeight)
  }

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return (
    <div
      ref={elRef}
      {...dataScroll}
      {...dataScrollable}
      onScroll={e => {
        if (e.target !== elRef.current) return
        setIsScrolled(elRef.current.scrollTop > 0)
      }}
      {...classes(style.scrollable, className, isScrolled && style.scrollClassName)}
      {...other}
    >
      {children}
    </div>
  )
}
