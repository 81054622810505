import React, { useEffect } from 'react'
import * as style from './notification.styl'
import { createPortal } from 'react-dom'
import { useTransition, animated } from 'react-spring'
import { classes } from '@grammarly/focal'

export const Notification = ({
  isShow,
  className,
  timeout = 7000,
  big,
  onClose,
  children,
  isAppear,
  parent
}: {
  isShow: boolean
  isAppear?: boolean
  timeout?: number
  className?: string
  big?: boolean
  onClose: Function
  children: React.ReactNode
  parent?: HTMLElement
}) => {
  const transitions = isAppear
    ? useTransition(isShow, null, {
        from: { transform: 'opacity(0)' },
        enter: { transform: 'translateY(1)' },
        leave: { transform: 'translateY(0)' }
      })
    : useTransition(isShow, null, {
        from: { transform: 'translateY(-100%)' },
        enter: { transform: 'translateY(0%)' },
        leave: { transform: 'translateY(-100%)' }
      })

  useEffect(() => {
    let timer = 0
    if (isShow) {
      timer = setTimeout(onClose, timeout)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isShow])

  const element = transitions.map(
    ({ item, props, key }) =>
      item && (
        <animated.div
          onClick={() => onClose()}
          {...classes(style.notification, big && style.big, isAppear && style.appear, className)}
          key={key}
          style={props}
        >
          <div className={style.content}>{children}</div>
        </animated.div>
      )
  )

  return <>{parent ? createPortal(<>{element}</>, parent) : <>{element}</>}</>
}
