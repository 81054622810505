import React from 'react'

import * as style from './billingPortalLink.styl'
import { getWriteApi } from '../model'

export const BillingPortalLink = ({ showError }: { showError: (x: string) => void }) => {
  return (
    <span
      className={style.billingPortalLink}
      onClick={async () => {
        const response = await getWriteApi().stripePortal(document.location.pathname)
        if (!response.validation.isValid) {
          showError(response.validation.message!)
        } else {
          // console.log(response)
          const url = response.item!
          document.location.href = url
        }
      }}
    >
      upgrade to PRO plan
    </span>
  )
}
