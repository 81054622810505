import { Atom, classes, F, Lens } from '@grammarly/focal'
import page from 'page'
import * as React from 'react'

import { limitMessage, limits } from '../api'
import { AddButton, Button, MainButton } from '../components/buttons'
import { Dialog, DialogButtons, DialogContent, DialogHeader } from '../components/dialog'
import { AddTelegram } from '../components/forms/telegram'
import { imageUrl } from '../config'
import { getWriteApi } from '../model'
import { Plan, PlanWithService, TelegramService } from '../types'
import { intitials } from '../utils/string'
import { track } from '../utils/tracking'
import { FoldableList } from './animatedList'
import { ErrorState } from './forms/utils'
import { Icons } from './icons'
import { ErrorBadge, PausedBadge } from './itemBadges'
import { ListItem } from './listItem'
import { PopupMenuItem } from './menu'
import { PlaceholderedImage } from './placeholderedImage'
import { PlanBadge } from './planBadge'
import { Ripple } from './ripple'
import * as style from './services.styl'

export const TelegramItem = ({
  data,
  reauthorize
}: {
  data: TelegramService
  reauthorize?(item: TelegramService): void
}) => {
  const name = data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : data.name
  return (
    <>
      <div className={style.avatar}>
        <PlaceholderedImage
          className={style.telegramItem}
          src={imageUrl(data.phone, data.id!)}
          title={name!}
          placeholder={
            <span className={style.telegramPlaceholder}>
              <span className={style.initials}>{intitials(name || '')}</span>
            </span>
          }
        />
      </div>

      <div className={style.userInfo}>
        <div className={style.phone}>
          <span className={style.phoneWrap}>
            <span className={style.phoneSizerValue}>{data.phone}</span>
            <span className={style.phoneSizer}>{data.phone}</span>
            {data.plan && (
              <PlanBadge className={style.phoneBadge} planType={data.plan.type}></PlanBadge>
            )}
          </span>
        </div>
        {data.name && <div className={style.username}>{data.name}</div>}
        {(data.firstName || data.lastName) && (
          <div className={style.name}>
            {[data.firstName, data.lastName].filter(Boolean).join(' ')}
          </div>
        )}
      </div>
      {data.isAuthorized && data.isPaused && <PausedBadge />}
      {!data.isAuthorized && <ErrorBadge onClick={() => reauthorize && reauthorize(data)} />}
    </>
  )
}

export const Services = ({
  data,
  plans,
  canTrial,
  isWhitelisted,
  selectedItem,
  onChange,
  onDelete,
  onCreateFilter,
  onNew,
  onOpenDialog,
  onCloseDialog,
  onEdit,
  onShowDelete,
  onHideDelete,
  setOpenCommand,
  currentPlan,
  currentService,
  onSelectPlanForPausedService,
  setItemCommands,
  isDialogMode
}: {
  isDialogMode?: Boolean
  data: Atom<TelegramService[]>
  plans: Atom<PlanWithService[]>
  canTrial: Atom<boolean>
  isWhitelisted: Atom<undefined | boolean>
  selectedItem: Atom<number>
  onDelete?(x: TelegramService): void
  onChange: Function
  onCreateFilter?: Function
  onNew(x: TelegramService): void
  onEdit(x: TelegramService): void
  onOpenDialog: Function
  onCloseDialog: Function
  onShowDelete(): void
  onHideDelete(): void
  onSelectPlanForPausedService(x: TelegramService): void
  setOpenCommand?(
    openDialog: React.Dispatch<
      React.SetStateAction<{
        isOpen: boolean
        item?: TelegramService | undefined
      }>
    >
  ): void
  setItemCommands?(x: {
    setLoadingItem: (phone: string, value: boolean) => void
    setError: (phone: string, message: string) => void
    onPause: (x: TelegramService) => Promise<void>
  }): void
  currentPlan: Atom<Plan | undefined>
  currentService: Atom<TelegramService | undefined>
}) => {
  const [openTelegramDialog, setOpenTelegramDialog] = React.useState<{
    isOpen: boolean
    item?: TelegramService
  }>({ isOpen: false })
  const isLoadingItem = Atom.create<Record<string, boolean>>({})
  const isErrorItem = Atom.create<Record<string, { message: string; isShow: boolean }>>({})
  const setError = (phone: string, message: string) => {
    isErrorItem.lens(Lens.key(phone)).set({ message, isShow: true })
  }
  const setLoadingItem = (phone: string, value: boolean) => {
    const isLoading = isLoadingItem.lens(Lens.key(phone))
    isLoading.set(value)
  }

  const onPause = async (item: TelegramService) => {
    setLoadingItem(item.phone, true)
    const result = await getWriteApi().pauseTelegramService(item.phone, !item.isPaused)
    track('pauseService', 'submit')
    if (result.validation.isValid) {
      onEdit({ ...item, isPaused: !item.isPaused })
    } else {
      track('pauseService', 'error', result.validation.message)
      setError(item.phone, result.validation.message || '')
    }

    setLoadingItem(item.phone, false)
  }

  if (setItemCommands) {
    setItemCommands({ setError, setLoadingItem, onPause })
  }

  if (setOpenCommand) setOpenCommand(setOpenTelegramDialog)
  return (
    <div>
      {!isDialogMode && (
        <>
          <F.div className={style.serviceList}>
            <FoldableList
              items={data}
              onItem={(item, i) => (
                <ListItem
                  trackingName="service"
                  key={item.phone}
                  id={item.phone}
                  {...classes(
                    style.serviceItem,
                    style.telegram,
                    item.isPaused && style.paused,
                    !item.isAuthorized && style.notAuthorized
                  )}
                  isLoading={isLoadingItem.lens(Lens.key(item.phone))}
                  isError={isErrorItem.lens(Lens.key(item.phone))}
                  selectedClassName={style.selected}
                  readOnlyClassName={style.readOnly}
                  isSelected={selectedItem.view(x => i === x)}
                  onClick={() => {
                    selectedItem.set(i)
                    onChange()
                  }}
                  onDelete={async () => {
                    track('deleteService', 'submit')
                    const result = await getWriteApi().deleteTelegramService(item.phone)
                    if (!result.validation.isValid) {
                      setError(item.phone, result.validation.message || '')
                      track('deleteService', 'error', result.validation.message)
                      return false
                    }
                    if (onDelete) onDelete(item)
                    return true
                  }}
                  onShowDelete={onShowDelete}
                  onHideDelete={onHideDelete}
                  onAction={async action => {
                    if (action === 'pause') {
                      if (item.isPaused && !item.plan) {
                        onSelectPlanForPausedService(item)
                        return
                      }

                      onPause(item)
                    }

                    if (action === 'reauthorize') {
                      setOpenTelegramDialog({ isOpen: true, item })
                    }
                  }}
                  getMenuItems={(_, _loadingAction) => (
                    <>
                      {item.isAuthorized ? (
                        <PopupMenuItem
                          action="pause"
                          icon={item.isPaused ? Icons.play : Icons.pause}
                        >
                          {!item.plan && item.isPaused
                            ? 'Choose plan to resume service'
                            : (item.isPaused ? 'Resume' : 'Pause') + ' processing updates'}
                        </PopupMenuItem>
                      ) : (
                        <PopupMenuItem
                          className={style.reauth}
                          action="reauthorize"
                          icon={Icons.error}
                        >
                          Reauthorize
                        </PopupMenuItem>
                      )}
                      <PopupMenuItem action="delete" icon={Icons.remove}>
                        Delete service with filters
                      </PopupMenuItem>
                    </>
                  )}
                >
                  <TelegramItem
                    data={item}
                    reauthorize={() => setOpenTelegramDialog({ isOpen: true, item })}
                  />
                </ListItem>
              )}
            />
          </F.div>
          <F.Fragment>
            {data.view(x => {
              const isDisabled = x.length >= limits.telegramService

              return (
                <AddButton
                  trackingName="AddServiceBtn"
                  onClick={() => {
                    currentPlan.set(undefined)
                    currentService.set(undefined)
                    setOpenTelegramDialog({ isOpen: !openTelegramDialog.isOpen })
                    setTimeout(() => page('/connect'), 300)
                  }}
                  disabled={isDisabled}
                  disabledText={limitMessage('telegram services', limits.telegramService)}
                  tooltip={'Connect your Telegram account, so you can setup filters for it.'}
                >
                  Connect Telegram
                </AddButton>
              )
            })}
          </F.Fragment>
        </>
      )}
      <AddServiceDialog
        plans={plans}
        currentService={currentService}
        canTrial={canTrial}
        isWhitelisted={isWhitelisted}
        openTelegramDialog={openTelegramDialog}
        onCreateFilter={onCreateFilter}
        onShow={onOpenDialog}
        onNew={onNew}
        onEdit={onEdit}
        currentPlan={currentPlan}
        onClose={() => {
          if (!document.location.pathname.includes('/profile')) page('/')
          setOpenTelegramDialog({ isOpen: false })
          onCloseDialog()
        }}
      />
    </div>
  )
}

export const AddServiceDialog = ({
  openTelegramDialog,
  plans,
  onClose,
  onShow,
  onCreateFilter,
  onNew,
  onEdit,
  nonCancelable,
  currentPlan,
  currentService,
  canTrial,
  isWhitelisted
}: {
  openTelegramDialog: { isOpen: boolean; item?: TelegramService } | Atom<boolean>
  plans: Atom<PlanWithService[]>
  canTrial: Atom<boolean>
  isWhitelisted: Atom<boolean | undefined>
  onClose: Function
  onShow: Function
  onCreateFilter?: Function
  onNew(x: TelegramService): void
  onEdit?(x: TelegramService): void
  nonCancelable?: boolean
  currentPlan?: Atom<Plan | undefined>
  currentService: Atom<TelegramService | undefined>
}) => {
  const innerDialog = (isShow: boolean, item?: TelegramService) => {
    if (isShow) onShow()
    return (
      <Dialog isShow={isShow} onClose={onClose} dialogModeName={'service'}>
        <AddTelegram
          plans={plans}
          nonCancelable={nonCancelable}
          onFinish={onClose}
          editItem={item}
          canTrial={canTrial}
          isWhitelisted={isWhitelisted}
          onCreateFilter={() => {
            onClose()
            if (onCreateFilter) onCreateFilter()
          }}
          onSubmit={async (step, values) => {
            const calculatedPlan =
              plans.get().filter(x => !x.service).length === 1
                ? plans.get()[0].plan
                : item
                ? item.plan
                : undefined

            const currentPlan_ = currentPlan ? currentPlan : Atom.create(calculatedPlan)

            const planId = currentPlan_?.get()?.id
            if (planId) values.planId = planId

            const result = await getWriteApi().registerTelegramService(values, step)
            if (!currentService.get() && values.phone) {
              currentService.set({
                id: values.phone,
                filters: [],
                phone: values.phone,
                isAuthorized: false
              })
            }

            if (item && onEdit && result.item) {
              onEdit(result.item)
            } else if (result.item && result.item.isAuthorized) {
              onNew(result.item)
            }

            return result.validation
          }}
          currentPlan={currentPlan}
          currentService={currentService}
        />
      </Dialog>
    )
  }

  const asSimple = openTelegramDialog as { isOpen: boolean; item?: TelegramService }
  const asAtom = openTelegramDialog as Atom<boolean>
  return (
    <F.Fragment>
      {asSimple.isOpen === true || asSimple.isOpen === false
        ? innerDialog(asSimple.isOpen, asSimple.item)
        : asAtom.view(x => innerDialog(x))}
    </F.Fragment>
  )
}

export const ServiceChooser = ({
  services,
  onNew,
  currentPlan,
  currentService,
  error
}: {
  services: TelegramService[]
  onNew: (x: Plan | undefined) => void
  currentPlan: Atom<Plan | undefined>
  currentService: Atom<TelegramService | undefined>
  error: ErrorState
}) => {
  return (
    <DialogContent isError={error.state} className={style.chooserContent}>
      <DialogHeader className={style.chooserHeader}>
        <div className={style.chooserHeaderWrap}>
          Connect Telegram{' '}
          {currentPlan.get() && (
            <PlanBadge className={style.planBadge} planType={currentPlan.get()?.type}></PlanBadge>
          )}
        </div>
      </DialogHeader>

      <div className={style.servicesChooserList}>
        {services.map(x => {
          return (
            <F.div
              onClick={() => currentService.set(x)}
              key={x.id}
              {...classes(
                style.serviceChooserItemWrap,
                currentService.view(s => s?.id === x.id && style.serviceChooserItemSelected)
              )}
            >
              <Ripple color="#d9e1f6" />
              <div
                {...classes(
                  style.serviceChooserItem,
                  style.serviceItem,
                  style.telegram,
                  x.isPaused && style.paused,
                  !x.isAuthorized && style.notAuthorized
                )}
              >
                <TelegramItem data={x}></TelegramItem>
              </div>
            </F.div>
          )
        })}
        <div className={style.chooserAddWrap}>
          <AddButton
            trackingName="AddServiceBtn"
            className={style.chooserAdd}
            onClick={() => {
              // setOpenTelegramDialog({ isOpen: !openTelegramDialog.isOpen })
              // setTimeout(() => page('/connect'), 300)
              onNew(currentPlan.get())
            }}
            // disabled={isDisabled}
            disabledText={limitMessage('telegram services', limits.telegramService)}
            tooltip={'Connect your Telegram account, so you can setup filters for it.'}
          >
            Add new
          </AddButton>
        </div>
      </div>
    </DialogContent>
  )
}

export const ServiceChooserDialog = ({
  isOpen,
  onClose,
  services,
  onNew,
  currentPlan,
  currentService,
  onSelect
}: {
  isOpen: Atom<boolean>
  onClose: Function
  services: Atom<TelegramService[]>
  onSelect: (x: TelegramService) => void
  onNew: (x: Plan | undefined) => void
  currentPlan: Atom<Plan | undefined>
  currentService: Atom<TelegramService | undefined>
}) => {
  const isLoading = Atom.create(false)
  const error = new ErrorState()
  return (
    <F.Fragment>
      {isOpen.view(_isOpen => {
        return (
          <Dialog
            dialogModeName="ServiceChooserDialog"
            isShow={_isOpen}
            showCloseButton
            onClose={() => onClose()}
          >
            <ServiceChooser
              onNew={onNew}
              currentPlan={currentPlan}
              currentService={currentService}
              services={services.get() /*.filter(x => !x.planId)*/}
              error={error}
            ></ServiceChooser>
            <DialogButtons>
              <F.Fragment>
                {currentService.view(
                  service =>
                    service &&
                    service.planId &&
                    service.planId !== currentPlan.get()?.id && (
                      <div className={style.serviceChooserWarning}>
                        <div className={style.serviceChooserWarningWrap}>
                          ⚠️ Account <b>{service.phone}</b> will be reassigned from a different
                          plan.
                        </div>
                      </div>
                    )
                )}
              </F.Fragment>
              <Button
                trackingName="chooseServiceCancelBtn"
                onClick={() => {
                  onClose()
                  error.hide()
                }}
              >
                Cancel
              </Button>
              <F.Fragment>
                {isLoading.view(loading => (
                  <MainButton
                    isLoading={loading}
                    trackingName="chooseServiceBtn"
                    disabled={currentService.view(
                      x => !x || (x && x.planId === currentPlan.get()?.id)
                    )}
                    onClick={async () => {
                      // currentPlan.get()?.id
                      // api call
                      const service = currentService.get()
                      const plan = currentPlan.get()
                      if (!service || !plan) {
                        console.warn(`service and plan should be provided`, service, plan)
                        return
                      }
                      isLoading.set(true)
                      const result = await getWriteApi().assignPlan(service.phone, plan.id)
                      isLoading.set(false)
                      if (!result.validation.isValid) {
                        error.show(result.validation.message || 'Error assigning plan.')
                      } else {
                        onSelect(service)
                      }
                    }}
                  >
                    Save
                  </MainButton>
                ))}
              </F.Fragment>
            </DialogButtons>
          </Dialog>
        )
      })}
    </F.Fragment>
  )
}
